import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Options } from '@models/configurator-models/form-fields';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.css'],
})
export class InputSelectComponent {
  @Input() formGroup: FormGroup = new FormGroup({
    defaultControl: new FormControl<string | null>(''),
  });
  @Input() label: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Input() options?: any[] | Options[] = [];
  @Input() defaultValue: any = null;
  @Input() multiple: boolean = false;
  @Output() onSelectionChange = new EventEmitter<MatSelectChange>();
  @Output() onClick = new EventEmitter<boolean>();
  @ViewChild(MatSelect) public matSelect!: MatSelect;

  isDropdownOpened: boolean = false;
  panelClass: string = '';

  handleDropdown(isDropdownOpened: boolean) {
    this.isDropdownOpened = isDropdownOpened;
    this.onClick.emit(isDropdownOpened);
  }

  handleSelectionChange(event: MatSelectChange) {
    this.onSelectionChange.emit(event);
  }
}

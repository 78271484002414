<apx-chart
  [autoUpdateSeries]="true"
  [chart]="chartOptions.chart!"
  [dataLabels]="chartOptions.dataLabels!"
  [legend]="chartOptions.legend!"
  [markers]="chartOptions.markers!"
  [series]="chartOptions.series"
  [xaxis]="chartOptions.xAxis!"
  [stroke]="chartOptions.stroke!"
  [tooltip]="chartOptions.tooltip!"
  [yaxis]="chartOptions.yAxis!"
  [labels]="chartOptions.labels"
  [fill]="chartOptions.fill!"
  [responsive]="chartOptions.responsive!"
  [grid]="chartOptions.grid!"
  [plotOptions]="chartOptions.plotOptions!"
  [states]="chartOptions.states!"
  [colors]="chartOptions.colors!" />

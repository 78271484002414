import { Pipe, PipeTransform } from '@angular/core';
import { Seconds } from '@app/utils/enums/time-enum';

@Pipe({
  name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const days: number = Math.floor(value / Seconds.DAY);
    const hours: number = Math.floor((value - days * Seconds.DAY) / Seconds.HOUR);
    const minutes: number = Math.floor((value - days * Seconds.DAY - hours * Seconds.HOUR) / Seconds.MIN);
    const seconds: number = value - days * Seconds.DAY - hours * Seconds.HOUR - minutes * Seconds.MIN;
    const defaultTime = '00:00:00';

    return value === undefined || value <= 0
      ? defaultTime
      : `${days ? days + 'd' : ''} ${hours ? ('00' + hours).slice(-2) + 'h' : ''} ${
          minutes ? ('00' + minutes).slice(-2) + 'm' : ''
        } ${seconds ? ('00' + seconds).slice(-2) + 's' : ''}`.trim();
  }
}

import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IncludedRelationship, TrendDataUIModel } from '@models/device';
import { MultiHostData } from '@models/jsonapi-models/host.model';
import { Cm2000Service } from '@services/jsonapi-services/cm2000.service';
import { Cm2000_actual_configService } from '@services/jsonapi-services/cm2000_actual_config.service';
import { Cm2000_desired_configService } from '@services/jsonapi-services/cm2000_desired_config.service';
import { Cm2000pService } from '@services/jsonapi-services/cm2000p.service';
import { Cm2000p_actual_configService } from '@services/jsonapi-services/cm2000p_actual_config.service';
import { Cm2000p_desired_configService } from '@services/jsonapi-services/cm2000p_desired_config.service';
import { Elexant3500iService } from '@services/jsonapi-services/elexant3500i.service';
import { Elexant3500i_actual_configService } from '@services/jsonapi-services/elexant3500i_actual_config.service';
import { Elexant3500i_desired_configService } from '@services/jsonapi-services/elexant3500i_desired_config.service';
import { Elexant40X0Service } from '@services/jsonapi-services/elexant40X0.service';
import { Elexant40x0_actual_configService } from '@services/jsonapi-services/elexant40x0_actual_config.service';
import { Elexant40x0_desired_configService } from '@services/jsonapi-services/elexant40x0_desired_config.service';
import { Elexant5010iService } from '@services/jsonapi-services/elexant5010i.service';
import { HostService } from '@services/jsonapi-services/host.service';
import { Htc910Service } from '@services/jsonapi-services/htc910.service';
import { Htc910_actual_configService } from '@services/jsonapi-services/htc910_actual_config.service';
import { Htc910_desired_configService } from '@services/jsonapi-services/htc910_desired_config.service';
import { Htc920Service } from '@services/jsonapi-services/htc920.service';
import { Htc920_actual_configService } from '@services/jsonapi-services/htc920_actual_config.service';
import { Htc920_desired_configService } from '@services/jsonapi-services/htc920_desired_config.service';
import { Ngc20Service } from '@services/jsonapi-services/ngc20.service';
import { Ngc40htcService } from '@services/jsonapi-services/ngc40htc.service';
import { Ngc40htc3Service } from '@services/jsonapi-services/ngc40htc3.service';
import { Ngc40htc3_actual_configService } from '@services/jsonapi-services/ngc40htc3_actual_config.service';
import { Ngc40htc3_desired_configService } from '@services/jsonapi-services/ngc40htc3_desired_config.service';
import { Ngc40htc_actual_configService } from '@services/jsonapi-services/ngc40htc_actual_config.service';
import { Ngc40htc_desired_configService } from '@services/jsonapi-services/ngc40htc_desired_config.service';
import { Ngc40ioService } from '@services/jsonapi-services/ngc40io.service';
import { Ngc40io_actual_configService } from '@services/jsonapi-services/ngc40io_actual_config.service';
import { Ngc40io_desired_configService } from '@services/jsonapi-services/ngc40io_desired_config.service';
import { Ngc40slimService } from '@services/jsonapi-services/ngc40slim.service';
import { Ngc40slim_actual_configService } from '@services/jsonapi-services/ngc40slim_actual_config.service';
import { Ngc40slim_desired_configService } from '@services/jsonapi-services/ngc40slim_desired_config.service';
import { DeviceNoteData, NoteService } from '@services/jsonapi-services/notes.service';
import { Cm2000DeviceDataMapperService } from '@services/mapper-services/cm2000-device-data-mapper.service';
import { Cm2000pDeviceDataMapperService } from '@services/mapper-services/cm2000p-device-data-mapper.service';
import { Elexant3500iDeviceDataMapperService } from '@services/mapper-services/elexant3500i-device-data-mapper.service';
import { Elexant40X0DeviceDataMapperService } from '@services/mapper-services/elexant40X0-device-data-mapper.service';
import { Elexant5010iDeviceDataMapperService } from '@services/mapper-services/elexant5010i-device-data-mapper.service';
import { HostConfigMapperService } from '@services/mapper-services/host-config-mapper.service';
import { Htc910DeviceDataMapperService } from '@services/mapper-services/htc910-device-data-mapper.service';
import { Htc920DeviceDataMapperService } from '@services/mapper-services/htc920-device-data-mapper.service';
import { Ngc20DeviceDataMapperService } from '@services/mapper-services/ngc20-device-data-mapper.service';
import { Ngc40htcDeviceDataMapperService } from '@services/mapper-services/ngc40htc-device-data-mapper.service';
import { Ngc40htc3DeviceDataMapperService } from '@services/mapper-services/ngc40htc3-device-data-mapper.service';
import { Ngc40ioDeviceDataMapperService } from '@services/mapper-services/ngc40io-device-data-mapper.service';
import { Ngc40slimDeviceDataMapperService } from '@services/mapper-services/ngc40slim-device-data-mapper.service';
import { NotesMapperService } from '@services/mapper-services/notes-mapper.service';
import {
  cm2000TrendDataParam,
  cm2000pTrendDataParam,
  deviceNotesParams,
  elexant3500iTrendDataParam,
  elexant40x0TrendDataParam,
  elexant5010iTrendDataParam,
  hostsWithActualConfigParms,
  htc910TrendDataParam,
  htc920TrendDataParam,
  ngc20TrendDataParam,
  ngc40htc3TrendDataParam,
  ngc40htcTrendDataParam,
  ngc40ioTrendDataParam,
  ngc40slimTrendDataParam,
} from '@utils/constants/params';
import { DeviceType } from '@utils/enums/device-enums';
import { Observable, map, of } from 'rxjs';
import { Elexant5010i_actual_configService } from './elexant5010i-actual-config.service';
import { Elexant5010i_desired_configService } from './elexant5010i-desired-config.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceMiddlewareService {
  constructor(
    private htc910Service: Htc910Service,
    private htc920Service: Htc920Service,
    private ngc20Service: Ngc20Service,
    private ngc40htcService: Ngc40htcService,
    private ngc40htc3Service: Ngc40htc3Service,
    private ngc40ioService: Ngc40ioService,
    private ngc40slimService: Ngc40slimService,
    private cm2000Service: Cm2000Service,
    private cm2000pService: Cm2000pService,
    private elexant40x0Service: Elexant40X0Service,
    private elexant5010iService: Elexant5010iService,
    private htc910MapperService: Htc910DeviceDataMapperService,
    private htc920MapperService: Htc920DeviceDataMapperService,
    private ngc20MapperService: Ngc20DeviceDataMapperService,
    private ngc40htcMapperService: Ngc40htcDeviceDataMapperService,
    private ngc40htc3MapperService: Ngc40htc3DeviceDataMapperService,
    private ngc40ioMapperService: Ngc40ioDeviceDataMapperService,
    private ngc40slimMapperService: Ngc40slimDeviceDataMapperService,
    private cm2000MapperService: Cm2000DeviceDataMapperService,
    private cm2000pMapperService: Cm2000pDeviceDataMapperService,
    private elexant40x0MapperService: Elexant40X0DeviceDataMapperService,
    private elexant5010iMapperService: Elexant5010iDeviceDataMapperService,
    private ngc40htc3ActualConfigService: Ngc40htc3_actual_configService,
    private ngc40htc3DesiredConfigService: Ngc40htc3_desired_configService,
    private cm2000ActualConfigService: Cm2000_actual_configService,
    private cm2000DesiredConfigService: Cm2000_desired_configService,
    private cm2000pActualConfigService: Cm2000p_actual_configService,
    private cm2000pDesiredConfigService: Cm2000p_desired_configService,
    private htc910ActualConfigService: Htc910_actual_configService,
    private htc910DesiredConfigService: Htc910_desired_configService,
    private htc920ActualConfigService: Htc920_actual_configService,
    private htc920DesiredConfigService: Htc920_desired_configService,
    private elexant40x0ActualConfigService: Elexant40x0_actual_configService,
    private elexant40x0DesiredConfigService: Elexant40x0_desired_configService,
    private ngc40htcActualConfigService: Ngc40htc_actual_configService,
    private ngc40htcDesiredConfigService: Ngc40htc_desired_configService,
    private ngc40ioActualConfigService: Ngc40io_actual_configService,
    private ngc40ioDesiredConfigService: Ngc40io_desired_configService,
    private ngc40slimDesiredConfigService: Ngc40slim_desired_configService,
    private ngc40slimActualConfigService: Ngc40slim_actual_configService,
    private elexant3500iActualConfigService: Elexant3500i_actual_configService,
    private elexant3500iDesiredConfigService: Elexant3500i_desired_configService,
    private notesService: NoteService,
    private notesDataMapper: NotesMapperService,
    private elexant3500iMapperService: Elexant3500iDeviceDataMapperService,
    private elexant3500iService: Elexant3500iService,
    private elexant5010iActualConfigService: Elexant5010i_actual_configService,
    private elexant5010iDesiredConfigService: Elexant5010i_desired_configService,
    private hostService: HostService,
    private hostConfigMapperService: HostConfigMapperService,
  ) {}

  getDeviceTrendsDataFromJsonApi(id: string, type: string): Observable<TrendDataUIModel> {
    switch (type) {
      case DeviceType.Htc910:
        return this.htc910Service.getHtc910TrendData(htc910TrendDataParam, id).pipe(
          map((deviceTrendData: any) => {
            return this.htc910MapperService.mapHtc910TrendData(deviceTrendData.data, deviceTrendData.included);
          }),
        );
      case DeviceType.Htc920:
        return this.htc920Service.getHtc920TrendData(htc920TrendDataParam, id).pipe(
          map((deviceTrendData: any) => {
            return this.htc920MapperService.mapHtc920TrendData(deviceTrendData.data, deviceTrendData.included);
          }),
        );
      case DeviceType.Ngc20:
        return this.ngc20Service.getNgc20(id, ngc20TrendDataParam).pipe(
          map((deviceTrendData: any) => {
            return this.ngc20MapperService.mapNgc20TrendData(deviceTrendData.data, deviceTrendData.included);
          }),
        );
      case DeviceType.Ngc40htc:
        return this.ngc40htcService.getNgc40htcTrendData(ngc40htcTrendDataParam, id).pipe(
          map((deviceTrendData: any) => {
            return this.ngc40htcMapperService.mapNgc40HtcTrendData(deviceTrendData.data, deviceTrendData.included);
          }),
        );
      case DeviceType.Ngc40htc3:
        return this.ngc40htc3Service.getNgc40htc3TrendData(ngc40htc3TrendDataParam, id).pipe(
          map((deviceTrendData: any) => {
            return this.ngc40htc3MapperService.mapNgc40Htc3TrendData(deviceTrendData.data, deviceTrendData.included);
          }),
        );
      case DeviceType.Ngc40io:
        return this.ngc40ioService.getNgc40ioTrendData(ngc40ioTrendDataParam, id).pipe(
          map((deviceTrendData: any) => {
            return this.ngc40ioMapperService.mapNgc40IoTrendData(deviceTrendData.data, deviceTrendData.included);
          }),
        );
      case DeviceType.Ngc40slim:
        return this.ngc40slimService.getNgc40slimTrendData(ngc40slimTrendDataParam, id).pipe(
          map((deviceTrendData: any) => {
            return this.ngc40slimMapperService.mapNgc40SlimTrendData(deviceTrendData.data, deviceTrendData.included);
          }),
        );
      case DeviceType.Cm2000:
        return this.cm2000Service.getCm2000TrendData(cm2000TrendDataParam, id).pipe(
          map((deviceTrendData: any) => {
            return this.cm2000MapperService.mapCm2000TrendData(deviceTrendData.data, deviceTrendData.included);
          }),
        );
      case DeviceType.Cm2000p:
        return this.cm2000pService.getCm2000pTrendData(cm2000pTrendDataParam, id).pipe(
          map((deviceTrendData: any) => {
            return this.cm2000pMapperService.mapCm2000pTrendData(deviceTrendData.data, deviceTrendData.included);
          }),
        );
      case DeviceType.Elexant40X0i:
        return this.elexant40x0Service.getElexant40X0TrendData(elexant40x0TrendDataParam, id).pipe(
          map((deviceTrendData: any) => {
            return this.elexant40x0MapperService.mapElexant40X0TrendData(
              deviceTrendData.data,
              deviceTrendData.included,
            );
          }),
        );
      case DeviceType.Elexant3500i:
        return this.elexant3500iService.getElexant3500iTrendData(elexant3500iTrendDataParam, id).pipe(
          map((deviceTrendData: any) => {
            return this.elexant3500iMapperService.mapElexant3500iTrendData(
              deviceTrendData.data,
              deviceTrendData.included,
            );
          }),
        );
      case DeviceType.Elexant5010i:
        return this.elexant5010iService.getElexant5010iTrendData(elexant5010iTrendDataParam, id).pipe(
          map((deviceTrendData: any) => {
            return this.elexant5010iMapperService.mapElexant5010iTrendData(
              deviceTrendData.data,
              deviceTrendData.included,
            );
          }),
        );
      default:
        return of();
    }
  }

  getDeviceActualConfigDataFromJsonApi(id: string, deviceType: string): Observable<any> {
    const params = new HttpParams();
    switch (deviceType) {
      case DeviceType.Ngc40htc3:
        return this.ngc40htc3ActualConfigService.getNgc40htc3_actual_config(id, params);
      case DeviceType.Cm2000:
        return this.cm2000ActualConfigService.getCm2000_actual_config(id, params);
      case DeviceType.Cm2000p:
        return this.cm2000pActualConfigService.getCm2000p_actual_config(id, params);
      case DeviceType.Htc910:
        return this.htc910ActualConfigService.getHtc910_actual_config(id, params);
      case DeviceType.Htc920:
        return this.htc920ActualConfigService.getHtc920_actual_config(id, params);
      case DeviceType.Elexant40X0i:
        return this.elexant40x0ActualConfigService.getElexant40x0_actual_config(id, params);
      case DeviceType.Elexant5010i:
        return this.elexant5010iActualConfigService.getElexant5010i_actual_config(id, params);
      case DeviceType.Elexant3500i:
        return this.elexant3500iActualConfigService.getElexant3500i_actual_config(id, params);
      case DeviceType.Ngc40htc:
        return this.ngc40htcActualConfigService.getNgc40htc_actual_config(id, params);
      case DeviceType.Ngc40io:
        return this.ngc40ioActualConfigService.getNgc40io_actual_config(id, params);
      case DeviceType.Ngc40slim:
        return this.ngc40slimActualConfigService.getNgc40slim_actual_config(id, params);
      default:
        return of('');
    }
  }

  getDeviceDesiredConfigDataFromJsonApi(id: string, deviceType: string): Observable<any> {
    const params = new HttpParams();
    switch (deviceType) {
      case DeviceType.Ngc40htc3:
        return this.ngc40htc3DesiredConfigService.getNgc40htc3_desired_config(id, params);
      case DeviceType.Cm2000:
        return this.cm2000DesiredConfigService.getCm2000_desired_config(id, params);
      case DeviceType.Cm2000p:
        return this.cm2000pDesiredConfigService.getCm2000p_desired_config(id, params);
      case DeviceType.Htc910:
        return this.htc910DesiredConfigService.getHtc910_desired_config(id, params);
      case DeviceType.Htc920:
        return this.htc920DesiredConfigService.getHtc920_desired_config(id, params);
      case DeviceType.Elexant40X0i:
        return this.elexant40x0DesiredConfigService.getElexant40x0_desired_config(id, params);
      case DeviceType.Elexant5010i:
        return this.elexant5010iDesiredConfigService.getElexant5010i_desired_config(id, params);
      case DeviceType.Elexant3500i:
        return this.elexant3500iDesiredConfigService.getElexant3500i_desired_config(id, params);
      case DeviceType.Ngc40htc:
        return this.ngc40htcDesiredConfigService.getNgc40htc_desired_config(id, params);
      case DeviceType.Ngc40io:
        return this.ngc40ioDesiredConfigService.getNgc40io_desired_config(id, params);
      case DeviceType.Ngc40slim:
        return this.ngc40slimDesiredConfigService.getNgc40slim_desired_config(id, params);
      default:
        return of('');
    }
  }

  addDesiredConfigData(deviceType: string, data: any): Observable<any> {
    switch (deviceType) {
      case DeviceType.Ngc40htc3:
        return this.ngc40htc3DesiredConfigService.addNgc40htc3_desired_config(data);
      case DeviceType.Cm2000:
        return this.cm2000DesiredConfigService.addCm2000_desired_config(data);
      case DeviceType.Cm2000p:
        return this.cm2000pDesiredConfigService.addCm2000p_desired_config(data);
      case DeviceType.Htc910:
        return this.htc910DesiredConfigService.addHtc910_desired_config(data);
      case DeviceType.Htc920:
        return this.htc920DesiredConfigService.addHtc920_desired_config(data);
      case DeviceType.Elexant40X0i:
        return this.elexant40x0DesiredConfigService.addElexant40x0_desired_config(data);
      case DeviceType.Elexant5010i:
        return this.elexant5010iDesiredConfigService.addElexant5010i_desired_config(data);
      case DeviceType.Elexant3500i:
        return this.elexant3500iDesiredConfigService.addElexant3500i_desired_config(data);
      case DeviceType.Ngc40htc:
        return this.ngc40htcDesiredConfigService.addNgc40htc_desired_config(data);
      case DeviceType.Ngc40io:
        return this.ngc40ioDesiredConfigService.addNgc40io_desired_config(data);
      case DeviceType.Ngc40slim:
        return this.ngc40slimDesiredConfigService.addNgc40slim_desired_config(data);
      default:
        return of('');
    }
  }

  updateDesiredConfigData(deviceType: string, data: any): Observable<any> {
    switch (deviceType) {
      case DeviceType.Ngc40htc3:
        return this.ngc40htc3DesiredConfigService.updateNgc40htc3_desired_config(data);
      case DeviceType.Cm2000:
        return this.cm2000DesiredConfigService.updateCm2000_desired_config(data);
      case DeviceType.Cm2000p:
        return this.cm2000pDesiredConfigService.updateCm2000p_desired_config(data);
      case DeviceType.Htc910:
        return this.htc910DesiredConfigService.updateHtc910_desired_config(data);
      case DeviceType.Htc920:
        return this.htc920DesiredConfigService.updateHtc920_desired_config(data);
      case DeviceType.Elexant40X0i:
        return this.elexant40x0DesiredConfigService.updateElexant40x0_desired_config(data);
      case DeviceType.Elexant5010i:
        return this.elexant5010iDesiredConfigService.updateElexant5010i_desired_config(data);
      case DeviceType.Elexant3500i:
        return this.elexant3500iDesiredConfigService.updateElexant3500i_desired_config(data);
      case DeviceType.Ngc40htc:
        return this.ngc40htcDesiredConfigService.updateNgc40htc_desired_config(data);
      case DeviceType.Ngc40io:
        return this.ngc40ioDesiredConfigService.updateNgc40io_desired_config(data);
      case DeviceType.Ngc40slim:
        return this.ngc40slimDesiredConfigService.updateNgc40slim_desired_config(data);
      default:
        return of('');
    }
  }

  getNotesFromJsonApi(deviceId: string) {
    return this.notesService.getDevicesNotes(deviceId, deviceNotesParams).pipe(
      map(notes => {
        return this.notesDataMapper.mapNotesDataToFrontEnd(notes as DeviceNoteData);
      }),
    );
  }

  getHostsFromJsonApi() {
    return this.hostService.getHosts(hostsWithActualConfigParms).pipe(
      map((hostData: MultiHostData) => {
        return this.hostConfigMapperService.mapHostsDataFromBackend(
          hostData,
          hostData.included as IncludedRelationship[],
        );
      }),
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '@services/app-config.service';

const MEDIA_TYPE = 'application/vnd.api+json';

export interface DeviceNoteData {
  data: {
    type: string;
    id: string;
  };
  included: Note[];
}

const typeNameByRelName: Record<string, string> = {
  child_notes: 'notes',
  device: 'devices',
  parent_note: 'notes',
  user: 'users',
};

export interface NoteAttributes {
  created_at: string;
  text: string;
}

export interface NoteRelationships {
  child_notes: { data: ResourceIdentifierObject[] };
  device: { data: ResourceIdentifierObject };
  parent_note: { data: ResourceIdentifierObject | null };
  user: { data: ResourceIdentifierObject };
}

export interface Note<Attributes = Partial<NoteAttributes>, Relationships = Partial<NoteRelationships>> {
  type: string;
  id?: string;
  attributes?: Attributes;
  relationships?: Relationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface NoteData {
  data: Note;
  included?: ResourceIdentifierObject[];
}

export interface MultiNoteData {
  data: Note[];
  included?: ResourceIdentifierObject[];
}

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  private API_RESOURCES_URL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.API_RESOURCES_URL = appConfigService.getJsonApiUrl();
  }

  getDevicesNotes(id: string, params: HttpParams) {
    const url = `${this.API_RESOURCES_URL}/devices/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        Accept: MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get(url, options);
  }

  getMulti(params: HttpParams): Observable<MultiNoteData> {
    const url = `${this.API_RESOURCES_URL}/notes`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        Accept: MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<MultiNoteData>(url, options);
  }

  get(id: string, params: HttpParams): Observable<NoteData> {
    const url = `${this.API_RESOURCES_URL}/notes/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        Accept: MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<NoteData>(url, options);
  }

  add(note: Note): Observable<never> {
    const url = `${this.API_RESOURCES_URL}/notes`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        'Content-Type': MEDIA_TYPE,
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.post<never>(url, { data: note }, options);
  }

  addWithoutId(note: Note): Observable<Note> {
    const url = `${this.API_RESOURCES_URL}/notes`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        'Content-Type': MEDIA_TYPE,
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.post<Note>(url, { data: note }, options);
  }

  update(note: Note): Observable<NoteData> {
    const url = `${this.API_RESOURCES_URL}/notes/` + note.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        'Content-Type': MEDIA_TYPE,
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.patch<NoteData>(url, { data: note }, options);
  }

  remove(id: string): Observable<never> {
    const url = `${this.API_RESOURCES_URL}/notes/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: this.authorizationScopeToken(),
        Accept: MEDIA_TYPE,
      }),
    };
    return this.http.delete<never>(url, options);
  }
}

import { HttpParams } from '@angular/common/http';
import { IdTokenPayload } from '@models/id-token';

export const ngc40htcParam: HttpParams = new HttpParams()
  .set('include', 'latest_state,device,latest_actual_config')
  .set(
    'fields[ngc40htc_latest_states]',
    'htc_0_control_temperature_at,htc_0_line_current_0_effective,htc_0_temperature_source_1_value,htc_0_temperature_source_0_value,htc_0_temperature_source_0_value_error, htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,htc_0_ssr_output,alarm_0_value,htc_0_control_temperature,htc_0_control_temperature_error,htc_0_switch_state',
  )
  .set(
    'fields[ngc40htc_actual_configs]',
    'device_tag,htc_0_control_temperature_setpoint,htc_0_control_temperature_high_alarm_setpoint,htc_0_control_temperature_low_alarm_setpoint,htc_0_ground_fault_current_high_alarm_setpoint,htc_0_ground_fault_current_trip_setpoint,htc_0_line_current_0_high_alarm_setpoint,htc_0_line_current_0_low_alarm_setpoint',
  )
  .set('fields[devices]', 'priority');
export const ngc40htc3Param: HttpParams = new HttpParams()
  .set('include', 'latest_state,device,latest_actual_config,info')
  .set(
    'fields[ngc40htc3_latest_states]',
    'htc_0_control_temperature_at,htc_0_line_current_0_effective,htc_0_temperature_source_1_value,htc_0_temperature_source_0_value,htc_0_temperature_source_0_value_error,htc_0_line_current_1_effective,htc_0_line_current_2_effective,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,htc_0_ssr_output,alarm_0_value,htc_0_control_temperature,htc_0_control_temperature_error,htc_0_switch_state',
  )
  .set(
    'fields[ngc40htc3_actual_configs]',
    'device_tag,htc_0_control_temperature_setpoint,htc_0_control_temperature_high_alarm_setpoint,htc_0_control_temperature_low_alarm_setpoint,htc_0_ground_fault_current_high_alarm_setpoint,htc_0_ground_fault_current_trip_setpoint,htc_0_line_current_0_high_alarm_setpoint,htc_0_line_current_0_low_alarm_setpoint,htc_0_line_current_1_high_alarm_setpoint,htc_0_line_current_1_low_alarm_setpoint,htc_0_line_current_2_high_alarm_setpoint,htc_0_line_current_2_low_alarm_setpoint',
  )
  .set('fields[ngc40htc3_infos]', 'device_firmware_version,device_serial')
  .set('fields[devices]', 'priority');

export const ngc40ioParam: HttpParams = new HttpParams()
  .set('include', 'device,latest_state,latest_actual_config')
  .set(
    'fields[ngc40io_latest_states]',
    'alarm_0_value,digital_input_0_value,temperature_0_value,temperature_0_value_error,temperature_1_value,temperature_1_value_error,temperature_2_value,temperature_2_value_error,temperature_3_value,temperature_3_value_error',
  )
  .set(
    'fields[ngc40io_actual_configs]',
    'device_tag,temperature_0_high_alarm_setpoint,temperature_0_low_alarm_setpoint,temperature_1_high_alarm_setpoint,temperature_1_low_alarm_setpoint,temperature_2_high_alarm_setpoint,temperature_2_low_alarm_setpoint,temperature_3_high_alarm_setpoint,temperature_3_low_alarm_setpoint',
  )
  .set('fields[devices]', 'priority');

export const ngc40slimParam: HttpParams = new HttpParams()
  .set('include', 'device,latest_state,latest_actual_config,info')
  .set(
    'fields[ngc40slim_latest_states]',
    'alarm_0_value,temperature_0_value,temperature_0_value_error,temperature_1_value,temperature_1_value_error,temperature_2_value,temperature_2_value_error',
  )
  .set('fields[ngc40slim_actual_configs]', 'device_tag')
  .set('fields[devices]', 'priority')
  .set('fields[ngc40slim_infos]', 'temperature_0_lower_bound,temperature_0_upper_bound');

export const htc910Param: HttpParams = new HttpParams()
  .set('include', 'device,latest_state,latest_actual_config')
  .set(
    'fields[htc910_latest_states]',
    'alarm_0_value,temperature_0_value,temperature_0_value_error,temperature_1_value,temperature_1_value_error,htc_0_control_temperature,htc_0_control_temperature_error,htc_0_load_current,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,htc_0_raw_switch_output',
  )
  .set(
    'fields[htc910_actual_configs]',
    'device_tag,htc_0_control_temperature_setpoint,temperature_0_high_alarm_setpoint,temperature_0_low_alarm_setpoint,temperature_1_high_alarm_setpoint,temperature_1_low_alarm_setpoint,htc_0_ground_fault_current_high_alarm_setpoint,htc_0_ground_fault_current_trip_setpoint,htc_0_load_current_high_alarm_setpoint,htc_0_load_current_low_alarm_setpoint,htc_0_voltage_high_alarm_setpoint,htc_0_voltage_low_alarm_setpoint',
  )
  .set('fields[devices]', 'priority');

export const htc920Param: HttpParams = new HttpParams()
  .set('include', 'device,latest_state,latest_actual_config')
  .set(
    'fields[htc920_latest_states]',
    'alarm_0_value,temperature_0_value,temperature_0_value_error,temperature_1_value,temperature_1_value_error,htc_0_control_temperature,htc_0_control_temperature_error,htc_0_load_current,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,htc_0_raw_switch_output',
  )
  .set(
    'fields[htc920_actual_configs]',
    'device_tag,htc_0_control_temperature_setpoint,temperature_0_high_alarm_setpoint,temperature_0_low_alarm_setpoint,temperature_1_high_alarm_setpoint,temperature_1_low_alarm_setpoint,htc_0_ground_fault_current_high_alarm_setpoint,htc_0_ground_fault_current_trip_setpoint,htc_0_load_current_high_alarm_setpoint,htc_0_load_current_low_alarm_setpoint,htc_0_voltage_high_alarm_setpoint,htc_0_voltage_low_alarm_setpoint',
  )
  .set('fields[devices]', 'priority');

export const cm2000Param: HttpParams = new HttpParams()
  .set('include', 'device,latest_state,latest_actual_config')
  .set(
    'fields[cm2000_latest_states]',
    'temperature_0_value,temperature_0_value_error,temperature_1_value,temperature_1_value_error,temperature_1_value_error,htc_0_control_temperature,htc_0_control_temperature_error,htc_0_load_current,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0',
  )
  .set(
    'fields[cm2000_actual_configs]',
    'htc_0_control_temperature_setpoint,temperature_0_high_alarm_setpoint,temperature_0_low_alarm_setpoint,temperature_1_high_alarm_setpoint,temperature_1_low_alarm_setpoint,htc_0_ground_fault_current_high_alarm_setpoint,htc_0_ground_fault_current_trip_setpoint,htc_0_load_current_high_alarm_setpoint,htc_0_load_current_low_alarm_setpoint,htc_0_voltage_high_alarm_setpoint,htc_0_voltage_low_alarm_setpoint',
  )
  .set('fields[devices]', 'priority');

export const cm2000pParam: HttpParams = new HttpParams()
  .set('include', 'device,latest_state,latest_actual_config')
  .set(
    'fields[cm2000p_latest_states]',
    'temperature_0_value,temperature_0_value_error,temperature_1_value,temperature_1_value_error,htc_0_control_temperature,htc_0_control_temperature_error,htc_0_load_current,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0',
  )
  .set(
    'fields[cm2000p_actual_configs]',
    'htc_0_control_temperature_setpoint,temperature_0_high_alarm_setpoint,temperature_0_low_alarm_setpoint,temperature_1_high_alarm_setpoint,temperature_1_low_alarm_setpoint,htc_0_ground_fault_current_high_alarm_setpoint,htc_0_ground_fault_current_trip_setpoint,htc_0_load_current_high_alarm_setpoint,htc_0_load_current_low_alarm_setpoint,htc_0_voltage_high_alarm_setpoint,htc_0_voltage_low_alarm_setpoint',
  )
  .set('fields[devices]', 'priority');

export const bridgeParam = new HttpParams()
  .set('include', 'peer_device,device.ngc40bridge.latest_actual_config,device.ac2000p.latest_actual_config')
  .set('filter[article]', '00000000-0000-0000-0300-400300000000,00000000-0000-0000-0100-4003100d0000')
  .set('fields[ngc40bridge_actual_configs]', 'device_tag')
  .set('fields[ac2000p_actual_configs]', 'device_tag');

export const ngc40htc3TrendDataParam: HttpParams = new HttpParams()
  .set('include', 'states,latest_actual_config')
  .set('fields[ngc40htc3s]', 'states,latest_actual_config')
  .set('fields[ngc40htc3_actual_configs]', 'htc_0_control_temperature_setpoint')
  .set(
    'fields[ngc40htc3_states]',
    'htc_0_control_temperature,htc_0_temperature_source_0_value,htc_0_temperature_source_1_value,htc_0_line_current_0_effective,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,htc_0_ssr_output,htc_0_switch_state,created_at',
  );

export const ngc40htcTrendDataParam: HttpParams = new HttpParams()
  .set('include', 'states,latest_actual_config')
  .set('fields[ngc40htcs]', 'states,latest_actual_config')
  .set('fields[ngc40htc_actual_configs]', 'htc_0_control_temperature_setpoint')
  .set(
    'fields[ngc40htc_states]',
    'htc_0_control_temperature,htc_0_temperature_source_0_value,htc_0_temperature_source_1_value,htc_0_line_current_0_effective,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,htc_0_ssr_output,htc_0_switch_state,created_at',
  );

export const htc910TrendDataParam: HttpParams = new HttpParams()
  .set('include', 'states,latest_actual_config')
  .set('fields[htc910s]', 'states,latest_actual_config')
  .set('fields[htc910_actual_configs]', 'htc_0_control_temperature_setpoint')
  .set(
    'fields[htc910_states]',
    'htc_0_control_temperature,temperature_0_value,temperature_1_value,htc_0_load_current,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,htc_0_raw_switch_output,created_at',
  );

export const htc920TrendDataParam: HttpParams = new HttpParams()
  .set('include', 'states,latest_actual_config')
  .set('fields[htc920s]', 'states,latest_actual_config')
  .set('fields[htc920_actual_configs]', 'htc_0_control_temperature_setpoint')
  .set(
    'fields[htc920_states]',
    'htc_0_control_temperature,temperature_0_value,temperature_1_value,htc_0_load_current,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,htc_0_raw_switch_output,created_at',
  );

export const ngc20TrendDataParam: HttpParams = new HttpParams()
  .set('include', 'states,latest_actual_config')
  .set('fields[ngc20s]', 'states,latest_actual_config')
  .set('fields[ngc20_actual_configs]', 'htc_0_control_temperature_setpoint')
  .set(
    'fields[ngc20_states]',
    'htc_0_control_temperature,temperature_0_value,temperature_1_value,htc_0_load_current,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,htc_0_switch_state,created_at',
  );

export const ngc40ioTrendDataParam: HttpParams = new HttpParams()
  .set('include', 'states')
  .set('fields[ngc40ios]', 'states')
  .set(
    'fields[ngc40io_states]',
    'temperature_0_value,temperature_1_value,temperature_2_value,temperature_3_value,created_at',
  );

export const ngc40slimTrendDataParam: HttpParams = new HttpParams()
  .set('include', 'states')
  .set('fields[ngc40ios]', 'states')
  .set('fields[ngc40io_states]', 'temperature_0_value,temperature_1_value,temperature_2_value,created_at');

export const cm2000TrendDataParam: HttpParams = new HttpParams()
  .set('include', 'states,latest_actual_config')
  .set('fields[cm2000_actual_configs]', 'htc_0_control_temperature_setpoint')
  .set(
    'fields[cm2000_states]',
    'htc_0_control_temperature,temperature_0_value,temperature_1_value,htc_0_load_current,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,created_at',
  );

export const cm2000pTrendDataParam: HttpParams = new HttpParams()
  .set('include', 'states,latest_actual_config')
  .set('fields[cm2000p_actual_configs]', 'htc_0_control_temperature_setpoint')
  .set(
    'fields[cm2000p_states]',
    'htc_0_control_temperature,temperature_0_value,temperature_1_value,htc_0_load_current,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,created_at',
  );

export const elexant40x0TrendDataParam: HttpParams = new HttpParams()
  .set('include', 'states,latest_actual_config')
  .set('fields[elexant40x0s]', 'states,latest_actual_config')
  .set('fields[elexant40x0_actual_configs]', 'htc_0_control_temperature_setpoint')
  .set(
    'fields[elexant40x0_states]',
    'htc_0_control_temperature,temperature_0_value,temperature_1_value,htc_0_line_current_0,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,htc_0_switch_state,created_at',
  );

export const elexant3500iTrendDataParam: HttpParams = new HttpParams()
  .set('include', 'states,latest_actual_config')
  .set('fields[elexant3500is]', 'states,latest_actual_config')
  .set('fields[elexant3500i_actual_configs]', 'htc_0_control_temperature_setpoint')
  .set(
    'fields[elexant3500i_states]',
    'htc_0_control_temperature,temperature_0_value,temperature_1_value,htc_0_load_current,htc_0_ground_fault_current,htc_0_switch_state,created_at',
  );
export const elexant5010iTrendDataParam: HttpParams = new HttpParams()
  .set('include', 'states,latest_actual_config')
  .set('fields[elexant5010is]', 'states,latest_actual_config')
  .set('fields[elexant5010i_actual_configs]', 'htc_0_control_temperature_setpoint')
  .set(
    'fields[elexant5010i_states]',
    'htc_0_control_temperature,temperature_0_value,temperature_1_value,htc_0_load_current,htc_0_ground_fault_current,htc_0_line_voltage,htc_0_power_consumption_0,htc_0_switch_state,created_at',
  );

export const userPreferenceDataParam: HttpParams = new HttpParams()
  .set('include', 'preference')
  .set('fields[preferences]', 'unit');

export const multiAlarmsDataParam: HttpParams = new HttpParams()
  .set(
    'include',
    'article,device,device.ac2000p.latest_actual_config,device.ngc40htc.latest_actual_config,device.ngc40htc3.latest_actual_config,device.ngc40io.latest_actual_config,device.ngc40slim.latest_actual_config,device.htc910.latest_actual_config,device.htc920.latest_actual_config,device.elexant3500i.latest_actual_config,device.ngc20.latest_actual_config,device.elexant40x0.latest_actual_config,device.elexant5010i.latest_actual_config,device.cm2000,device.cm2000p',
  )
  .set('fields[articles]', 'name,description')
  .set('fields[ac2000p_actual_configs]', 'device_tag')
  .set('fields[ngc40htc_actual_configs]', 'device_tag')
  .set('fields[ngc40htc3_actual_configs]', 'device_tag')
  .set('fields[ngc40io_actual_configs]', 'device_tag')
  .set('fields[ngc40slim_actual_configs]', 'device_tag')
  .set('fields[htc910_actual_configs]', 'device_tag')
  .set('fields[htc920_actual_configs]', 'device_tag')
  .set('fields[elexant3500i_actual_configs]', 'device_tag')
  .set('fields[elexant40x0_actual_configs]', 'device_tag')
  .set('fields[ngc20_actual_configs]', 'device_tag')
  .set('fields[elexant5010i_actual_configs]', 'device_tag')
  .set('fields[cm2000s]', 'name')
  .set('fields[cm2000ps]', 'name');

export const alarmDataParam: HttpParams = new HttpParams()
  .set(
    'include',
    'article,device.ac2000p.latest_actual_config,device.ngc40htc.latest_actual_config,device.ngc40htc3.latest_actual_config,device.ngc40io.latest_actual_config,device.ngc40slim.latest_actual_config,device.htc910.latest_actual_config,device.htc920.latest_actual_config,device.elexant5010i.latest_actual_config,device.ngc20.latest_actual_config,device.elexant3500i.latest_actual_config,device.elexant40x0.latest_actual_config,device.cm2000,device.cm2000p',
  )
  .set('fields[articles]', 'name,description')
  .set('fields[ac2000p_actual_configs]', 'device_tag')
  .set('fields[ngc40htc_actual_configs]', 'device_tag')
  .set('fields[ngc40htc3_actual_configs]', 'device_tag')
  .set('fields[ngc40io_actual_configs]', 'device_tag')
  .set('fields[ngc40slim_actual_configs]', 'device_tag')
  .set('fields[htc910_actual_configs]', 'device_tag')
  .set('fields[htc920_actual_configs]', 'device_tag')
  .set('fields[elexant3500i_actual_configs]', 'device_tag')
  .set('fields[elexant40x0_actual_configs]', 'device_tag')
  .set('fields[ngc20_actual_configs]', 'device_tag')
  .set('fields[elexant5010i_actual_configs]', 'device_tag')
  .set('fields[cm2000s]', 'name')
  .set('fields[cm2000ps]', 'name');

export const alarmPulseDataParam = (id: string): HttpParams => {
  return new HttpParams().set('filter[alarm]', `${id}`).set('filter[latest]', 'true');
};

export const alarmPulseDataWithAlarmParam = (id: string): HttpParams => {
  return new HttpParams()
    .set('filter[alarm]', `${id}`)
    .set('filter[latest]', 'true')
    .set(
      'include',
      'alarm.article,alarm.device.ac2000p.latest_actual_config,alarm.device.ngc40htc.latest_actual_config,alarm.device.ngc40htc3.latest_actual_config,alarm.device.ngc40io.latest_actual_config,alarm.device.ngc40slim.latest_actual_config,alarm.device.htc910.latest_actual_config,alarm.device.htc920.latest_actual_config,alarm.device.elexant5010i.latest_actual_config,alarm.device.elexant40X0.latest_actual_config,alarm.device.elexant3500i.latest_actual_config,alarm.device.ngc20.latest_actual_config,alarm.device.cm2000,alarm.device.cm2000p',
    )
    .set('fields[ngc40htc_actual_configs]', 'device_tag')
    .set('fields[ac2000p_actual_configs]', 'device_tag')
    .set('fields[ngc40htc3_actual_configs]', 'device_tag')
    .set('fields[ngc40io_actual_configs]', 'device_tag')
    .set('fields[ngc40slim_actual_configs]', 'device_tag')
    .set('fields[htc910_actual_configs]', 'device_tag')
    .set('fields[htc920_actual_configs]', 'device_tag')
    .set('fields[elexant3500i_actual_configs]', 'device_tag')
    .set('fields[elexant40x0_actual_configs]', 'device_tag')
    .set('fields[ngc20_actual_configs]', 'device_tag')
    .set('fields[elexant5010i_actual_configs]', 'device_tag')
    .set('fields[cm2000s]', 'name')
    .set('fields[cm2000ps]', 'name');
};

export const idProviderParam: HttpParams = new HttpParams().set('filter[name]', 'keycloak').set('[id_providers]', '');

export const userParam = (token: IdTokenPayload): HttpParams => {
  return new HttpParams().set('filter[id_tokens.subject]', token.sub).set('fields[users]', 'eula_accepted');
};

export const deviceNotesParams: HttpParams = new HttpParams()
  .set('include', 'notes')
  .set('fields[notes]', 'text,created_at,user,parent_note,child_notes')
  .set('fields[devices]', '');

export const repliesToNotesParams: HttpParams = new HttpParams().set('include', 'child_notes');

export const maintenanceInfoParams = (deviceType: string | undefined): HttpParams => {
  return new HttpParams().set('include', `latest_state`).set(`fields[${deviceType}s]`, '');
};

export const userPermissionsParams = (roleName: string): HttpParams => {
  return new HttpParams()
    .set('filter[name]', `${roleName}`)
    .set('include', 'permissions')
    .set('fields[permissions]', 'operation,resource');
};

export const hostsWithActualConfigParms: HttpParams = new HttpParams().set('include', 'latest_actual_config');

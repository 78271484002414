export interface TestIdProvider {
  name: string;
  displayName: string;
  logoSrc: string;
  clientID: string;
  tokenEndpoint: string;
  configurationURL: string;
}

export interface TokenResponse {
  id_token: string;
  refresh_token?: string;
}

export type Authorize = {
  idToken: string;
  refreshToken?: string;
  codeVerifier: string;
  state: string;
  providerName: string;
};

export const localStorageKeyList = {
  authorizeKeyName: 'authorize',
  viewStyleKeyName: 'view-style',
  paginationDetailsKeyName: 'pagination-details',
  groupPaginationDetailsKeyName: 'group-pagination-details',
  alarmsPaginationDetailsKeyName: 'alarms-pagination-details',
};

<ng-template #headerLeftTemplate>
  <span class="font-lg">{{ title }}</span>
  <ng-content select="[header-left-content]"></ng-content>
</ng-template>

<ng-template #headerRightTemplate>
  @if (title === pageEnum.Devices || title === pageEnum.Alarms) {
    <form>
      <mat-form-field class="search-bar-wrapper">
        <div class="flex">
          <span class="material-symbols-outlined flex-col-center cl-grey">search</span>
          <input
            type="text"
            class="search-bar"
            placeholder="Search"
            matInput
            [matAutocomplete]="auto"
            [formControl]="searchControl" />
          <span
            class="material-symbols-outlined flex-col-center font-med cl-grey cursor-pointer"
            (click)="clearSearch()"
            >close</span
          >
        </div>
        <mat-autocomplete #auto="matAutocomplete" class="overlay-container">
          @if (searchString && deviceCount > 0) {
            <mat-option [disabled]="true">
              <div class="list-option">
                <span class="material-symbols-outlined cl-dark"> filter_alt </span> Search for
                {{ searchString }}
              </div>
            </mat-option>
          }
          @for (device of searchedDevices | async; track device) {
            @if (searchString) {
              <mat-option [value]="device.deviceTag">
                <div class="list-option">
                  <span class="material-symbols-outlined cl-grey"> deployed_code </span>
                  {{ device.deviceTag }}
                </div>
              </mat-option>
            }
          }
          @if (searchString && !deviceCount) {
            <mat-option [disabled]="true" class="no-device-found">No device found</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    </form>
  }
  <ng-content select="[header-right-content]"></ng-content>
</ng-template>

<div class="header-left-content">
  <div class="inner-container">
    <ng-container *ngTemplateOutlet="headerLeftTemplate"></ng-container>
  </div>
  <div class="inner-container">
    <ng-container *ngTemplateOutlet="headerRightTemplate"></ng-container>
  </div>
</div>

import { TrendDataUIModel } from '@models/device';
import { PlotlyNewDataPoint } from '@models/plotly-chart';
import { DeviceType } from '@utils/enums/device-enums';
import { TemperatureSeriesAttributes } from '@utils/enums/trend-chart-enums';
import { cloneDeep } from 'lodash-es';
import { initialCm2000xPowerSeriesData, initialCurrentSeriesData, initialPowerSeriesData } from './device-constants';

export function getChartSeriesData(deviceType: string): TrendDataUIModel {
  switch (deviceType) {
    case DeviceType.Cm2000:
    case DeviceType.Cm2000p:
      return {
        temperatureChart: [
          { name: TemperatureSeriesAttributes.controlTemperature, x: [], y: [] },
          { name: TemperatureSeriesAttributes.sensorTemperature1, x: [], y: [] },
          { name: TemperatureSeriesAttributes.sensorTemperature2, x: [], y: [] },
          { name: TemperatureSeriesAttributes.setPoint, x: [], y: [] },
        ],
        currentChart: cloneDeep(initialCurrentSeriesData),
        powerChart: cloneDeep(initialCm2000xPowerSeriesData),
      };
    case DeviceType.Ngc40io:
      return {
        temperatureChart: [
          { name: TemperatureSeriesAttributes.sensorTemperature1, x: [], y: [] },
          { name: TemperatureSeriesAttributes.sensorTemperature2, x: [], y: [] },
          { name: TemperatureSeriesAttributes.sensorTemperature3, x: [], y: [] },
          { name: TemperatureSeriesAttributes.sensorTemperature4, x: [], y: [] },
        ],
      };
    case DeviceType.Ngc40slim:
      return {
        temperatureChart: [
          { name: TemperatureSeriesAttributes.sensorTemperature1, x: [], y: [] },
          { name: TemperatureSeriesAttributes.sensorTemperature2, x: [], y: [] },
          { name: TemperatureSeriesAttributes.sensorTemperature3, x: [], y: [] },
        ],
      };
    case DeviceType.Elexant3500i:
      return {
        temperatureChart: [
          { name: TemperatureSeriesAttributes.controlTemperature, x: [], y: [] },
          { name: TemperatureSeriesAttributes.sensorTemperature1, x: [], y: [] },
          { name: TemperatureSeriesAttributes.sensorTemperature2, x: [], y: [] },
          { name: TemperatureSeriesAttributes.setPoint, x: [], y: [] },
        ],
        currentChart: cloneDeep(initialCurrentSeriesData),
      };
    default:
      return {
        temperatureChart: [
          { name: TemperatureSeriesAttributes.controlTemperature, x: [], y: [] },
          { name: TemperatureSeriesAttributes.sensorTemperature1, x: [], y: [] },
          { name: TemperatureSeriesAttributes.sensorTemperature2, x: [], y: [] },
          { name: TemperatureSeriesAttributes.setPoint, x: [], y: [] },
        ],
        currentChart: cloneDeep(initialCurrentSeriesData),
        powerChart: cloneDeep(initialPowerSeriesData),
      };
  }
}

export const initialNewDataPoints: PlotlyNewDataPoint = {
  x: [],
  y: [],
};

<div class="trend-selection-overlay">
  <app-overlay
    title="Trend Data Point Selection"
    overlayType="TrendOverlay"
    (closeOverlayEvent)="closeTrendSelectionDialog()">
    <ng-container body>
      <div class="selection-list">
        <mat-list>
          @if (keyOfTrendSelection) {
            @for (plotCategories of keyOfTrendSelection; track plotCategories) {
              @if (getPlotOptions(plotCategories)?.length) {
                <h2 mat-subheader>{{ getTrendDialogSubtitles()[$index] }}</h2>
                <mat-selection-list>
                  @for (category of getPlotOptions(plotCategories); track category) {
                    <mat-list-option [(selected)]="category.plotted" [checkboxPosition]="checkboxPosition">
                      {{ category.name }}
                    </mat-list-option>
                  }
                </mat-selection-list>
                <mat-divider></mat-divider>
              }
            }
          }
        </mat-list>
      </div>
    </ng-container>
    <ng-container footer>
      <div class="action-buttons">
        <button
          id="saveTrendsCategory"
          [ngClass]="getButtonStatus() ? 'round-edge-button disabled' : 'round-edge-button btn-black'"
          [disabled]="getButtonStatus()"
          (click)="onSave()">
          OK
        </button>
      </div>
    </ng-container>
  </app-overlay>
</div>

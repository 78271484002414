import { Injectable } from '@angular/core';
import { Base, IncludedRelationship } from '@app/models/device';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfigService } from '@app/services/app-config.service';
import { Observable } from 'rxjs';

interface Elexant40X0Attributes {
  name?: string;
  serial?: number;
}

export const typeNameByRelName: Record<string, string> = {
  actual_configs: 'elexant40x0_actual_configs',
  desired_config: 'elexant40x0_desired_configs',
  device: 'devices',
  info: 'elexant40x0_infos',
  latest_actual_config: 'elexant40x0_actual_configs',
  latest_state: 'elexant40x0_latest_states',
  states: 'elexant40x0_states',
};

export interface Elexant40X0_actual_configAttributes {
  device_tag: string;
  htc_0_control_temperature_setpoint?: string;
  htc_0_control_temperature_high_alarm_setpoint?: string;
  htc_0_control_temperature_low_alarm_setpoint?: string;
  htc_0_ground_fault_current_high_alarm_setpoint?: string;
  htc_0_ground_fault_current_trip_setpoint?: string;
  htc_0_line_current_0_high_alarm_setpoint?: string;
  htc_0_line_current_0_low_alarm_setpoint?: string;
  htc_0_voltage_high_alarm_setpoint?: string;
  htc_0_voltage_low_alarm_setpoint?: string;
  temperature_0_high_alarm_setpoint?: string;
  temperature_0_low_alarm_setpoint?: string;
  temperature_1_high_alarm_setpoint?: string;
  temperature_1_low_alarm_setpoint?: string;
  htc_0_power_0_maximum_limit_setpoints?: string;
}

export interface Elexant40X0_latest_stateAttributes {
  alarm_0_value?: boolean | null;
  created_at?: string | null;
  temperature_0_value?: string | null;
  temperature_0_value_error?: string | null;
  temperature_1_value?: string | null;
  temperature_1_value_error?: string | null;
  htc_0_control_temperature?: string | null;
  htc_0_control_temperature_error?: string | null;
  htc_0_ground_fault_current?: string | null;
  htc_0_line_voltage?: string | null;
  htc_0_power_consumption_0?: string | null;
  htc_0_line_current_0?: string | null;
  htc_0_switch_state?: boolean | null;
}

export interface Elexant40X0_latest_stateRelationships {
  Elexant40X0?: { data: Elexant40X0 };
}

export interface Elexant40X0_latest_actual_config extends Base {
  id: string;
  attributes?: Elexant40X0_actual_configAttributes;
}

export interface Elexant40X0_latest_state extends Base {
  id: string;
  attributes?: Elexant40X0_latest_stateAttributes;
  relationships?: Elexant40X0_latest_stateRelationships;
}

export interface Elexant40X0Relationships {
  actual_configs?: { data: Elexant40X0_latest_actual_config[] };
  desired_config?: { data: ResourceIdentifierObject[] };
  device?: { data: unknown };
  info?: { data: unknown };
  latest_actual_config?: { data: Elexant40X0_latest_actual_config };
  latest_state?: { data: Elexant40X0_latest_state };
  states?: { data: unknown[] };
}

export interface Elexant40X0 extends Base {
  id: string;
  type: string;
  attributes?: Elexant40X0Attributes;
  relationships?: Elexant40X0Relationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Elexant40X0Data {
  data: Elexant40X0;
  included?: IncludedRelationship[];
}

export interface Elexant40X0sData {
  data: Elexant40X0[];
  included?: IncludedRelationship[];
}

@Injectable({
  providedIn: 'root',
})
export class Elexant40X0Service {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getElexant40X0s(params: HttpParams): Observable<Elexant40X0sData> {
    const url = `${this.uiBaseURL}/elexant40x0s`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Elexant40X0sData>(url, options);
  }

  getElexant40X0(id: string, params: HttpParams): Observable<Elexant40X0Data> {
    const url = `${this.uiBaseURL}/elexant40x0s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Elexant40X0Data>(url, options);
  }

  addElexant40X0(elexant40X0: Elexant40X0): Observable<Elexant40X0> {
    const url = `${this.uiBaseURL}/elexant40x0s`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.post<Elexant40X0>(url, { data: elexant40X0 }, options);
  }

  updateElexant40X0(elexant40X0: Elexant40X0): Observable<Elexant40X0Data> {
    const url = `${this.uiBaseURL}/elexant40x0s/` + elexant40X0.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<Elexant40X0Data>(url, { data: elexant40X0 }, options);
  }

  removeElexant40X0(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/elexant40x0s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.delete<never>(url, options);
  }

  getElexant40X0TrendData(params: HttpParams, id: string): Observable<Elexant40X0sData> {
    const url = `${this.uiBaseURL}/elexant40x0s/` + id;
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Elexant40X0sData>(url, options);
  }
}

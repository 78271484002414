import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@services/app-config.service';
import { Observable } from 'rxjs';

const MEDIA_TYPE = 'application/vnd.api+json';

const typeNameByRelName: Record<string, string> = {
  id_providers: 'id_providers',
  id_tokens: 'id_tokens',
  organizations: 'organizations',
  roles: 'roles',
  user_roles: 'user_roles',
  device_filters: 'device_filters',
  preference: 'preferences',
  notes: 'notes',
};

export interface UserAttrs {
  eula_accepted: boolean;
}

export interface UserRels {
  organizations: { data: ResourceIdentifierObject[] };
  roles: { data: ResourceIdentifierObject[] };
  user_roles: { data: ResourceIdentifierObject[] };
  device_filters: { data: ResourceIdentifierObject[] };
  preference: { data: ResourceIdentifierObject };
}

export interface User<Attrs = UserAttrs, Rels = UserRels> {
  type?: string;
  id?: string;
  attributes?: Attrs;
  relationships?: Rels;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface UserData {
  data: User;
  included?: ResourceIdentifierObject[];
}

export interface MultiUserData {
  data: User[];
  included?: ResourceIdentifierObject[];
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getMulti(params: HttpParams): Observable<MultiUserData> {
    const url = `${this.uiBaseURL}/users`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
      params,
    };
    return this.http.get<MultiUserData>(url, options);
  }

  get(id: string, params: HttpParams): Observable<UserData> {
    const url = `${this.uiBaseURL}/users/` + id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
      params,
    };
    return this.http.get<UserData>(url, options);
  }

  addWithoutId(user: User): Observable<UserData> {
    const url = `${this.uiBaseURL}/users`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
    };
    return this.http.post<UserData>(url, { data: user }, options);
  }

  update(user: User): Observable<UserData> {
    const url = `${this.uiBaseURL}/users/${String(user.id)}`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
    };
    return this.http.patch<UserData>(url, { data: user }, options);
  }

  updateToManyRelationship(id: string, relName: string, rids: string[]): Observable<never> {
    const typ = typeNameByRelName[relName];
    const data: ResourceIdentifierObject[] = [];
    for (const rid of rids) {
      data.push({ type: typ, id: rid });
    }
    const url = `${this.uiBaseURL}/users/` + id + '/relationships/' + relName;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
    };
    return this.http.patch<never>(url, { data: data }, options);
  }
}

// Code generated by "jsonapi"; DO NOT EDIT.

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@app/services/app-config.service';
import {
  MultiNgc40io_desired_configData,
  Ngc40io_desired_config,
  Ngc40io_desired_configData,
} from '@models/jsonapi-models/desired-configs/ngc40io-desired-config.model';
import { Observable } from 'rxjs';

const MEDIA_TYPE = 'application/vnd.api+json';
@Injectable({
  providedIn: 'root',
})
export class Ngc40io_desired_configService {
  uiBaseUrl = '';
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
  ) {
    this.uiBaseUrl = appConfigService.getJsonApiUrl();
  }

  getNgc40io_desired_configs(params: HttpParams): Observable<MultiNgc40io_desired_configData> {
    const url = `${this.uiBaseUrl}/ngc40io_desired_configs`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<MultiNgc40io_desired_configData>(url, options);
  }

  getNgc40io_desired_config(id: string, params: HttpParams): Observable<Ngc40io_desired_configData> {
    const url = `${this.uiBaseUrl}/ngc40io_desired_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<Ngc40io_desired_configData>(url, options);
  }

  addNgc40io_desired_config(ngc40io_desired_config: Ngc40io_desired_config): Observable<Ngc40io_desired_config> {
    const url = `${this.uiBaseUrl}/ngc40io_desired_configs`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
    };
    return this.http.post<Ngc40io_desired_config>(url, { data: ngc40io_desired_config }, options);
  }

  updateNgc40io_desired_config(ngc40io_desired_config: Ngc40io_desired_config): Observable<Ngc40io_desired_configData> {
    const url = `${this.uiBaseUrl}/ngc40io_desired_configs/` + ngc40io_desired_config.id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
    };
    return this.http.patch<Ngc40io_desired_configData>(url, { data: ngc40io_desired_config }, options);
  }

  removeNgc40io_desired_config(id: string): Observable<never> {
    const url = `${this.uiBaseUrl}/ngc40io_desired_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
    };
    return this.http.delete<never>(url, options);
  }
}

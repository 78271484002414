import * as i0 from '@angular/core';
import { Injectable, TemplateRef, ComponentRef, ViewContainerRef, Component, ViewEncapsulation, Input, ViewChild, Directive, InjectionToken, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import { ReplaySubject } from 'rxjs';
const _c0 = ["templateOutlet"];
const _c1 = a0 => ({
  "active": a0
});
function BlockUIContentComponent_div_1_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.message || ctx_r0.defaultMessage, " ");
  }
}
function BlockUIContentComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelement(1, "div", 5);
    i0.ɵɵtemplate(2, BlockUIContentComponent_div_1_div_2_Template, 2, 1, "div", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.message || ctx_r0.defaultMessage);
  }
}
function BlockUIContentComponent_2_ng_template_0_Template(rf, ctx) {}
function BlockUIContentComponent_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, BlockUIContentComponent_2_ng_template_0_Template, 0, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
  }
}
const _c2 = ["*"];
const BlockUIDefaultName = 'block-ui-main';
class BlockUIActions {}
BlockUIActions.START = 'start';
BlockUIActions.STOP = 'stop';
BlockUIActions.UPDATE = 'update';
BlockUIActions.RESET = 'reset';
BlockUIActions.RESET_GLOBAL = 'reset_global';
BlockUIActions.UNSUBSCRIBE = 'unsubscribe';
class BlockUIInstanceService {
  constructor() {
    this.blockUISettings = {};
    this.blockUIInstances = {};
    this.blockUISubject = new ReplaySubject(1);
    this.blockUIObservable = this.blockUISubject.asObservable();
    this.blockUIObservable.subscribe(this.blockUIMiddleware.bind(this));
  }
  getSettings() {
    return this.blockUISettings;
  }
  updateSettings(settings = {}) {
    this.blockUISettings = {
      ...this.blockUISettings,
      ...settings
    };
  }
  decorate(name = BlockUIDefaultName) {
    const blockUI = {
      name,
      isActive: false,
      blockCount: 0,
      start: this.dispatch(this.blockUISubject, BlockUIActions.START, name),
      update: this.dispatch(this.blockUISubject, BlockUIActions.UPDATE, name),
      stop: this.dispatch(this.blockUISubject, BlockUIActions.STOP, name),
      reset: this.dispatch(this.blockUISubject, BlockUIActions.RESET, name),
      resetGlobal: this.dispatch(this.blockUISubject, BlockUIActions.RESET_GLOBAL, name),
      unsubscribe: this.dispatch(this.blockUISubject, BlockUIActions.UNSUBSCRIBE, name)
    };
    this.blockUIInstances[name] = this.blockUIInstances[name] || blockUI;
    return blockUI;
  }
  observe() {
    return this.blockUIObservable;
  }
  clearInstance(instanceName) {
    this.dispatch(this.blockUISubject, BlockUIActions.RESET, instanceName);
  }
  blockUIMiddleware({
    action,
    name
  }) {
    let isActive = null;
    switch (action) {
      case BlockUIActions.START:
        isActive = true;
        break;
      case BlockUIActions.STOP:
      case BlockUIActions.RESET:
        isActive = false;
        break;
    }
    if (isActive !== null) {
      this.blockUIInstances[name].isActive = isActive;
    }
  }
  dispatch(subject, action, name = BlockUIDefaultName) {
    return message => {
      subject.next({
        name,
        action,
        message
      });
    };
  }
}
BlockUIInstanceService.ɵfac = function BlockUIInstanceService_Factory(t) {
  return new (t || BlockUIInstanceService)();
};
BlockUIInstanceService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: BlockUIInstanceService,
  factory: BlockUIInstanceService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockUIInstanceService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();

// Spinner style - https://github.com/lukehaas/css-loaders
const styles = `
.block-ui-wrapper {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.70);
  z-index: 30000;
  cursor: wait;
}

.block-ui-wrapper.block-ui-wrapper--element {
  position: absolute;
}

.block-ui-wrapper.active {
  display: block;
}

.block-ui-wrapper.block-ui-main {
  position: fixed;
}

.block-ui-spinner,
.block-ui-template {
  position: absolute;
  top: 40%;
  margin: 0 auto;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.block-ui-spinner > .message {
  font-size: 1.3em;
  text-align: center;
  color: #fff;
}

.block-ui__element {
  position: relative;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 7px auto;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
`;
const template = `
<div class="block-ui-wrapper {{name}} {{className}}" [ngClass]="{ 'active': state.blockCount > 0 }">
  <div class="block-ui-spinner" *ngIf="!templateCmp">
    <div class="loader"></div>
    <div *ngIf="message || defaultMessage" class="message">
      {{ message || defaultMessage }}
    </div>
  </div>
  <ng-template *ngIf="templateCmp" #templateOutlet></ng-template>
</div>
`;
class BlockUIContentComponent {
  constructor(blockUI, resolver, changeDetectionRef) {
    this.blockUI = blockUI;
    this.resolver = resolver;
    this.changeDetectionRef = changeDetectionRef;
    this.name = BlockUIDefaultName;
    this.defaultBlockState = {
      startTimeouts: [],
      stopTimeouts: [],
      updateTimeouts: [],
      blockCount: 0,
      startCallCount: 0,
      stopCallCount: 0
    };
    this.state = {
      ...this.defaultBlockState
    };
  }
  ngOnInit() {
    this.settings = this.blockUI.getSettings();
    this.blockUISubscription = this.subscribeToBlockUI(this.blockUI.observe());
  }
  ngAfterViewInit() {
    try {
      if (!this.templateCmp) {
        return false;
      }
      if (this.templateCmp instanceof TemplateRef) {
        this.templateOutlet.createEmbeddedView(this.templateCmp);
      } else {
        const templateComp = this.resolver.resolveComponentFactory(this.templateCmp);
        this.templateCompRef = this.templateOutlet.createComponent(templateComp);
        this.updateBlockTemplate(this.message);
      }
    } catch (error) {
      console.error('ng-block-ui:', error);
    }
  }
  ngAfterViewChecked() {
    this.detectChanges();
  }
  subscribeToBlockUI(blockUI$) {
    return blockUI$.subscribe(event => this.onDispatchedEvent(event));
  }
  onDispatchedEvent(event) {
    switch (event.action) {
      case BlockUIActions.START:
        this.onStart(event);
        break;
      case BlockUIActions.STOP:
        this.onStop(event);
        break;
      case BlockUIActions.UPDATE:
        this.onUpdate(event);
        break;
      case BlockUIActions.RESET:
        this.onReset(event);
        break;
      case BlockUIActions.RESET_GLOBAL:
        this.resetState();
        break;
      case BlockUIActions.UNSUBSCRIBE:
        this.onStop(event);
        this.onUnsubscribe(event.name);
        break;
    }
  }
  onStart({
    name,
    message
  }) {
    if (name === this.name) {
      const delay = this.delayStart ?? this.settings.delayStart ?? 0;
      this.state.startCallCount += 1;
      const startTimeout = setTimeout(() => {
        this.state.blockCount += 1;
        this.showBlock(message);
        this.updateInstanceBlockCount();
      }, delay);
      this.state.startTimeouts.push(startTimeout);
    }
  }
  onStop({
    name
  }) {
    if (name === this.name) {
      const stopCount = this.state.stopCallCount + 1;
      if (this.state.startCallCount - stopCount >= 0) {
        const delay = this.delayStop ?? this.settings.delayStop ?? 0;
        this.state.stopCallCount = stopCount;
        const stopTimeout = setTimeout(() => {
          this.state.blockCount -= 1;
          this.updateInstanceBlockCount();
          this.detectChanges();
        }, delay);
        this.state.stopTimeouts.push(stopTimeout);
      }
    }
  }
  onUpdate({
    name,
    message
  }) {
    if (name === this.name) {
      const delay = this.delayStart || this.settings.delayStart || 0;
      clearTimeout(this.state.updateTimeouts[0]);
      const updateTimeout = setTimeout(() => {
        this.updateMessage(message);
      }, delay);
      this.state.updateTimeouts.push(updateTimeout);
    }
  }
  onReset({
    name
  }) {
    if (name === this.name) {
      this.resetState();
    }
  }
  updateMessage(message) {
    this.showBlock(message);
  }
  showBlock(message) {
    this.message = message || this.defaultMessage || this.settings.message;
    this.updateBlockTemplate(this.message);
    this.detectChanges();
  }
  updateBlockTemplate(msg) {
    if (this.templateCompRef && this.templateCompRef instanceof ComponentRef) {
      this.templateCompRef.instance.message = msg;
    }
  }
  resetState() {
    [...this.state.startTimeouts, ...this.state.stopTimeouts, ...this.state.updateTimeouts].forEach(clearTimeout);
    this.state = {
      ...this.defaultBlockState
    };
    this.updateInstanceBlockCount();
    this.detectChanges();
  }
  onUnsubscribe(name) {
    if (this.blockUISubscription && name === this.name) {
      this.blockUISubscription.unsubscribe();
    }
  }
  updateInstanceBlockCount() {
    if (this.blockUI.blockUIInstances[this.name]) {
      const {
        blockCount
      } = this.state;
      this.blockUI.blockUIInstances[this.name].blockCount = blockCount;
    }
  }
  detectChanges() {
    if (!this.changeDetectionRef['destroyed']) {
      this.changeDetectionRef.detectChanges();
    }
  }
  ngOnDestroy() {
    this.resetState();
    this.onUnsubscribe(this.name);
    this.blockUI.clearInstance(this.name);
  }
}
BlockUIContentComponent.ɵfac = function BlockUIContentComponent_Factory(t) {
  return new (t || BlockUIContentComponent)(i0.ɵɵdirectiveInject(BlockUIInstanceService), i0.ɵɵdirectiveInject(i0.ComponentFactoryResolver), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
BlockUIContentComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BlockUIContentComponent,
  selectors: [["block-ui-content"]],
  viewQuery: function BlockUIContentComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5, ViewContainerRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templateOutlet = _t.first);
    }
  },
  inputs: {
    name: "name",
    delayStart: "delayStart",
    delayStop: "delayStop",
    defaultMessage: [i0.ɵɵInputFlags.None, "message", "defaultMessage"],
    templateCmp: [i0.ɵɵInputFlags.None, "template", "templateCmp"]
  },
  decls: 3,
  vars: 9,
  consts: [["templateOutlet", ""], [3, "ngClass"], ["class", "block-ui-spinner", 4, "ngIf"], [4, "ngIf"], [1, "block-ui-spinner"], [1, "loader"], ["class", "message", 4, "ngIf"], [1, "message"]],
  template: function BlockUIContentComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 1);
      i0.ɵɵtemplate(1, BlockUIContentComponent_div_1_Template, 3, 1, "div", 2)(2, BlockUIContentComponent_2_Template, 2, 0, null, 3);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵclassMapInterpolate2("block-ui-wrapper ", ctx.name, " ", ctx.className, "");
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(7, _c1, ctx.state.blockCount > 0));
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !ctx.templateCmp);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.templateCmp);
    }
  },
  dependencies: [i2.NgClass, i2.NgIf],
  styles: [".block-ui-wrapper{display:none;position:fixed;height:100%;width:100%;top:0;left:0;background:rgba(0,0,0,.7);z-index:30000;cursor:wait}.block-ui-wrapper.block-ui-wrapper--element{position:absolute}.block-ui-wrapper.active{display:block}.block-ui-wrapper.block-ui-main{position:fixed}.block-ui-spinner,.block-ui-template{position:absolute;top:40%;margin:0 auto;left:0;right:0;transform:translateY(-50%)}.block-ui-spinner>.message{font-size:1.3em;text-align:center;color:#fff}.block-ui__element{position:relative}.loader,.loader:after{border-radius:50%;width:10em;height:10em}.loader{margin:7px auto;font-size:5px;position:relative;text-indent:-9999em;border-top:1.1em solid rgba(255,255,255,.2);border-right:1.1em solid rgba(255,255,255,.2);border-bottom:1.1em solid rgba(255,255,255,.2);border-left:1.1em solid #ffffff;transform:translateZ(0);animation:load8 1.1s infinite linear}@keyframes load8{0%{transform:rotate(0)}to{transform:rotate(360deg)}}\n"],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockUIContentComponent, [{
    type: Component,
    args: [{
      selector: 'block-ui-content',
      template: template,
      encapsulation: ViewEncapsulation.None,
      styles: [".block-ui-wrapper{display:none;position:fixed;height:100%;width:100%;top:0;left:0;background:rgba(0,0,0,.7);z-index:30000;cursor:wait}.block-ui-wrapper.block-ui-wrapper--element{position:absolute}.block-ui-wrapper.active{display:block}.block-ui-wrapper.block-ui-main{position:fixed}.block-ui-spinner,.block-ui-template{position:absolute;top:40%;margin:0 auto;left:0;right:0;transform:translateY(-50%)}.block-ui-spinner>.message{font-size:1.3em;text-align:center;color:#fff}.block-ui__element{position:relative}.loader,.loader:after{border-radius:50%;width:10em;height:10em}.loader{margin:7px auto;font-size:5px;position:relative;text-indent:-9999em;border-top:1.1em solid rgba(255,255,255,.2);border-right:1.1em solid rgba(255,255,255,.2);border-bottom:1.1em solid rgba(255,255,255,.2);border-left:1.1em solid #ffffff;transform:translateZ(0);animation:load8 1.1s infinite linear}@keyframes load8{0%{transform:rotate(0)}to{transform:rotate(360deg)}}\n"]
    }]
  }], function () {
    return [{
      type: BlockUIInstanceService
    }, {
      type: i0.ComponentFactoryResolver
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    name: [{
      type: Input
    }],
    delayStart: [{
      type: Input
    }],
    delayStop: [{
      type: Input
    }],
    defaultMessage: [{
      type: Input,
      args: ['message']
    }],
    templateCmp: [{
      type: Input,
      args: ['template']
    }],
    templateOutlet: [{
      type: ViewChild,
      args: ['templateOutlet', {
        read: ViewContainerRef
      }]
    }]
  });
})();
class BlockUIComponent {
  constructor(blockUI) {
    this.blockUI = blockUI;
  }
  ngOnInit() {
    this.name = this.name || BlockUIDefaultName;
    this.template = this.template || this.blockUI.blockUISettings.template;
  }
}
BlockUIComponent.ɵfac = function BlockUIComponent_Factory(t) {
  return new (t || BlockUIComponent)(i0.ɵɵdirectiveInject(BlockUIInstanceService));
};
BlockUIComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: BlockUIComponent,
  selectors: [["block-ui"]],
  inputs: {
    name: "name",
    message: "message",
    delayStart: "delayStart",
    delayStop: "delayStop",
    template: "template"
  },
  ngContentSelectors: _c2,
  decls: 2,
  vars: 5,
  consts: [[3, "name", "message", "template", "delayStart", "delayStop"]],
  template: function BlockUIComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
      i0.ɵɵelement(1, "block-ui-content", 0);
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("name", ctx.name)("message", ctx.message)("template", ctx.template)("delayStart", ctx.delayStart)("delayStop", ctx.delayStop);
    }
  },
  dependencies: [BlockUIContentComponent],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockUIComponent, [{
    type: Component,
    args: [{
      selector: 'block-ui',
      template: `
    <ng-content></ng-content>
    <block-ui-content
      [name]="name"
      [message]="message"
      [template]="template"
      [delayStart]="delayStart"
      [delayStop]="delayStop"
    >
    </block-ui-content>
  `,
      encapsulation: ViewEncapsulation.None
    }]
  }], function () {
    return [{
      type: BlockUIInstanceService
    }];
  }, {
    name: [{
      type: Input
    }],
    message: [{
      type: Input
    }],
    delayStart: [{
      type: Input
    }],
    delayStop: [{
      type: Input
    }],
    template: [{
      type: Input
    }]
  });
})();
class BlockUIService {
  constructor(blockUIInstance) {
    this.blockUIInstance = blockUIInstance;
    this.globalDispatch = this.blockUIInstance.decorate();
  }
  /**
  * Starts blocking for given BlockUI instance or instances
  */
  start(target, message) {
    this.dispatch(target, BlockUIActions.START, message);
  }
  /**
  * Stops blocking for given BlockUI instance or instances
  */
  stop(target) {
    this.dispatch(target, BlockUIActions.STOP);
  }
  /**
  * Reset blocking for given BlockUI instance or instances
  */
  reset(target) {
    this.dispatch(target, BlockUIActions.RESET);
  }
  /**
  * Reset blocking for all BlockUI instances
  */
  resetGlobal() {
    this.globalDispatch.resetGlobal();
  }
  /**
  * Updates message for given BlockUI instance or instances
  */
  update(target, message) {
    this.dispatch(target, BlockUIActions.UPDATE, message);
  }
  /**
  * Unsubscribes for given BlockUI instance or instances
  */
  unsubscribe(target) {
    this.dispatch(target, BlockUIActions.UNSUBSCRIBE);
  }
  /**
  * Checks if BlockUI is actively blocking
  */
  isActive(target = null) {
    const targets = target ? this.toArray(target) : null;
    const instances = this.blockUIInstance.blockUIInstances;
    return Object.keys(instances).some(key => {
      if (!targets) {
        return instances[key].isActive;
      }
      return targets.indexOf(instances[key].name) >= 0 && instances[key].isActive;
    });
  }
  dispatch(target = [], type, message) {
    const instances = this.toArray(target);
    instances.forEach(i => this.blockUIInstance.decorate(i)[type](message));
  }
  toArray(target = []) {
    return typeof target === 'string' ? [target] : target;
  }
}
BlockUIService.ɵfac = function BlockUIService_Factory(t) {
  return new (t || BlockUIService)(i0.ɵɵinject(BlockUIInstanceService));
};
BlockUIService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: BlockUIService,
  factory: BlockUIService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockUIService, [{
    type: Injectable
  }], function () {
    return [{
      type: BlockUIInstanceService
    }];
  }, null);
})();
class BlockUIDirective {
  constructor(blockUIService, blockUIInstanceService, viewRef, templateRef, renderer, componentFactoryResolver) {
    this.blockUIService = blockUIService;
    this.blockUIInstanceService = blockUIInstanceService;
    this.viewRef = viewRef;
    this.templateRef = templateRef;
    this.renderer = renderer;
    this.componentFactoryResolver = componentFactoryResolver;
  }
  set blockUI(name) {
    this.blockTarget = name;
  }
  set blockUIMessage(message) {
    this.message = message;
  }
  set blockUITemplate(template) {
    this.template = template;
  }
  set blockUIDelayStart(delayStart) {
    this.delayStart = delayStart ? Number(delayStart) : null;
  }
  set blockUIDelayStop(delayStop) {
    this.delayStop = delayStop ? Number(delayStop) : null;
  }
  ngOnInit() {
    try {
      this.viewRef.createEmbeddedView(this.templateRef);
      const parentElement = this.getParentElement();
      if (parentElement && !this.isComponentInTemplate(parentElement)) {
        this.renderer.addClass(parentElement, 'block-ui__element');
        this.blockUIComponentRef = this.createComponent();
        let blockUIContent = this.findContentNode(this.viewRef.element.nativeElement);
        if (blockUIContent) {
          const settings = this.blockUIInstanceService.getSettings();
          parentElement.appendChild(blockUIContent);
          this.blockUIComponentRef.instance.className = 'block-ui-wrapper--element';
          this.blockUIComponentRef.instance.name = this.blockTarget || BlockUIDefaultName;
          if (this.message) this.blockUIComponentRef.instance.defaultMessage = this.message;
          if (this.delayStart) this.blockUIComponentRef.instance.delayStart = this.delayStart;
          if (this.delayStop) this.blockUIComponentRef.instance.delayStop = this.delayStop;
          if (this.template || settings.template) this.blockUIComponentRef.instance.templateCmp = this.template || settings.template;
        }
      }
    } catch (error) {
      console.error('ng-block-ui:', error);
    }
  }
  isComponentInTemplate(element) {
    // Needed because of https://github.com/microsoft/TypeScript/issues/26235
    const targetElement = element || {};
    let {
      children
    } = targetElement;
    children = Array.from(children || []).reverse();
    return children.some(el => el && el.localName === 'block-ui');
  }
  getParentElement() {
    const embeddedView = this.viewRef.get(0);
    return embeddedView.rootNodes[0];
  }
  // Needed for IE (#17)
  findContentNode(element) {
    const nextSibling = element.nextSibling || {};
    const previousSibling = element.previousSibling || {};
    return [nextSibling, nextSibling.nextSibling, previousSibling, previousSibling.previousSibling].find(e => e && e.localName === 'block-ui-content');
  }
  createComponent() {
    const resolvedBlockUIComponent = this.componentFactoryResolver.resolveComponentFactory(BlockUIContentComponent);
    return this.viewRef.createComponent(resolvedBlockUIComponent);
  }
  ngOnDestroy() {
    if (this.blockTarget) {
      this.blockUIService.reset(this.blockTarget);
    }
  }
}
BlockUIDirective.ɵfac = function BlockUIDirective_Factory(t) {
  return new (t || BlockUIDirective)(i0.ɵɵdirectiveInject(BlockUIService), i0.ɵɵdirectiveInject(BlockUIInstanceService), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ComponentFactoryResolver));
};
BlockUIDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BlockUIDirective,
  selectors: [["", "blockUI", ""]],
  inputs: {
    blockUI: "blockUI",
    blockUIMessage: "blockUIMessage",
    blockUITemplate: "blockUITemplate",
    blockUIDelayStart: "blockUIDelayStart",
    blockUIDelayStop: "blockUIDelayStop"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockUIDirective, [{
    type: Directive,
    args: [{
      selector: '[blockUI]'
    }]
  }], function () {
    return [{
      type: BlockUIService
    }, {
      type: BlockUIInstanceService
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.TemplateRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ComponentFactoryResolver
    }];
  }, {
    blockUI: [{
      type: Input
    }],
    blockUIMessage: [{
      type: Input
    }],
    blockUITemplate: [{
      type: Input
    }],
    blockUIDelayStart: [{
      type: Input
    }],
    blockUIDelayStop: [{
      type: Input
    }]
  });
})();
const BlockUIServiceInstance = new BlockUIInstanceService();
// Needed for AOT compiling
const BlockUIModuleSettings = new InjectionToken('BlockUIModuleSettings');
function provideInstance(settings) {
  BlockUIServiceInstance.updateSettings(settings);
  return BlockUIServiceInstance;
}
class BlockUIModule {
  static forRoot(settings = {}) {
    return {
      ngModule: BlockUIModule,
      providers: [{
        provide: BlockUIModuleSettings,
        useValue: settings
      }, {
        provide: BlockUIInstanceService,
        useFactory: provideInstance,
        deps: [BlockUIModuleSettings]
      }, BlockUIService]
    };
  }
}
BlockUIModule.ɵfac = function BlockUIModule_Factory(t) {
  return new (t || BlockUIModule)();
};
BlockUIModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: BlockUIModule
});
BlockUIModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BlockUIModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      entryComponents: [BlockUIComponent, BlockUIContentComponent],
      declarations: [BlockUIComponent, BlockUIDirective, BlockUIContentComponent],
      exports: [BlockUIComponent, BlockUIDirective, BlockUIContentComponent]
    }]
  }], null, null);
})();
let blockInstanceGuid = 1;
function BlockUI(blockName, settings = {}) {
  if (!settings.scopeToInstance) {
    return function (target, propertyKey) {
      target[propertyKey] = BlockUIServiceInstance.decorate(blockName);
    };
  }
  return function (target, key) {
    const secret = `_${key}-block-ui`;
    Object.defineProperty(target, key, {
      get: function () {
        if (this[secret]) {
          return this[secret];
        }
        const instanceName = `${blockName}-${blockInstanceGuid++}`;
        this[secret] = BlockUIServiceInstance.decorate(instanceName);
        return this[secret];
      },
      set: function (value) {
        this[secret] = value;
      }
    });
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { BlockUIDefaultName as BLOCKUI_DEFAULT, BlockUI, BlockUIComponent, BlockUIContentComponent, BlockUIDirective, BlockUIModule, BlockUIService };

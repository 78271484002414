export const enum DeviceType {
  Devices = 'devices',
  Ngc40htc = 'ngc40htc',
  Ngc40htcs = 'ngc40htcs',
  Ngc20 = 'ngc20',
  Ngc20s = 'ngc20s',
  Ngc40htc3 = 'ngc40htc3',
  Ngc40htc3s = 'ngc40htc3s',
  Ngc40io = 'ngc40io',
  Ngc40ios = 'ngc40ios',
  Ngc40slim = 'ngc40slim',
  Ngc40slims = 'ngc40slims',
  Ngc40bridge = 'ngc40bridge',
  Ngc40bridges = 'ngc40bridges',
  Htc910s = 'htc910s',
  Htc920s = 'htc920s',
  Htc910 = 'htc910',
  Htc920 = 'htc920',
  Cm2000s = 'cm2000s',
  Cm2000 = 'cm2000',
  Cm2000ps = 'cm2000ps',
  Cm2000p = 'cm2000p',
  Ac2000p = 'ac2000p',
  Ac2000ps = 'ac2000ps',
  Elexant40X0 = 'elexant40x0',
  Elexant40X0s = 'elexant40x0s',
  Elexant40X0i = '4010i/4020i',
  Elexant3500i = 'elexant3500i',
  Elexant5010i = 'elexant5010i',
  Elexant5010is = 'elexant5010is',
  Htc3 = 'htc3',
  Htc = 'htc',
  Io = 'io',
  Slim = 'slim',
}

export enum DeviceTypeTrends {
  Ngc40htc = 'ngc40htc',
  Ngc20 = 'ngc20',
  Ngc40htc3 = 'ngc40htc3',
  Ngc40io = 'ngc40io',
  Ngc40slim = 'ngc40slim',
  Htc910 = 'htc910',
  Htc920 = 'htc920',
  Cm2000 = 'cm2000',
  Cm2000p = 'cm2000p',
  Elexant40X0 = '4010i/4020i',
  Elexant3500i = 'elexant3500i',
  Elexant5010i = 'elexant5010i',
}

export const enum DeviceTag {
  Cm2000 = 'cm2000-1',
  Cm2000p = 'cm2000p-1',
}

export const enum DeviceLatestStateType {
  Ngc40htc = 'ngc40htc_latest_states',
  Ngc20 = 'ngc20_latest_states',
  Ngc40htc3 = 'ngc40htc3_latest_states',
  Ngc40io = 'ngc40io_latest_states',
  Ngc40slim = 'ngc40slim_latest_states',
  Htc910 = 'htc910_latest_states',
  Htc920 = 'htc920_latest_states',
  Cm2000 = 'cm2000_latest_states',
  Cm2000p = 'cm2000p_latest_states',
  Elexant40X0 = 'elexant40x0_latest_states',
  Elexant3500i = 'elexant3500i_latest_states',
  Elexant5010i = 'elexant5010i_latest_states',
}

export const enum DeviceActualConfigType {
  Ngc20 = 'ngc20_actual_configs',
  Ngc40htc = 'ngc40htc_actual_configs',
  Ngc40htc3 = 'ngc40htc3_actual_configs',
  Ngc40io = 'ngc40io_actual_configs',
  Ngc40slim = 'ngc40slim_actual_configs',
  Htc910 = 'htc910_actual_configs',
  Htc920 = 'htc920_actual_configs',
  Cm2000 = 'cm2000_actual_configs',
  Cm2000p = 'cm2000p_actual_configs',
  Ngc40bridge = 'ngc40bridge_actual_configs',
  Ac2000p = 'ac2000p_actual_configs',
  Elexant40X0 = 'elexant40x0_actual_configs',
  Elexant3500i = 'elexant3500i_actual_configs',
  Elexant5010i = 'elexant5010i_actual_configs',
}

export const enum DeviceInfoType {
  Ngc40slim = 'ngc40slim_infos',
  Ngc40htc3 = 'ngc40htc3_infos',
}

export enum GroupTypes {
  Model = 'Model',
  Parent = 'Parent',
  Priority = 'Priority',
  OnlineState = 'Online State',
  None = 'None',
  AlarmType = 'Alarm Type',
  AlarmState = 'Alarm State',
  AlarmPriority = 'Alarm Priority',
}

export const GroupKeys: { [key in GroupTypes]: string } = {
  [GroupTypes.Model]: 'deviceType',
  [GroupTypes.Parent]: 'bridgeName',
  [GroupTypes.Priority]: 'devicePriority',
  [GroupTypes.OnlineState]: 'onlineState',
  [GroupTypes.None]: 'none',
  [GroupTypes.AlarmType]: 'alarmType',
  [GroupTypes.AlarmState]: 'alarmState',
  [GroupTypes.AlarmPriority]: 'alarmPriority',
};

export const enum DeviceAttributeNames {
  DeviceTag = 'deviceTag',
  Type = 'deviceType',
  SetPoint = 'setPoint',
  ControlTemp = 'controlTemperature',
  LineCurrent = 'lineCurrent',
  GroundFault = 'groundFaultCurrent',
  Voltage = 'voltage',
  Power = 'power',
  OutputState = 'outputState',
  Temp1 = 'sensorTemperature1',
  Temp2 = 'sensorTemperature2',
  Temp3 = 'sensorTemperature3',
  Temp4 = 'sensorTemperature4',
  FirmwareVersion = 'firmwareVersion',
  ModbusAddress = 'modbusAddress',
  DeviceConnectionType = 'deviceConnectionType',
  DeviceModel = 'deviceModel',
  DeviceVariant = 'deviceVariant',
  ArticleName = 'articleName',
  SerialNumber = 'serialNumber',
  BridgeName = 'bridgeName',
  AlarmCount = 'alarmsCount',
}

export const enum GroupNames {
  AllDevices = 'All Devices',
  Online = 'Online',
  Offline = 'Offline',
  DevicesWithNoAlarms = 'Devices With No Alarms',
}

export const enum ActualConfigUpdateStatus {
  Ok = 'ok',
  Obsolete = 'obsolete',
  Nop = 'Nop',
  Expired = 'expired',
  Incomplete = 'incomplete',
  Merged = 'merged',
  Invalid = 'invalid',
  IoFailed = 'io_failed',
  ReadOnly = 'read_only',
}

export const enum DeviceTabs {
  Details = 'Details',
  Alarms = 'Alarms',
  Configure = 'Configure',
  Trend = 'Trend',
  MaintenanceInfo = 'Maintenance Info',
  HistoryLog = 'History Log',
}

export const enum DeviceTabIndexes {
  Details = 0,
  Alarms,
  Configure,
  Trend,
  MaintenanceInfo,
  HistoryLog,
}

export const enum DeviceStateType {
  Ngc40htc = 'ngc40htc_states',
  Ngc40htc3 = 'ngc40htc3_states',
  Ngc40io = 'ngc40io_states',
  Ngc40slim = 'ngc40slim_states',
  Htc910 = 'htc910_states',
  Htc920 = 'htc920_states',
  Cm2000 = 'cm2000_states',
  Cm2000p = 'cm2000p_states',
  Elexant40X0 = 'elexant40x0_states',
  Elexant3500i = 'elexant3500i_states',
  Ngc20 = 'ngc20_states',
  Elexant5010i = 'elexant5010i_states',
}

export const enum DeviceStatus {
  OnlineState = 'onlineState',
  OfflineState = 'offlineState',
}

export const enum DeviceState {
  Online = 'Online',
  Offline = 'Offline',
}

import { Component, DestroyRef, Inject, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { UserService } from '@app/services/jsonapi-services/user.service';
import { KeycloakService } from '@app/services/keycloak.service';
import { UserMapperService } from '@app/services/mapper-services/user-mapper.service';
import { environment } from '@env/environment';
import { AuthService } from '@services/auth.service';
import { userParam } from '@utils/constants/params';
import { mergeMap } from 'rxjs';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css'],
})
export class TermsComponent {
  authId!: string;
  userId!: string;
  idProviderId!: string;
  destroyRef = inject(DestroyRef);

  constructor(
    @Inject(Router) private router: Router,
    private userService: UserService,
    private userMapperService: UserMapperService,
    private auth: AuthService,
    private keycloakService: KeycloakService,
  ) {}

  redirectToLogin() {
    this.keycloakService.signOut();
  }

  onAccept() {
    const token = environment.e2e ? this.auth.parseIdToken() : this.keycloakService.getToken();
    this.userService
      .getMulti(userParam(token))
      .pipe(
        mergeMap(userData => {
          this.userId = userData.data[0].id!;
          let userDataToBePosted = this.userMapperService.mapToBackEnd({
            eula_accepted: true,
          });
          userDataToBePosted = { ...userDataToBePosted, id: this.userId };
          return this.userService.update(userDataToBePosted);
        }),
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        void this.router.navigateByUrl('/dashboard');
      });
  }
}

export const trailingZeroRegExPattern: RegExp = /^(-?\d+\.*\d*?)(0*)(Δ°C|°C|°F|nA|nW|nV|mA|V|W|A|Ω|%|s|m|h)$/;
export const usageRegExPattern = /^TS\d+Usage$/;
export const temp_max_regex: RegExp = /temperature_(\d+)_maximum$/;
export const temp_min_regex: RegExp = /temperature_(\d+)_minimum$/;
export const energy_consumption_regex: RegExp = /htc_0_energy_consumption_(\d+)$/;
export const trace_current_regex: RegExp = /htc_0_trace_current_(\d+)_highest_instantaneous$/;
export const line_current_regex: RegExp = /htc_0_line_current_(\d+)_highest_instantaneous$/;
export const units_pattern: RegExp = /(Δ°C|°C|°F|nA|nW|nV|mA|V|W|A|Ω|h|Hz|s|m|%|kWh|ms)$/;
export const space_check_pattern = /^ *$/;
export const remove_trailing_space = /\s+$/;
export const remove_leading_space = /^\s+/g;
export const ipv4_pattern: RegExp =
  /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const digit_followed_by_I_regex = /(\d)I(?!\w)/g;
export const host_path_regex: RegExp =
  /(modbus\-tcp|modbus\-rtu|modbus\-ascii):\/\/(\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}(?!.*\/\/)/;

import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { NotesUIModel } from '@app/models/notes';
import { Event, EventService } from '@app/services/event.service';
import { DeviceMiddlewareService } from '@app/services/jsonapi-services/device-middleware.service';
import { NoteService } from '@app/services/jsonapi-services/notes.service';
import { NotesMapperService } from '@app/services/mapper-services/notes-mapper.service';
import { UserUtilService } from '@app/services/user-util.service';
import { Events } from '@app/utils/enums/event-enum';
import { remove_trailing_space, space_check_pattern } from '@app/utils/patterns';
import { ExportCsvService } from '@services/export-csv.service';
import { defaultWriteOptions, getJsonToSheetOptions } from '@utils/constants/export-constants';
import { NotesHeaderNames, NotesHeaderProperties } from '@utils/enums/export-enums';
import { utils } from 'xlsx';

@Component({
  selector: 'app-device-notes',
  templateUrl: './device-notes.component.html',
  styleUrl: './device-notes.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class DeviceNotesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() deviceId!: string;
  @Input() deviceTag!: string;
  @Output() closeEvent = new EventEmitter<void>();
  @ViewChildren('chatContent') private chatContent!: QueryList<any>;
  @ViewChild('chatContainer') private chatContainer!: ElementRef;
  parentNote: NotesUIModel | undefined;
  updatedAllNotesData!: NotesUIModel[];
  notesToDisplay: NotesUIModel[] = [];
  notesControl = new FormControl('');
  spaceCheckPattern = space_check_pattern;
  isLoading = true;
  blockUIMessage = 'Loading notes...';
  noteSearchControl = new FormControl('');
  showSearch: boolean = false;
  searchString: string | null = null;
  initialized!: Promise<void>;
  destroyRef = inject(DestroyRef);
  private userId!: string;
  private newNotesEventId = 0;
  private csvColumnHeaders: any[] = [
    { headerName: NotesHeaderNames.DeviceTag, property: NotesHeaderProperties.DeviceTag },
    { headerName: NotesHeaderNames.User, property: NotesHeaderProperties.User },
    { headerName: NotesHeaderNames.DateTimeStamp, property: NotesHeaderProperties.DateTimeStamp },
    { headerName: NotesHeaderNames.Note, property: NotesHeaderProperties.Note },
    { headerName: NotesHeaderNames.Comments, property: NotesHeaderProperties.Comments },
  ];

  constructor(
    private userUtilsService: UserUtilService,
    private deviceMiddlewareService: DeviceMiddlewareService,
    private notesMapperService: NotesMapperService,
    private notesService: NoteService,
    private exportService: ExportCsvService,
    private event: EventService,
  ) {}

  ngOnInit(): void {
    this.getDeviceNotes();
    this.bindEvents();
    this.searchNote();
  }

  ngAfterViewInit() {
    this.scrollToBottom();
    this.chatContent.changes.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.scrollToBottom();
    });
  }

  toggleSearchBar() {
    this.showSearch = !this.showSearch;
    this.noteSearchControl.setValue('');
  }

  searchNote() {
    this.noteSearchControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(data => {
      this.searchString = data!.toLowerCase().trim();
      this.parentNote ? this.getComments(this.parentNote) : this.getParentNotes();
    });
  }

  scrollToBottom() {
    this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
  }

  onClose() {
    this.closeEvent.emit();
  }

  getDeviceNotes() {
    this.isLoading = true;
    this.updatedAllNotesData = [];
    this.deviceMiddlewareService
      .getNotesFromJsonApi(this.deviceId!)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: notes => {
          this.updatedAllNotesData = notes;
          this.getParentNotes();
          this.isLoading = false;
        },
        error: err => {
          console.error('Failed to fetch notes for ', this.deviceTag, '\nError: ', err);
          this.isLoading = false;
        },
      });
  }

  getParentNotes() {
    this.parentNote = undefined;
    this.notesToDisplay = [];
    this.notesToDisplay = this.updatedAllNotesData.filter(data => {
      return !data.parentId && (this.searchString ? data.text.toLowerCase().includes(this.searchString) : true);
    });
  }

  getComments(note: NotesUIModel) {
    this.parentNote = note;
    this.notesToDisplay = [];
    this.notesToDisplay = this.updatedAllNotesData.filter(data => {
      return (
        data.parentId === this.parentNote?.noteId &&
        (this.searchString ? data.text.toLowerCase().includes(this.searchString) : true)
      );
    });
  }

  postNotesToJsonApi() {
    const notesText = this.notesControl.value?.replace(remove_trailing_space, '');
    if (!this.userId)
      this.userUtilsService
        .getUserId()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(id => {
          this.userId = id;
        });
    const mappedNotesData = this.notesMapperService.mapNotesDataToBackend(
      notesText!,
      this.deviceId!,
      this.userId,
      this.parentNote?.noteId,
    );
    if (notesText) {
      this.notesService
        .addWithoutId(mappedNotesData)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          error: err => {
            console.error('Failed to add notes for ', this.deviceTag, '\nError: ', err);
          },
        });
    }
    this.notesControl.setValue('');
  }

  onNewNotesUpdate(notesUpdate: Event) {
    if (notesUpdate.data.relationships.device?.data.id === this.deviceId) {
      const parentId = notesUpdate.data.relationships.parent_note?.data?.id;
      const mappedNote = this.notesMapperService.mapNotesEventData(notesUpdate);
      this.updatedAllNotesData.push(mappedNote);

      if (
        ((this.parentNote && this.parentNote.noteId === parentId) ||
          (!this.parentNote && !notesUpdate.data.relationships?.parent_note.data)) &&
        (!this.searchString || String(notesUpdate.data.attributes?.text).toLowerCase().includes(this.searchString))
      )
        this.notesToDisplay.push(mappedNote);

      if (parentId)
        for (const note of this.updatedAllNotesData)
          if (note.noteId === parentId) {
            note.commentCount = note.commentCount! + 1;
            break;
          }
    }
  }

  exportNotes() {
    const exportData: { [key: string]: string }[] = [];
    let mappedData: { [key: string]: any };
    const parentNotes = this.updatedAllNotesData.filter(data => {
      return !data.parentId;
    });
    parentNotes.forEach(note => {
      mappedData = {};
      this.csvColumnHeaders.forEach(entity => {
        mappedData[entity.headerName] =
          entity.property == NotesHeaderProperties.DeviceTag
            ? this.deviceTag
            : entity.headerName == NotesHeaderNames.Comments
              ? ''
              : note[entity.property as keyof NotesUIModel];
      });
      exportData.push(mappedData);
      if (note.commentCount) {
        const childNotes = this.updatedAllNotesData.filter(data => {
          return data.parentId === note.noteId;
        });
        childNotes.forEach(child => {
          mappedData = {};
          this.csvColumnHeaders.forEach(entity => {
            mappedData[entity.headerName] =
              entity.property == NotesHeaderProperties.DeviceTag
                ? this.deviceTag
                : entity.headerName == NotesHeaderNames.Note
                  ? note.text
                  : child[entity.property as keyof NotesUIModel];
          });
          exportData.push(mappedData);
        });
      }
    });
    this.exportService.writeWorkSheetToFile(
      utils.json_to_sheet(exportData, getJsonToSheetOptions(this.csvColumnHeaders.map(entity => entity.headerName))),
      `${this.deviceTag}-notes.csv`,
      defaultWriteOptions,
    );
  }

  private bindEvents(): void {
    this.initialized = new Promise<void>((resolve, _reject) => {
      this.newNotesEventId = this.event.register(Events.Notes, this.onNewNotesUpdate.bind(this));
      resolve();
    });
  }

  ngOnDestroy(): void {
    if (this.newNotesEventId !== 0) this.event.deregister(Events.Notes, this.newNotesEventId);
  }
}

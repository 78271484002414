import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DestroyRef, EventEmitter, Input, OnInit, Output, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { UserUtilService } from '@app/services/user-util.service';
import { STORAGE_KEYS } from '@app/utils/constants/local-storage-constants';
import { dropEvent } from '@models/customize-columns';
import { AttributesInfo } from '@models/table-items';
import { LocalStorageService } from '@services/local-storage.service';
import { customizeColumnDetails } from '@utils/constants/customize-columns';

@Component({
  selector: 'app-customize-columns',
  templateUrl: './customize-columns.component.html',
  styleUrl: './customize-columns.component.css',
})
export class CustomizeColumnsComponent implements OnInit {
  @Input({ required: true }) columns!: AttributesInfo[];
  @Input({ required: true }) tableName!: string;
  @Output() onColumnsChange: EventEmitter<AttributesInfo[]> = new EventEmitter<AttributesInfo[]>();

  destroyRef = inject(DestroyRef);
  showOverlay = false;
  iconName = 'view_column';
  showSearch = false;
  searchControl = new FormControl('');
  hiddenColumns: AttributesInfo[] = [];
  filteredHiddenColumns = signal<AttributesInfo[]>([]);
  visibleColumns = signal<AttributesInfo[]>([]);
  initialVisibleColumns: AttributesInfo[] = [];
  initialHiddenColumns: AttributesInfo[] = [];
  visibleColumnFieldsStorageKey: string = '';
  allColumnFieldsStorageKey: string = '';
  hiddenColumnGroups = computed<{ [key: string]: AttributesInfo[] }>(() => this.getHiddenColumnGroups());
  lockedColumns = computed<AttributesInfo[]>(() => this.visibleColumns().filter(column => column.isStickyStart));
  draggableColumns = computed<AttributesInfo[]>(() => this.visibleColumns().filter(column => !column.isStickyStart));

  constructor(
    private localStorageService: LocalStorageService,
    private userUtilService: UserUtilService,
  ) {}

  ngOnInit() {
    this.userUtilService
      .getUserId()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((userId: string) => {
        this.visibleColumnFieldsStorageKey = STORAGE_KEYS.visibleColumnFields(this.tableName, userId);
        this.allColumnFieldsStorageKey = STORAGE_KEYS.allColumnFields(this.tableName, userId);
      });
    this.columns = this.columns.filter(column => column.field !== customizeColumnDetails.field);
    this.applyColumnState();
    this.initSearch();
  }

  applyColumnState() {
    let allColumnFields: string[] = this.localStorageService.getItem(this.allColumnFieldsStorageKey);
    let visibleColumnFields: string[] = this.localStorageService.getItem(this.visibleColumnFieldsStorageKey);

    if (allColumnFields && visibleColumnFields) {
      const fieldsAdded = this.getFieldsAdded(allColumnFields, this.columns);
      const fieldsRemoved = this.getFieldsRemoved(allColumnFields, this.columns);

      fieldsAdded.forEach(field => {
        const column = this.columns.find(column => column.field === field);
        allColumnFields.push(field);
        if (column?.isDisplayedByDefault) {
          const index = this.columns.findIndex(column => column.field === field);
          visibleColumnFields[index] ? visibleColumnFields.splice(index, 0, field) : visibleColumnFields.push(field);
        }
      });

      fieldsRemoved.forEach(field => {
        allColumnFields = allColumnFields.filter(colunmnField => colunmnField !== field);
        visibleColumnFields = visibleColumnFields.filter(columnField => columnField !== field);
      });

      this.localStorageService.setItem(this.allColumnFieldsStorageKey, allColumnFields);
      this.localStorageService.setItem(this.visibleColumnFieldsStorageKey, visibleColumnFields);
    }

    this.setHiddenColumns(visibleColumnFields);
    this.setVisibleColumns(visibleColumnFields);
    this.sendUpdatedColumns();
  }

  getFieldsAdded(prevColumnFields: string[], newColumns: AttributesInfo[]): string[] {
    const newColumnFields = newColumns.map(column => column.field);
    return newColumnFields.filter(field => !prevColumnFields.includes(field));
  }

  getFieldsRemoved(prevColumnFields: string[], newColumns: AttributesInfo[]): string[] {
    const newColumnFields = newColumns.map(column => column.field);
    return prevColumnFields.filter(field => !newColumnFields.includes(field));
  }

  getHiddenColumnGroups() {
    const groupNames = new Set<string>();
    const OTHER_COLUMNS = 'Other columns';
    this.filteredHiddenColumns().forEach(column => groupNames.add(column.group ?? OTHER_COLUMNS));
    const groups: { [key: string]: AttributesInfo[] } = {};
    groupNames.forEach(groupName => {
      const groupColumns =
        groupName === OTHER_COLUMNS
          ? this.filteredHiddenColumns().filter(column => !column.group)
          : this.filteredHiddenColumns().filter(column => column.group === groupName);
      groupColumns.sort((column1, column2) => column1.header.localeCompare(column2.header));
      groups[groupName] = groupColumns;
    });
    return groups;
  }

  setHiddenColumns(visibleColumnFields: string[] | null) {
    this.hiddenColumns = visibleColumnFields
      ? this.columns.filter(column => !visibleColumnFields.includes(column.field))
      : this.columns.filter(column => !column.isDisplayedByDefault);
    this.filteredHiddenColumns.set([...this.hiddenColumns]);
    this.initialHiddenColumns = [...this.hiddenColumns];
  }

  setVisibleColumns(visibleColumnFields: string[] | null) {
    this.visibleColumns.set(
      visibleColumnFields
        ? visibleColumnFields.map(field => this.columns.find(column => column.field === field)!)
        : this.columns.filter(column => column.isDisplayedByDefault),
    );
    this.initialVisibleColumns = [...this.visibleColumns()];
  }

  initSearch() {
    this.searchControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(searchKeyword => {
      this.filterHiddenColumns(searchKeyword ?? '');
    });
  }

  filterHiddenColumns(searchKeyword: string) {
    this.filteredHiddenColumns.set(
      this.hiddenColumns.filter(column =>
        column.header.trim().toLowerCase().includes(searchKeyword?.trim()?.toLowerCase()),
      ),
    );
  }

  sendUpdatedColumns() {
    this.onColumnsChange.emit([...this.visibleColumns(), customizeColumnDetails]);
  }

  clearSearch() {
    this.searchControl.setValue('');
    this.showSearch = false;
  }

  enableSearch() {
    this.showSearch = true;
  }

  makeColumnVisible(column: AttributesInfo) {
    this.visibleColumns.update(visibleColumns => {
      visibleColumns.push(column);
      return [...visibleColumns];
    });
    this.hiddenColumns = this.hiddenColumns.filter(hiddenColumn => hiddenColumn.field !== column.field);
    this.filteredHiddenColumns.update(filteredHiddenColumns =>
      filteredHiddenColumns.filter(filteredColumn => filteredColumn.field !== column.field),
    );
  }

  hideColumn(column: AttributesInfo) {
    this.visibleColumns.update(visibleColumns =>
      visibleColumns.filter(visibleColumn => visibleColumn.field !== column.field),
    );
    this.hiddenColumns.push(column);
    this.filteredHiddenColumns.update(filteredHiddenColumns => {
      filteredHiddenColumns.push(column);
      return [...filteredHiddenColumns];
    });
    if (this.searchControl.value) {
      this.filterHiddenColumns(this.searchControl.value);
    }
  }

  openOverlay() {
    this.showOverlay = true;
  }

  saveChanges() {
    this.sendUpdatedColumns();
    this.localStorageService.setItem(
      this.visibleColumnFieldsStorageKey,
      this.visibleColumns().map(column => column.field),
    );
    this.localStorageService.setItem(
      this.allColumnFieldsStorageKey,
      this.columns.map(column => column.field),
    );
    this.showOverlay = false;
  }

  onCancel() {
    this.hiddenColumns = [...this.initialHiddenColumns];
    this.filteredHiddenColumns.set([...this.initialHiddenColumns]);
    this.visibleColumns.set([...this.initialVisibleColumns]);
    this.showOverlay = false;
  }

  drop(event: dropEvent) {
    const draggableColumns = this.draggableColumns();
    moveItemInArray(draggableColumns, event.previousIndex, event.currentIndex);
    this.visibleColumns.set([...this.lockedColumns(), ...draggableColumns]);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from '@app/services/app-config.service';

const MEDIA_TYPE = 'application/vnd.api+json';

export interface Elexant40x0_actual_configAttrs {
  agent_communication_failure_limit: string;
  agent_communication_failure_mask: boolean;
  alarm_0_mode: string;
  alarm_0_toggle_time: string;
  created_at: string;
  device_broadcast_timeout: string;
  device_reset_mask: boolean;
  device_tag: string;
  digital_input_source_0_mode: string;
  digital_input_source_0_polarity: string;
  eth_0_configured_default_gateway: string;
  eth_0_configured_ip_address: string;
  eth_0_configured_net_mask: string;
  eth_0_modbus_tcp_port: number;
  htc_0_auto_cycle_enabled: boolean;
  htc_0_auto_cycle_interval: string;
  htc_0_balanced_load: boolean;
  htc_0_circuit_breaker_0_current_rating: string;
  htc_0_circuit_breaker_0_limiting_latch: boolean;
  htc_0_circuit_breaker_0_limiting_mask: boolean;
  htc_0_circuit_breaker_0_type: string;
  htc_0_contactor_cycle_count_limit_exceeded_mask: boolean;
  htc_0_contactor_cycle_count_setpoint: number;
  htc_0_contactor_polarity_under_ssr_active: boolean;
  htc_0_control_temperature_failure_latch: boolean;
  htc_0_control_temperature_failure_mask: boolean;
  htc_0_control_temperature_high_alarm_filter: boolean;
  htc_0_control_temperature_high_alarm_setpoint: string;
  htc_0_control_temperature_high_latch: boolean;
  htc_0_control_temperature_high_mask: boolean;
  htc_0_control_temperature_low_alarm_filter: boolean;
  htc_0_control_temperature_low_alarm_setpoint: string;
  htc_0_control_temperature_low_latch: boolean;
  htc_0_control_temperature_low_mask: boolean;
  htc_0_control_temperature_setpoint: string;
  htc_0_current_turns_ratio: number;
  htc_0_deadband: string;
  htc_0_energize: boolean;
  htc_0_ground_fault_current_high_alarm_filter: boolean;
  htc_0_ground_fault_current_high_alarm_setpoint: string;
  htc_0_ground_fault_current_high_latch: boolean;
  htc_0_ground_fault_current_high_mask: boolean;
  htc_0_ground_fault_current_trip_setpoint: string;
  htc_0_ground_fault_current_zero_offset: string;
  htc_0_ground_fault_trip_mask: boolean;
  htc_0_ground_fault_turns_ratio: number;
  htc_0_heater_configuration: string;
  htc_0_heater_time_limit_exceeded_mask: boolean;
  htc_0_heater_time_setpoint: string;
  htc_0_high_limit_cutout_latch: boolean;
  htc_0_high_limit_cutout_mask: boolean;
  htc_0_high_limit_cutout_setpoint: string;
  htc_0_line_current_0_high_alarm_filter: boolean;
  htc_0_line_current_0_high_alarm_setpoint: string;
  htc_0_line_current_0_high_latch: boolean;
  htc_0_line_current_0_high_mask: boolean;
  htc_0_line_current_0_low_alarm_filter: boolean;
  htc_0_line_current_0_low_alarm_setpoint: string;
  htc_0_line_current_0_low_latch: boolean;
  htc_0_line_current_0_low_mask: boolean;
  htc_0_line_current_0_maximum: string;
  htc_0_line_current_1_high_alarm_filter: boolean;
  htc_0_line_current_1_high_alarm_setpoint: string;
  htc_0_line_current_1_high_latch: boolean;
  htc_0_line_current_1_high_mask: boolean;
  htc_0_line_current_1_low_alarm_filter: boolean;
  htc_0_line_current_1_low_alarm_setpoint: string;
  htc_0_line_current_1_low_latch: boolean;
  htc_0_line_current_1_low_mask: boolean;
  htc_0_line_current_2_high_alarm_filter: boolean;
  htc_0_line_current_2_high_alarm_setpoint: string;
  htc_0_line_current_2_high_latch: boolean;
  htc_0_line_current_2_high_mask: boolean;
  htc_0_line_current_2_low_alarm_filter: boolean;
  htc_0_line_current_2_low_alarm_setpoint: string;
  htc_0_line_current_2_low_latch: boolean;
  htc_0_line_current_2_low_mask: boolean;
  htc_0_line_voltage_high_latch: boolean;
  htc_0_line_voltage_high_mask: boolean;
  htc_0_line_voltage_low_latch: boolean;
  htc_0_line_voltage_low_mask: boolean;
  htc_0_load_shed_enabled: boolean;
  htc_0_load_shed_fail_safe_enabled: boolean;
  htc_0_low_limit_cutout_deadband: string;
  htc_0_low_limit_cutout_latch: boolean;
  htc_0_low_limit_cutout_mask: boolean;
  htc_0_low_limit_cutout_setpoint: string;
  htc_0_output_0_limiting_latch: boolean;
  htc_0_output_0_limiting_mask: boolean;
  htc_0_output_limit_mode: string;
  htc_0_output_limit_percentage_maximum: string;
  htc_0_output_switch_type: string;
  htc_0_pasc_min_ambient_temperature: string;
  htc_0_pasc_min_pipe_size: string;
  htc_0_pasc_power_adjust: string;
  htc_0_power_0_maximum_limit_setpoint: string;
  htc_0_proportional_band: string;
  htc_0_ssr_rating: string;
  htc_0_switch_0_limiting_latch: boolean;
  htc_0_switch_0_limiting_mask: boolean;
  htc_0_switch_control_mode: string;
  htc_0_temperature_control_mode: string;
  htc_0_temperature_fail_mode: string;
  htc_0_temperature_fail_mode_percentage: string;
  htc_0_tracing_resistance_0_high_alarm_filter: boolean;
  htc_0_tracing_resistance_0_high_deviation: string;
  htc_0_tracing_resistance_0_high_latch: boolean;
  htc_0_tracing_resistance_0_high_mask: boolean;
  htc_0_tracing_resistance_0_low_alarm_filter: boolean;
  htc_0_tracing_resistance_0_low_deviation: string;
  htc_0_tracing_resistance_0_low_latch: boolean;
  htc_0_tracing_resistance_0_low_mask: boolean;
  htc_0_tracing_resistance_0_nominal: string;
  htc_0_tracing_resistance_1_high_alarm_filter: boolean;
  htc_0_tracing_resistance_1_high_deviation: string;
  htc_0_tracing_resistance_1_high_latch: boolean;
  htc_0_tracing_resistance_1_high_mask: boolean;
  htc_0_tracing_resistance_1_low_alarm_filter: boolean;
  htc_0_tracing_resistance_1_low_deviation: string;
  htc_0_tracing_resistance_1_low_latch: boolean;
  htc_0_tracing_resistance_1_low_mask: boolean;
  htc_0_tracing_resistance_1_nominal: string;
  htc_0_tracing_resistance_2_high_alarm_filter: boolean;
  htc_0_tracing_resistance_2_high_deviation: string;
  htc_0_tracing_resistance_2_high_latch: boolean;
  htc_0_tracing_resistance_2_high_mask: boolean;
  htc_0_tracing_resistance_2_low_alarm_filter: boolean;
  htc_0_tracing_resistance_2_low_deviation: string;
  htc_0_tracing_resistance_2_low_latch: boolean;
  htc_0_tracing_resistance_2_low_mask: boolean;
  htc_0_tracing_resistance_2_nominal: string;
  htc_0_voltage_high_alarm_filter: boolean;
  htc_0_voltage_high_alarm_setpoint: string;
  htc_0_voltage_low_alarm_filter: boolean;
  htc_0_voltage_low_alarm_setpoint: string;
  htc_0_voltage_measurement_configuration: string;
  htc_0_voltage_turns_ratio: number;
  limiter_0_cutout_setpoint: string;
  limiter_0_tag: string;
  limiter_0_temperature_sensor_failure_latch: boolean;
  limiter_0_temperature_sensor_failure_mask: boolean;
  limiter_0_trip_latch: boolean;
  limiter_0_trip_mask: boolean;
  serial_0_baud_rate: string;
  serial_0_modbus_address: number;
  serial_0_parity: string;
  serial_0_stop_bits: number;
  serial_0_tx_delay: string;
  temperature_0_calibrated_high_value: string;
  temperature_0_calibrated_low_value: string;
  temperature_0_control_enabled: boolean;
  temperature_0_failure_latch: boolean;
  temperature_0_failure_mask: boolean;
  temperature_0_high_alarm_filter: boolean;
  temperature_0_high_alarm_setpoint: string;
  temperature_0_high_cutout_enabled: boolean;
  temperature_0_high_latch: boolean;
  temperature_0_high_mask: boolean;
  temperature_0_lead_resistance: string;
  temperature_0_low_alarm_filter: boolean;
  temperature_0_low_alarm_setpoint: string;
  temperature_0_low_cutout_enabled: boolean;
  temperature_0_low_latch: boolean;
  temperature_0_low_mask: boolean;
  temperature_0_tag: string;
  temperature_0_type: string;
  temperature_1_calibrated_high_value: string;
  temperature_1_calibrated_low_value: string;
  temperature_1_control_enabled: boolean;
  temperature_1_failure_latch: boolean;
  temperature_1_failure_mask: boolean;
  temperature_1_high_alarm_filter: boolean;
  temperature_1_high_alarm_setpoint: string;
  temperature_1_high_cutout_enabled: boolean;
  temperature_1_high_latch: boolean;
  temperature_1_high_mask: boolean;
  temperature_1_lead_resistance: string;
  temperature_1_low_alarm_filter: boolean;
  temperature_1_low_alarm_setpoint: string;
  temperature_1_low_cutout_enabled: boolean;
  temperature_1_low_latch: boolean;
  temperature_1_low_mask: boolean;
  temperature_1_tag: string;
  temperature_1_type: string;
  temperature_2_calibrated_high_value: string;
  temperature_2_calibrated_low_value: string;
  temperature_2_control_enabled: boolean;
  temperature_2_failure_latch: boolean;
  temperature_2_failure_mask: boolean;
  temperature_2_high_alarm_filter: boolean;
  temperature_2_high_alarm_setpoint: string;
  temperature_2_high_cutout_enabled: boolean;
  temperature_2_high_latch: boolean;
  temperature_2_high_mask: boolean;
  temperature_2_lead_resistance: string;
  temperature_2_low_alarm_filter: boolean;
  temperature_2_low_alarm_setpoint: string;
  temperature_2_low_cutout_enabled: boolean;
  temperature_2_low_latch: boolean;
  temperature_2_low_mask: boolean;
  temperature_2_tag: string;
  temperature_2_type: string;
}

export interface Elexant40x0_actual_configRels {
  bases: { data: ResourceIdentifierObject[] };
  desired_config: { data: ResourceIdentifierObject };
  elexant40x0: { data: ResourceIdentifierObject };
  latest: { data: ResourceIdentifierObject };
}

export interface Elexant40x0_actual_config<
  Attrs = Partial<Elexant40x0_actual_configAttrs>,
  Rels = Partial<Elexant40x0_actual_configRels>,
> {
  type: string;
  id: string;
  attributes?: Attrs;
  relationships?: Rels;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Elexant40x0_actual_configData {
  data: Elexant40x0_actual_config;
  included?: ResourceIdentifierObject[];
}

export interface MultiElexant40x0_actual_configData {
  data: Elexant40x0_actual_config[];
  included?: ResourceIdentifierObject[];
}

@Injectable({
  providedIn: 'root',
})
export class Elexant40x0_actual_configService {
  uiBaseUrl = '';

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
  ) {
    this.uiBaseUrl = this.appConfigService.getJsonApiUrl();
  }

  getElexant40x0_actual_configs(params: HttpParams): Observable<MultiElexant40x0_actual_configData> {
    const url = `${this.uiBaseUrl}/elexant40x0_actual_configs`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<MultiElexant40x0_actual_configData>(url, options);
  }

  getElexant40x0_actual_config(id: string, params: HttpParams): Observable<Elexant40x0_actual_configData> {
    const url = `${this.uiBaseUrl}/elexant40x0_actual_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<Elexant40x0_actual_configData>(url, options);
  }

  addElexant40x0_actual_config(
    elexant40x0_actual_config: Elexant40x0_actual_config,
  ): Observable<Elexant40x0_actual_config> {
    const url = `${this.uiBaseUrl}/elexant40x0_actual_configs`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
    };
    return this.http.post<Elexant40x0_actual_config>(url, { data: elexant40x0_actual_config }, options);
  }

  updateElexant40x0_actual_config(
    elexant40x0_actual_config: Elexant40x0_actual_config,
  ): Observable<Elexant40x0_actual_configData> {
    const url = `${this.uiBaseUrl}/elexant40x0_actual_configs/` + elexant40x0_actual_config.id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
    };
    return this.http.patch<Elexant40x0_actual_configData>(url, { data: elexant40x0_actual_config }, options);
  }

  removeElexant40x0_actual_config(id: string): Observable<never> {
    const url = `${this.uiBaseUrl}/elexant40x0_actual_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
    };
    return this.http.delete<never>(url, options);
  }
}

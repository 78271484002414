import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { AboutPageComponent } from '@shared/about-page/about-page.component';
import { PlotlyChartComponent } from '@shared/chart/plotly-chart/plotly-chart.component';
import { AccordionComponent } from '@shared/components/accordion/accordion.component';
import { ApexchartsComponent } from '@shared/components/apexcharts/apexcharts.component';
import { CustomizeColumnsComponent } from '@shared/components/customize-columns/customize-columns.component';
import { DeviceNotesComponent } from '@shared/components/device-notes/device-notes.component';
import { HeaderComponent } from '@shared/components/header/header.component';
import { DialogComponent } from '@shared/dialog/dialog.component';
import { ColumnResizerDirective } from '@shared/directives/column-resizer.directive';
import { NavComponent } from '@shared/nav/nav.component';
import { NoDataAvailableComponent } from '@shared/no-data-available/no-data-available.component';
import { OverlayComponent } from '@shared/overlay/overlay.component';
import { AlarmAttributeValuePipe } from '@shared/pipes/alarm-attribute-value.pipe';
import { CamelcasePipe } from '@shared/pipes/camelcase.pipe';
import { DeviceAttributeValuePipe } from '@shared/pipes/device-attribute-value.pipe';
import { FormatTimePipe } from '@shared/pipes/format-time.pipe';
import { TermsComponent } from '@shared/terms/terms.component';
import { TrendDurationComponent } from '@shared/trend-duration/trend-duration.component';
import { TrendSelectionComponent } from '@shared/trend-selection/trend-selection.component';
import { TrendComponent } from '@shared/trend/trend.component';
import { WaitForApprovalComponent } from '@shared/wait-for-approval/wait-for-approval.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BlockUIModule } from 'ng-block-ui';
import { InputSelectComponent } from './components/input-select/input-select.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { CheckPermissionsDirective } from './directives/check-permissions.directive';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
  declarations: [
    OverlayComponent,
    HeaderComponent,
    NavComponent,
    NoDataAvailableComponent,
    TermsComponent,
    WaitForApprovalComponent,
    AboutPageComponent,
    ColumnResizerDirective,
    FormatTimePipe,
    CamelcasePipe,
    DeviceAttributeValuePipe,
    AlarmAttributeValuePipe,
    TrendDurationComponent,
    TrendSelectionComponent,
    ApexchartsComponent,
    PlotlyChartComponent,
    TrendComponent,
    DialogComponent,
    CustomizeColumnsComponent,
    InputTextComponent,
    InputSelectComponent,
    CheckPermissionsDirective,
    UnauthorizedComponent,
    AccordionComponent,
    DeviceNotesComponent,
  ],
  imports: [
    RouterModule,
    MaterialModule,
    CommonModule,
    BlockUIModule.forRoot(),
    OverlayModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
  ],
  exports: [
    OverlayComponent,
    HeaderComponent,
    NavComponent,
    NoDataAvailableComponent,
    TermsComponent,
    WaitForApprovalComponent,
    AboutPageComponent,
    ColumnResizerDirective,
    CheckPermissionsDirective,
    FormatTimePipe,
    CamelcasePipe,
    DeviceAttributeValuePipe,
    AlarmAttributeValuePipe,
    TrendDurationComponent,
    TrendSelectionComponent,
    ApexchartsComponent,
    PlotlyChartComponent,
    TrendComponent,
    DialogComponent,
    CustomizeColumnsComponent,
    InputTextComponent,
    InputSelectComponent,
    DeviceNotesComponent,
    AccordionComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}

export const defaultWriteOptions = {
  bookType: 'csv',
};

export const getJsonToSheetOptions = (headers: string[]) => {
  return {
    dateNF: 'mm/dd/yyyy hh:mm:ss',
    cellDates: true,
    header: headers,
  };
};

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base } from '@models/device';
import { AppConfigService } from '@services/app-config.service';
import { Htc910 } from '@services/jsonapi-services/htc910.service';
import { Htc910_desired_config } from '@services/jsonapi-services/htc910_desired_config.service';

export type Htc910_actual_configAttributes = {
  alarm_0_mode?: boolean;
  console_0_control_temperature_setpoint_lower_bound?: string;
  console_0_control_temperature_setpoint_upper_bound?: string;
  created_at?: string;
  device_point_being_used?: string;
  device_reset_mask?: boolean;
  device_tag?: string;
  htc_0_auto_cycle_enabled?: boolean;
  htc_0_auto_cycle_interval?: string;
  htc_0_auto_cycle_interval_unit?: boolean;
  htc_0_circuit_breaker_0_current_rating?: string;
  htc_0_circuit_breaker_0_limiting_mask?: boolean;
  htc_0_contactor_cycle_count_limit_exceeded_mask?: boolean;
  htc_0_contactor_cycle_count_setpoint?: string;
  htc_0_control_temperature_failure_mask?: boolean;
  htc_0_control_temperature_setpoint?: string;
  htc_0_deadband?: string;
  htc_0_external_input_configuration?: string;
  htc_0_ground_fault_current_high_alarm_filter?: string;
  htc_0_ground_fault_current_high_alarm_setpoint?: string;
  htc_0_ground_fault_current_high_mask?: boolean;
  htc_0_ground_fault_current_trip_setpoint?: string;
  htc_0_ground_fault_trip_mask?: boolean;
  htc_0_line_current_0_over_mask?: boolean;
  htc_0_line_voltage_high_mask?: boolean;
  htc_0_line_voltage_low_mask?: boolean;
  htc_0_load_current_high_alarm_filter?: string;
  htc_0_load_current_high_alarm_setpoint?: string;
  htc_0_load_current_high_mask?: boolean;
  htc_0_load_current_low_alarm_filter?: string;
  htc_0_load_current_low_alarm_setpoint?: string;
  htc_0_load_current_low_mask?: boolean;
  htc_0_load_shed_enabled?: boolean;
  htc_0_load_shed_fail_safe_enabled?: boolean;
  htc_0_load_shed_zone_0_enabled?: boolean;
  htc_0_load_shed_zone_1_enabled?: boolean;
  htc_0_load_shed_zone_2_enabled?: boolean;
  htc_0_load_shed_zone_3_enabled?: boolean;
  htc_0_maximum_power_setpoint?: string;
  htc_0_non_latching_temperature_alarms?: boolean;
  htc_0_override_source?: boolean;
  htc_0_power_limiting_mask?: boolean;
  htc_0_proportional_ambient_cycle_time?: string;
  htc_0_proportional_band?: string;
  htc_0_remote_override?: string;
  htc_0_ssr_rating?: string;
  htc_0_switch_0_failure_mask?: boolean;
  htc_0_switch_0_limiting_mask?: boolean;
  htc_0_switch_control_mode?: string;
  htc_0_temperature_control_mode?: string;
  htc_0_temperature_fail_mode?: boolean;
  htc_0_three_phase_power_calculation_enabled?: boolean;
  htc_0_tracing_resistance_0_high_alarm_filter?: string;
  htc_0_tracing_resistance_0_high_deviation?: string;
  htc_0_tracing_resistance_0_high_mask?: boolean;
  htc_0_tracing_resistance_0_low_alarm_filter?: string;
  htc_0_tracing_resistance_0_low_deviation?: string;
  htc_0_tracing_resistance_0_low_mask?: boolean;
  htc_0_tracing_resistance_0_nominal?: string;
  htc_0_voltage_high_alarm_filter?: string;
  htc_0_voltage_high_alarm_setpoint?: string;
  htc_0_voltage_low_alarm_filter?: string;
  htc_0_voltage_low_alarm_setpoint?: string;
  htc_0_voltage_source?: string;
  nvram_0_total_failure_mask?: boolean;
  serial_0_baud_rate?: string;
  serial_0_communications_driver?: string;
  serial_0_communications_profile?: string;
  serial_0_frame_type?: string;
  serial_0_modbus_address?: string;
  serial_0_modbus_sub_address?: string;
  serial_0_parity?: string;
  serial_0_tx_delay?: string;
  temperature_0_failure_mask?: boolean;
  temperature_0_high_alarm_filter?: string;
  temperature_0_high_alarm_setpoint?: string;
  temperature_0_high_cutout_enabled?: boolean;
  temperature_0_high_mask?: boolean;
  temperature_0_lead_resistance?: string;
  temperature_0_low_alarm_filter?: string;
  temperature_0_low_alarm_setpoint?: string;
  temperature_0_low_mask?: boolean;
  temperature_0_type?: string;
  temperature_1_failure_mask?: boolean;
  temperature_1_high_alarm_setpoint?: string;
  temperature_1_high_cutout_enabled?: boolean;
  temperature_1_high_mask?: boolean;
  temperature_1_lead_resistance?: string;
  temperature_1_low_alarm_setpoint?: string;
  temperature_1_low_mask?: boolean;
  temperature_1_type?: string;
  desired_config_id?: string;
  config_id?: string;
};

interface Htc910_actual_configRelationships {
  bases?: { data: Htc910_desired_config[] };
  complete?: { data: Htc910_desired_config[] };
  htc?: { data: Htc910 };
  latest?: { data: Htc910 };
}

export interface Htc910_actual_config extends Base {
  id: string;
  attributes?: Htc910_actual_configAttributes;
  relationships?: Htc910_actual_configRelationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Htc910_actual_configData {
  data: Htc910_actual_config;
  included?: ResourceIdentifierObject[];
}

export interface Htc910_actual_configsData {
  data: Htc910_actual_config[];
  included?: ResourceIdentifierObject[];
}

@Injectable({
  providedIn: 'root',
})
export class Htc910_actual_configService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }
  getHtc910_actual_configs(params: HttpParams): Observable<Htc910_actual_configsData> {
    const url = `${this.uiBaseURL}/htc910_actual_configs`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Htc910_actual_configsData>(url, options);
  }

  getHtc910_actual_config(id: string, params: HttpParams): Observable<Htc910_actual_configData> {
    const url = `${this.uiBaseURL}/htc910_actual_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Htc910_actual_configData>(url, options);
  }

  addHtc910_actual_config(htc910_actual_config: Htc910_actual_config): Observable<Htc910_actual_config> {
    const url = `${this.uiBaseURL}/htc910_actual_configs`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.post<Htc910_actual_config>(url, { data: htc910_actual_config }, options);
  }

  updateHtc910_actual_config(htc910_actual_config: Htc910_actual_config): Observable<Htc910_actual_configData> {
    const url = `${this.uiBaseURL}/htc910_actual_configs/` + htc910_actual_config.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<Htc910_actual_configData>(url, { data: htc910_actual_config }, options);
  }

  removeHtc910_actual_config(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/htc910_actual_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.delete<never>(url, options);
  }
}

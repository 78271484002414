import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base, IncludedRelationship } from '@models/device';
import { AppConfigService } from '@services/app-config.service';
import { Ngc40htc_actual_config } from '@services/jsonapi-services/ngc40htc_actual_config.service';
import { Ngc40htc_desired_config } from '@services/jsonapi-services/ngc40htc_desired_config.service';
import { Ngc40htc_latest_state } from '@services/jsonapi-services/ngc40htc_latest_state.service';

interface Ngc40htcAttributes {
  name?: string;
  serial?: number;
}

export const typeNameByRelName: Record<string, string> = {
  actual_configs: 'ngc40htc_actual_configs',
  bridge: 'ngc40bridges',
  desired_config: 'ngc40htc_desired_configs',
  device: 'devices',
  info: 'ngc40htc_infos',
  latest_actual_config: 'ngc40htc_actual_configs',
  latest_state: 'ngc40htc_latest_states',
  states: 'ngc40htc_states',
};

export interface Ngc40htcRelationships {
  actual_configs?: { data: Ngc40htc_actual_config[] };
  bridge?: { data: ResourceIdentifierObject };
  desired_config?: { data: Ngc40htc_desired_config[] };
  device?: { data: unknown };
  info?: { data: unknown };
  latest_actual_config?: { data: Ngc40htc_actual_config };
  latest_state?: { data: Ngc40htc_latest_state };
  states?: { data: unknown[] };
}

export interface Ngc40htc extends Base {
  id: string;
  type: string;
  attributes?: Ngc40htcAttributes;
  relationships?: Ngc40htcRelationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Ngc40htcData {
  data: Ngc40htc;
  included?: IncludedRelationship[];
}

// TODO {.Plural | pascal}
export interface Ngc40htcsData {
  data: Ngc40htc[];
  included?: IncludedRelationship[];
}

@Injectable({
  providedIn: 'root',
})
export class Ngc40htcService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getNgc40htcs(params: HttpParams): Observable<Ngc40htcsData> {
    const url = `${this.uiBaseURL}/ngc40htcs`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40htcsData>(url, options);
  }

  getNgc40htc(id: string, params: HttpParams): Observable<Ngc40htcData> {
    const url = `${this.uiBaseURL}/ngc40htcs/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40htcData>(url, options);
  }

  addNgc40htc(ngc40htc: Ngc40htc): Observable<Ngc40htc> {
    const url = `${this.uiBaseURL}/ngc40htcs`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.post<Ngc40htc>(url, { data: ngc40htc }, options);
  }

  updateNgc40htc(ngc40htc: Ngc40htc): Observable<Ngc40htcData> {
    const url = `${this.uiBaseURL}/ngc40htcs/` + ngc40htc.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<Ngc40htcData>(url, { data: ngc40htc }, options);
  }

  removeNgc40htc(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/ngc40htcs/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.delete<never>(url, options);
  }

  getNgc40htcTrendData(params: HttpParams, id: string): Observable<Ngc40htcsData> {
    const url = `${this.uiBaseURL}/ngc40htcs/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Ngc40htcsData>(url, options);
  }
}

import { HttpParams } from '@angular/common/http';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserPreferenceUIModel } from '@app/models/user_preference';
import { eventDeviceRelationships } from '@app/utils/constants/device-constants';
import { environment } from '@env/environment';
import { AlarmUIModel } from '@models/alarm';
import { DataArrayModel, DeviceUIModel } from '@models/device';
import { ConversionService } from '@services/conversion.service';
import { Event, EventService, ResourceObject } from '@services/event.service';
import {
  AlarmPulse,
  AlarmService,
  MultiAlarmData,
  MultiAlarmPulseData,
} from '@services/jsonapi-services/alarm.service';
import { UserService } from '@services/jsonapi-services/user.service';
import { AlarmDataMapperService } from '@services/mapper-services/alarm-mapper.service';
import { Cm2000DeviceDataMapperService } from '@services/mapper-services/cm2000-device-data-mapper.service';
import { Cm2000pDeviceDataMapperService } from '@services/mapper-services/cm2000p-device-data-mapper.service';
import { Elexant3500iDeviceDataMapperService } from '@services/mapper-services/elexant3500i-device-data-mapper.service';
import { Elexant40X0DeviceDataMapperService } from '@services/mapper-services/elexant40X0-device-data-mapper.service';
import { Elexant5010iDeviceDataMapperService } from '@services/mapper-services/elexant5010i-device-data-mapper.service';
import { Htc910DeviceDataMapperService } from '@services/mapper-services/htc910-device-data-mapper.service';
import { Htc920DeviceDataMapperService } from '@services/mapper-services/htc920-device-data-mapper.service';
import { Ngc20DeviceDataMapperService } from '@services/mapper-services/ngc20-device-data-mapper.service';
import { Ngc40htcDeviceDataMapperService } from '@services/mapper-services/ngc40htc-device-data-mapper.service';
import { Ngc40htc3DeviceDataMapperService } from '@services/mapper-services/ngc40htc3-device-data-mapper.service';
import { Ngc40ioDeviceDataMapperService } from '@services/mapper-services/ngc40io-device-data-mapper.service';
import { Ngc40slimDeviceDataMapperService } from '@services/mapper-services/ngc40slim-device-data-mapper.service';
import { SocketService } from '@services/socket.service';
import { UserUtilService } from '@services/user-util.service';
import { alarmDataParam } from '@utils/constants/params';
import { AlarmStateEnum } from '@utils/enums/alarm-enums';
import { DeviceActualConfigType, DeviceLatestStateType, DeviceType } from '@utils/enums/device-enums';
import { CacheEvents, EventOperation, Events } from '@utils/enums/event-enum';
import { MeasurementUnits, TemperatureUnits } from '@utils/enums/unit-enums';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataStoreService {
  destroyRef = inject(DestroyRef);
  alarmsData = new BehaviorSubject<DataArrayModel<AlarmUIModel>>({ data: [], isLoading: true });
  devicesData = new BehaviorSubject<DataArrayModel<DeviceUIModel>>({ data: [], isLoading: true });
  newAlarmCount = new BehaviorSubject<number>(0);
  sendUpdatedDeviceData = new Subject<Event>();
  preferenceUnit!: string | undefined;
  preferenceId!: string;

  public initialized!: Promise<void>;

  constructor(
    private conversionService: ConversionService,
    private userUtilsService: UserUtilService,
    private userService: UserService,
    public webSocket: SocketService,
    private event: EventService,
    private ngc40htcMappperService: Ngc40htcDeviceDataMapperService,
    private ngc20MapperService: Ngc20DeviceDataMapperService,
    private ngc40htc3MappperService: Ngc40htc3DeviceDataMapperService,
    private ngc40ioMappperService: Ngc40ioDeviceDataMapperService,
    private ngc40slimMappperService: Ngc40slimDeviceDataMapperService,
    private htc910MapperService: Htc910DeviceDataMapperService,
    private htc920MapperService: Htc920DeviceDataMapperService,
    private cm2000MapperService: Cm2000DeviceDataMapperService,
    private cm2000pMapperService: Cm2000pDeviceDataMapperService,
    private alarmService: AlarmService,
    public alarmDataMapperService: AlarmDataMapperService,
    private elexant40X0MapperService: Elexant40X0DeviceDataMapperService,
    private elexant3500iMapperService: Elexant3500iDeviceDataMapperService,
    private elexant5010iMapperService: Elexant5010iDeviceDataMapperService,
  ) {
    this.preferenceUnit = TemperatureUnits.Celsius;
    this.webSocket.initializeWebSocket(true);
    this.userSettings();
  }

  userSettings() {
    const userParam: HttpParams = new HttpParams()
      .set('filter[id_tokens.subject]', this.userUtilsService.authId)
      .set('include', 'preference')
      .set('fields[preferences]', 'unit')
      .set('fields[users]', '');
    this.userService
      .getMulti(userParam)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (userPreferenceData: any) => {
          if (userPreferenceData?.included) {
            this.preferenceId = userPreferenceData.included[0].id;
            const userPreference: UserPreferenceUIModel = {
              id: userPreferenceData?.included[0]?.id,
              unit: userPreferenceData?.included[0]?.attributes?.unit,
            };
            this.userUtilsService.setUserPreference(userPreference);
            this.userUtilsService.setUserId(userPreferenceData.data[0].id);
            if (environment.e2e) {
              localStorage.setItem('e2ePreferenceId', JSON.stringify(userPreferenceData?.included[0]?.id));
            }
          }
          this.getDevicesFromCache();
          this.getCurrentUserPreference();
          this.getAlarmsFromCache();
        },
      }),
      (error: unknown) => {
        console.error(error);
      };
  }

  getDevicesFromCache(): void {
    this.webSocket.dataSource.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (res: any) => {
        if (res && res.event === CacheEvents.GetDevices) {
          let devices = JSON.parse(JSON.stringify(res.data));
          devices.forEach((device: DeviceUIModel) => {
            device.alarmState = this.conversionService.convertStringToBoolean(device.alarmStateValue!);
            device.onlineState = this.conversionService.convertStringToBoolean(device.onlineStateValue!);
            device.alarmsCount = 0;
            if (device.outputStateValue !== undefined)
              device.outputState = this.conversionService.convertStringToBoolean(device.outputStateValue!);
          });
          devices = devices.sort((a: { deviceTag: any }, b: { deviceTag: any }) =>
            a.deviceTag < b.deviceTag ? -1 : 1,
          );
          if (this.preferenceUnit === TemperatureUnits.Fahrenheit) {
            devices = this.updateDevicesOnPreferenceChange(devices);
          }
          this.setDevicesData(devices, false);
        }
      },
      error: (err: unknown) => {
        this.devicesData.error(err);
        this.setDevicesData([], false);
      },
    });
  }

  getCurrentUserPreference(): void {
    this.userUtilsService
      .getUserPreference()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (userPreference: UserPreferenceUIModel | undefined) => {
          this.conversionService.preferenceUnit = userPreference!.unit;
          const devices = this.devicesData.getValue().data;
          if (devices.length) {
            const updatedDevices = this.updateDevicesOnPreferenceChange(devices);
            this.setDevicesData(updatedDevices, false);
          }
        },
        error: (error: unknown) => {
          console.error(error);
        },
      });
  }

  updateDevicesOnPreferenceChange(devicesData: DeviceUIModel[]): DeviceUIModel[] {
    return this.conversionService.getDevicesDataOnPreferenceChange(devicesData);
  }

  getAlarmsFromCache(): void {
    this.webSocket.dataSource.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (res: any) => {
        if (res && res.event === CacheEvents.GetAlarms) {
          let alarms = JSON.parse(JSON.stringify(res.data));
          let deviceList = this.devicesData.getValue().data;
          alarms = alarms.filter((alarm: AlarmUIModel) => {
            const [index, device] = this.getDeviceById(alarm.deviceId!);
            if (device) {
              device.alarmsCount = device.alarmsCount! + 1;
              deviceList[index] = device;
            }
            if (alarm.shelveTimeRemaining === 'None') alarm.shelveTimeRemaining = 'NA';
            return alarm.timestamp !== null;
          });
          this.setDevicesData(deviceList, false);
          this.setAlarmsData(alarms, false);
          this.bindEvents();
        }
      },
      error: (err: unknown) => {
        this.alarmsData.error(err);
        this.setAlarmsData([], false);
      },
    });
  }

  setAlarmsData(alarmsData: AlarmUIModel[], isLoading: boolean): void {
    alarmsData = alarmsData.filter(alarm => alarm.alarmState !== AlarmStateEnum.Reset);
    const alarms: DataArrayModel<AlarmUIModel> = {
      data: alarmsData,
      isLoading: isLoading,
    };
    this.alarmsData.next(alarms);
    let newAlarmCount: number = 0;
    alarmsData.forEach(alarm => {
      if (alarm.timestamp && new Date(alarm.timestamp) > new Date(new Date().getTime() - 86400000))
        newAlarmCount = newAlarmCount + 1;
    });
    this.newAlarmCount.next(newAlarmCount);
  }

  getAlarmsData(): Observable<DataArrayModel<AlarmUIModel>> {
    return this.alarmsData;
  }

  getNewAlarmCount(): Observable<number> {
    return this.newAlarmCount;
  }

  setDevicesData(devicesData: DeviceUIModel[], isLoading: boolean): void {
    const devices: DataArrayModel<DeviceUIModel> = {
      data: devicesData,
      isLoading: isLoading,
    };
    this.devicesData.next(devices);
  }

  getDevicesData(): BehaviorSubject<DataArrayModel<DeviceUIModel>> {
    return this.devicesData;
  }

  getDeviceById(id: string): [number, DeviceUIModel] {
    const deviceIndex = this.devicesData.getValue().data.findIndex(device => device.id === id);
    return [deviceIndex, { ...this.devicesData.getValue().data[deviceIndex] }];
  }

  mapUpdatedDevice(deviceToUpdate: DeviceUIModel, deviceLatestState: Event): DeviceUIModel {
    switch (deviceToUpdate.deviceType) {
      case DeviceType.Ngc40htc:
        return this.ngc40htcMappperService.mapNgc40htcDeviceLatestState(
          deviceToUpdate,
          deviceLatestState?.data.attributes!,
        );
      case DeviceType.Ngc20:
        return this.ngc20MapperService.mapNgc20DeviceLatestState(deviceToUpdate, deviceLatestState?.data.attributes!);
      case DeviceType.Ngc40htc3:
        return this.ngc40htc3MappperService.mapNgc40htc3DeviceLatestState(
          deviceToUpdate!,
          deviceLatestState?.data.attributes!,
        );
      case DeviceType.Ngc40io:
        return this.ngc40ioMappperService.mapNgc40IoDeviceLatestState(
          deviceToUpdate,
          deviceLatestState?.data.attributes!,
        );
      case DeviceType.Ngc40slim:
        return this.ngc40slimMappperService.mapNgc40SlimDeviceLatestState(
          deviceToUpdate,
          deviceLatestState?.data.attributes!,
        );
      case DeviceType.Htc910:
        return this.htc910MapperService.mapHtc910DeviceLatestState(deviceToUpdate, deviceLatestState?.data.attributes!);
      case DeviceType.Htc920:
        return this.htc920MapperService.mapHtc920DeviceLatestState(deviceToUpdate, deviceLatestState?.data.attributes!);
      case DeviceType.Cm2000:
        return this.cm2000MapperService.mapCm2000DeviceLatestState(deviceToUpdate, deviceLatestState?.data.attributes!);
      case DeviceType.Cm2000p:
        return this.cm2000pMapperService.mapCm2000pDeviceLatestState(
          deviceToUpdate,
          deviceLatestState?.data.attributes!,
        );
      case DeviceType.Elexant40X0i:
        return this.elexant40X0MapperService.mapElexant40X0LatestState(
          deviceToUpdate,
          deviceLatestState?.data.attributes!,
        );
      case DeviceType.Elexant3500i:
        return this.elexant3500iMapperService.mapElexant3500iDevice(
          deviceToUpdate,
          deviceLatestState?.data.attributes!,
        );
      case DeviceType.Elexant5010i:
        return this.elexant5010iMapperService.mapElexant5010iDeviceLatestState(
          deviceToUpdate,
          deviceLatestState?.data.attributes!,
        );
      default:
        return {} as DeviceUIModel;
    }
  }

  mapNgc40HTCxAndElexant40x0Config(device: DeviceUIModel, attributes: Record<string, null> | undefined): DeviceUIModel {
    device.setPoint = this.conversionService.setTemperature(attributes?.htc_0_control_temperature_setpoint!);
    device.controlTempHighAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.htc_0_control_temperature_high_alarm_setpoint!,
    );
    device.controlTempLowAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.htc_0_control_temperature_low_alarm_setpoint!,
    );
    device.sensorTemperature1HighAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.htc_0_control_temperature_high_alarm_setpoint!,
    );
    device.sensorTemperature1LowAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.htc_0_control_temperature_low_alarm_setpoint!,
    );
    device.sensorTemperature2HighAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.htc_0_control_temperature_high_alarm_setpoint!,
    );
    device.sensorTemperature2LowAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.htc_0_control_temperature_low_alarm_setpoint!,
    );
    device.groundFaultCurrentHighAlarmSetPoint = attributes?.htc_0_ground_fault_current_high_alarm_setpoint;
    device.groundFaultCurrentTripSetPoint = attributes?.htc_0_ground_fault_current_trip_setpoint;
    device.lineCurrentHighAlarmSetPoint = attributes?.htc_0_line_current_0_high_alarm_setpoint;
    device.lineCurrentLowAlarmSetPoint = attributes?.htc_0_line_current_0_low_alarm_setpoint;
    device.powerHighAlarmSetpoint =
      device.deviceType === DeviceType.Ngc40htc
        ? attributes?.htc_0_power_0_maximum_limit_setpoint
        : Math.max(
            this.conversionService.getDigitFromValue(attributes?.htc_0_power_0_maximum_limit_setpoint),
            this.conversionService.getDigitFromValue(attributes?.htc_0_power_1_maximum_limit_setpoint),
            this.conversionService.getDigitFromValue(attributes?.htc_0_power_2_maximum_limit_setpoint),
          ).toFixed() + MeasurementUnits.Watt;

    return device;
  }

  mapElexant3500iConfig(device: DeviceUIModel, attributes: Record<string, null> | undefined): DeviceUIModel {
    device.setPoint = this.conversionService.setTemperature(attributes?.htc_0_control_temperature_setpoint!);
    device.controlTempHighAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.htc_0_control_temperature_high_alarm_setpoint!,
    );
    device.controlTempLowAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.htc_0_control_temperature_low_alarm_setpoint!,
    );
    device.sensorTemperature1HighAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_0_high_alarm_setpoint!,
    );
    device.sensorTemperature1LowAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_0_low_alarm_setpoint!,
    );
    device.sensorTemperature2HighAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_1_high_alarm_setpoint!,
    );
    device.sensorTemperature2LowAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_1_low_alarm_setpoint!,
    );
    device.groundFaultCurrentHighAlarmSetPoint = attributes?.htc_0_ground_fault_current_high_alarm_setpoint;
    device.groundFaultCurrentTripSetPoint = attributes?.htc_0_ground_fault_current_trip_setpoint;
    return device;
  }

  mapNgc40IOConfig(device: DeviceUIModel, attributes: Record<string, null> | undefined): DeviceUIModel {
    device.sensorTemperature1HighAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_0_high_alarm_setpoint!,
    );
    device.sensorTemperature1LowAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_0_low_alarm_setpoint!,
    );
    device.sensorTemperature2HighAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_1_high_alarm_setpoint!,
    );
    device.sensorTemperature2LowAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_1_low_alarm_setpoint!,
    );
    device.sensorTemperature3HighAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_2_high_alarm_setpoint!,
    );
    device.sensorTemperature3LowAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_2_low_alarm_setpoint!,
    );
    device.sensorTemperature4HighAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_3_high_alarm_setpoint!,
    );
    device.sensorTemperature4LowAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_3_low_alarm_setpoint!,
    );

    return device;
  }

  mapCM2000xAndHTC9x0Config(device: DeviceUIModel, attributes: Record<string, null> | undefined): DeviceUIModel {
    device.setPoint = this.conversionService.setTemperature(attributes?.htc_0_control_temperature_setpoint!);
    device.controlTempHighAlarmSetPoint = this.conversionService.setTemperature(
      String(
        Number(
          Math.max(
            parseFloat(this.conversionService.removeUnit(attributes?.temperature_0_high_alarm_setpoint)),
            parseFloat(this.conversionService.removeUnit(attributes?.temperature_1_high_alarm_setpoint)),
          ),
        ).toFixed(2),
      ) + MeasurementUnits.Celsius,
    );
    device.controlTempLowAlarmSetPoint = this.conversionService.setTemperature(
      String(
        Number(
          Math.max(
            parseFloat(this.conversionService.removeUnit(attributes?.temperature_0_low_alarm_setpoint)),
            parseFloat(this.conversionService.removeUnit(attributes?.temperature_1_low_alarm_setpoint)),
          ),
        ).toFixed(2),
      ) + MeasurementUnits.Celsius,
    );
    device.sensorTemperature1HighAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_0_high_alarm_setpoint!,
    );
    device.sensorTemperature1LowAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_0_low_alarm_setpoint!,
    );
    device.sensorTemperature2HighAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_1_high_alarm_setpoint!,
    );
    device.sensorTemperature2LowAlarmSetPoint = this.conversionService.setTemperature(
      attributes?.temperature_1_low_alarm_setpoint!,
    );
    device.groundFaultCurrentHighAlarmSetPoint = attributes?.htc_0_ground_fault_current_high_alarm_setpoint;
    device.groundFaultCurrentTripSetPoint = attributes?.htc_0_ground_fault_current_trip_setpoint;
    device.lineCurrentHighAlarmSetPoint = attributes?.htc_0_load_current_high_alarm_setpoint;
    device.lineCurrentLowAlarmSetPoint = attributes?.htc_0_load_current_low_alarm_setpoint;
    device.powerHighAlarmSetpoint = attributes?.htc_0_maximum_power_setpoint;

    return device;
  }

  onDeviceLatestStateUpdate(deviceLatestStateEvent: Event) {
    this.sendUpdatedLatestStateofDevice(deviceLatestStateEvent);
    this.initialized
      .then(() => {
        const deviceType = deviceLatestStateEvent.data.type;
        if (
          deviceType === DeviceLatestStateType.Ngc40htc ||
          deviceType === DeviceLatestStateType.Ngc20 ||
          deviceType === DeviceLatestStateType.Ngc40htc3 ||
          deviceType === DeviceLatestStateType.Ngc40io ||
          deviceType === DeviceLatestStateType.Ngc40slim ||
          deviceType === DeviceLatestStateType.Htc910 ||
          deviceType === DeviceLatestStateType.Htc920 ||
          deviceType === DeviceLatestStateType.Cm2000p ||
          deviceType === DeviceLatestStateType.Elexant40X0 ||
          deviceType === DeviceLatestStateType.Elexant3500i ||
          deviceType === DeviceLatestStateType.Cm2000 ||
          deviceType === DeviceLatestStateType.Elexant5010i
        ) {
          const [deviceIndex, device] = this.getDeviceById(deviceLatestStateEvent.data.id);
          if (device) {
            const updatedDeviceData = this.mapUpdatedDevice(device, deviceLatestStateEvent);
            const devices = this.devicesData.getValue().data;
            devices.splice(deviceIndex, 1, updatedDeviceData);
            this.setDevicesData(devices, false);
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  public onDeviceUpdate(deviceEvent: Event) {
    this.initialized
      .then(() => {
        const deviceId = deviceEvent.data.id;
        const deviceAttr = deviceEvent.data.attributes!;
        const alarmsData = this.alarmsData.getValue().data;
        alarmsData.map(alarmData => {
          if (alarmData.deviceId === deviceId) {
            alarmData.devicePriority = deviceAttr.priority;
          }
        });
        this.setAlarmsData(alarmsData, false);
      })
      .catch(err => {
        console.error(err);
      });
  }

  public onAlarmUpdate(alarmEvent: Event) {
    this.initialized
      .then(() => {
        let deviceList = this.devicesData.getValue().data;
        const alarmAttr = alarmEvent.data.attributes!;
        const alarmId = alarmEvent.data.id;
        const alarmsData = this.alarmsData.getValue().data;
        for (const alarmData of alarmsData) {
          if (alarmData.id === alarmId) {
            alarmData.alarmState = alarmAttr?.state;
            if (alarmData.alarmState === AlarmStateEnum.Reset) {
              const [index, device] = this.getDeviceById(alarmData.deviceId!);
              device.alarmsCount = device.alarmsCount! > 0 ? device.alarmsCount! - 1 : 0;
              deviceList[index] = device;
            }
            alarmData.alarmPriority = alarmAttr?.priority === 1 ? 'Alarm' : 'Warning';
            if (alarmAttr?.state)
              alarmData.shelveTimeRemaining =
                alarmAttr.state === AlarmStateEnum.Shelved ? alarmAttr.shelved_until! : 'NA';
            break;
          }
        }
        this.setDevicesData(deviceList, false);
        this.setAlarmsData(alarmsData, false);
      })
      .catch(err => {
        console.error(err);
      });
  }

  public onCm2000XUpdate(event: Event) {
    this.initialized
      .then(() => {
        const deviceId = event.data.id;
        const deviceAttributes = event.data.attributes!;
        const alarmsData = this.alarmsData.getValue().data;
        alarmsData.map(alarmData => {
          if (alarmData.deviceId === deviceId) {
            alarmData.deviceTag = deviceAttributes.name;
          }
          return alarmData;
        });
        this.setAlarmsData(alarmsData, false);
      })
      .catch(err => {
        console.error(err);
      });
  }

  public onAlarmPulseUpdateOrCreate(alarmPulseEvent: Event) {
    this.initialized
      .then(() => {
        const alarmPulseAttr = alarmPulseEvent.data.attributes!;
        const alarmsData = this.alarmsData.getValue().data;
        if (alarmPulseAttr.end_at) {
          const alarmID = alarmPulseEvent.data.relationships.alarm.data.id;
          const arrayIndex = alarmsData.findIndex(e => e.id === alarmID);
          const alarmToDelete = alarmsData.find(e => e.id === alarmID);
          if (arrayIndex >= 0 && alarmToDelete) {
            const deviceList = this.devicesData.getValue().data;
            alarmsData.splice(arrayIndex, 1);
            const [index, device] = this.getDeviceById(alarmToDelete!.deviceId!);
            device.alarmsCount = device.alarmsCount! - 1;
            deviceList[index] = device;
            this.setDevicesData(deviceList, false);
            this.setAlarmsData(alarmsData, false);
          }
        } else {
          const alarmId = alarmPulseEvent.data.relationships.alarm.data.id;
          let isAlarmExist = false;
          alarmsData.map(alarmData => {
            if (alarmData.id === alarmId) {
              isAlarmExist = true;
              alarmData.timestamp = this.conversionService.convertDateTimeStringToDate(alarmPulseAttr.begin_at!);
            }
          });
          if (isAlarmExist) {
            this.setAlarmsData(alarmsData, false);
          } else {
            const alarmPulseData: MultiAlarmPulseData = {
              data: [alarmPulseEvent.data] as AlarmPulse[],
            };
            this.alarmService
              .getAlarm(alarmId, alarmDataParam)
              .pipe(
                map(response => {
                  const alarmsData: MultiAlarmData = {
                    data: [response.data],
                    included: response.included,
                  };
                  return this.alarmDataMapperService.mapAlarmsDataToFrontEnd(alarmsData, alarmPulseData);
                }),
                takeUntilDestroyed(this.destroyRef),
              )
              .subscribe({
                next: (newAlarms: AlarmUIModel[]) => {
                  let alarmsData = this.alarmsData.getValue().data;
                  const deviceList = this.devicesData.getValue().data;
                  newAlarms.forEach(alarm => {
                    const [index, device] = this.getDeviceById(alarm.deviceId!);
                    device.alarmsCount = device.alarmsCount! + 1;
                    deviceList[index] = device;
                  });
                  alarmsData = [...alarmsData, ...newAlarms];
                  this.setDevicesData(deviceList, false);
                  this.setAlarmsData(alarmsData, false);
                },
                error: (error: unknown) => {
                  console.error('Error occurred during fetching alarms', error);
                },
              });
          }
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  onDeviceActualConfigCreateOrUpdate(deviceActualConfigEvent: Event): void {
    this.initialized
      .then(() => {
        const deviceActualConfigId = deviceActualConfigEvent.data.id;
        const deviceActualConfig = deviceActualConfigEvent.data.attributes!;
        const alarmsData = this.alarmsData.getValue().data;
        alarmsData.map(alarmData => {
          if (alarmData.deviceActualConfigId === deviceActualConfigId) {
            alarmData.deviceTag = deviceActualConfig.device_tag;
          }
          return alarmData;
        });
        this.setAlarmsData(alarmsData, false);

        const relationshipType =
          eventDeviceRelationships[deviceActualConfigEvent.data.type.replace('_actual_config', '')];
        const [deviceIndex, device] = this.getDeviceById(
          deviceActualConfigEvent.data.relationships[relationshipType as keyof ResourceObject['relationships']]!.data
            .id,
        );
        if (device && Object.keys(device).length !== 0) {
          let updatedDeviceData;
          if (
            device.deviceType !== DeviceType.Cm2000 &&
            device.deviceType !== DeviceType.Cm2000p &&
            deviceActualConfigEvent.data.attributes?.device_tag
          )
            device.deviceTag = deviceActualConfigEvent.data.attributes?.device_tag!;

          switch (deviceActualConfigEvent.data.type) {
            case DeviceActualConfigType.Ngc40htc:
            case DeviceActualConfigType.Ngc40htc3:
            case DeviceActualConfigType.Elexant40X0:
              updatedDeviceData = this.mapNgc40HTCxAndElexant40x0Config(
                device,
                deviceActualConfigEvent.data.attributes,
              );
              break;
            case DeviceActualConfigType.Ngc40io:
              updatedDeviceData = this.mapNgc40IOConfig(device, deviceActualConfigEvent.data.attributes);
              break;
            case DeviceActualConfigType.Htc910:
            case DeviceActualConfigType.Htc920:
            case DeviceActualConfigType.Cm2000:
            case DeviceActualConfigType.Cm2000p:
              updatedDeviceData = this.mapCM2000xAndHTC9x0Config(device, deviceActualConfigEvent.data.attributes);
              break;
            case DeviceActualConfigType.Elexant3500i:
              updatedDeviceData = this.mapElexant3500iConfig(device, deviceActualConfigEvent.data.attributes);
              break;
            default:
              updatedDeviceData = device;
          }

          if (deviceActualConfigEvent.op === EventOperation.Create) {
            updatedDeviceData.actualConfigId = deviceActualConfigEvent.data.id;
            updatedDeviceData.desiredConfigId = 'NA';
            updatedDeviceData.desiredConfigDeadline = null;
          }

          const devices = [...this.devicesData.getValue().data];
          devices.splice(deviceIndex, 1, updatedDeviceData);
          this.setDevicesData(devices, false);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  onDeviceDesiredConfigCreateOrUpdate(deviceDesiredConfigEvent: Event) {
    this.initialized
      .then(() => {
        if (deviceDesiredConfigEvent.data) {
          const relationshipType =
            eventDeviceRelationships[deviceDesiredConfigEvent.data.type.replace('_desired_config', '')];
          const [deviceIndex, updatedDevice] = this.getDeviceById(
            deviceDesiredConfigEvent.data!.relationships[relationshipType as keyof ResourceObject['relationships']]!
              .data.id,
          );
          if (
            deviceDesiredConfigEvent.op === EventOperation.Update &&
            deviceDesiredConfigEvent.data.attributes!.state === 'done'
          ) {
            updatedDevice!.desiredConfigId = 'NA';
            updatedDevice!.desiredConfigDeadline = null;
          } else if (deviceDesiredConfigEvent.op === EventOperation.Create) {
            updatedDevice!.desiredConfigId = deviceDesiredConfigEvent.data.id;
            updatedDevice!.desiredConfigDeadline = deviceDesiredConfigEvent.data.attributes!.deadline;
          }
          const devices = this.devicesData.getValue().data;
          devices.splice(deviceIndex, 1, updatedDevice);
          this.setDevicesData(devices, false);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  onPreferenceUpdate(preferenceUpdateEvent: Event) {
    this.initialized
      .then(() => {
        if (this.preferenceId === preferenceUpdateEvent.data.id) {
          const userPreference: UserPreferenceUIModel = {
            id: preferenceUpdateEvent.data.id,
            unit: preferenceUpdateEvent.data.attributes!.unit!,
          };
          this.userUtilsService.setUserPreference(userPreference);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  sendUpdatedLatestStateofDevice(eventObject: Event) {
    this.sendUpdatedDeviceData.next(eventObject);
  }

  getUpdatedDeviceData(): Observable<Event> {
    return this.sendUpdatedDeviceData;
  }

  private bindEvents(): void {
    this.initialized = new Promise<void>((resolve, _reject) => {
      this.event.register(Events.LatestStateUpdate, this.onDeviceLatestStateUpdate.bind(this));
      this.event.register(Events.ActualConfigCreate, this.onDeviceActualConfigCreateOrUpdate.bind(this));
      this.event.register(Events.ActualConfigUpdate, this.onDeviceActualConfigCreateOrUpdate.bind(this));
      this.event.register(Events.DesiredConfigCreate, this.onDeviceDesiredConfigCreateOrUpdate.bind(this));
      this.event.register(Events.DesiredConfigUpdate, this.onDeviceDesiredConfigCreateOrUpdate.bind(this));
      this.event.register(Events.DevicesUpdate, this.onDeviceUpdate.bind(this));
      this.event.register(Events.AlarmPulsesUpdate, this.onAlarmPulseUpdateOrCreate.bind(this));
      this.event.register(Events.AlarmPulsesCreate, this.onAlarmPulseUpdateOrCreate.bind(this));
      this.event.register(Events.AlarmsUpdate, this.onAlarmUpdate.bind(this));
      this.event.register(Events.Cm2000sUpdate, this.onCm2000XUpdate.bind(this));
      this.event.register(Events.Cm2000psUpdate, this.onCm2000XUpdate.bind(this));
      this.event.register(Events.PreferenceUpdate, this.onPreferenceUpdate.bind(this));
      resolve();
    });
  }
}

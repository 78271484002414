import { Pipe, PipeTransform } from '@angular/core';
import { AlarmUIModel } from '@app/models/alarm';

@Pipe({
  name: 'alarmAttributeValue',
  pure: false,
})
export class AlarmAttributeValuePipe implements PipeTransform {
  transform(attribute: string, alarm: AlarmUIModel): string | [string, string] {
    const value = alarm[attribute as keyof AlarmUIModel];
    if (attribute === 'timestamp')
      return value ? [this.getDate(value as string), this.getTime(value as string)] : ['-', '-'];
    else return value?.toString()!;
  }

  getDate(timeStamp: string): string {
    let date = new Date(timeStamp);
    return `${date.toLocaleString('en-us', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}`;
  }

  getTime(timeStamp: string): string {
    return new Date(timeStamp).toLocaleTimeString([], {
      hourCycle: 'h23',
      hour: '2-digit',
      minute: '2-digit',
    });
  }
}

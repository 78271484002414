import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.css'],
})
export class OverlayComponent implements OnChanges {
  @BlockUI('overlay-block') blockUI!: NgBlockUI;
  @Input() title!: string;
  @Input() iconName: string = '';
  @Input() showCloseBtn: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() loadingMessage = 'Loading...';
  @Output() closeOverlayEvent: EventEmitter<void> = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges) {
    const change: SimpleChange = changes.isLoading;
    if (!change) return;
    if (change.currentValue === true && (change.previousValue === false || !change.previousValue)) {
      this.blockUI.start(this.loadingMessage);
    } else if (change.currentValue === false && change.previousValue === true) {
      this.blockUI.stop();
    }
  }

  onClose() {
    this.closeOverlayEvent.emit();
  }
}

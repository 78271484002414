import { Component, DestroyRef, EventEmitter, OnInit, Output, ViewEncapsulation, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DisplayDuration, Duration, DurationRange } from '@app/models/select-durations';
import { toDate } from 'date-fns';

@Component({
  selector: 'app-trend-duration',
  templateUrl: './trend-duration.component.html',
  styleUrl: './trend-duration.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class TrendDurationComponent implements OnInit {
  @Output() durationChange = new EventEmitter<DisplayDuration>();
  @Output() toggleCategorySelection = new EventEmitter();
  rangeDuration!: FormGroup;
  destroyRef = inject(DestroyRef);
  maxDate!: Date;
  error!: string | null;
  duration: DisplayDuration | null = Duration.oneDay;
  durationOptions!: Duration[];
  datePattern = /([1-9]|0[1-9]|1[012])[\/]([1-9]|0[1-9]|[12][0-9]|3[01])[\/]((20)[0-9][0-9])/;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.rangeDuration = this.formBuilder.group({
      start: new FormControl(<string | null>'', Validators.required),
      end: new FormControl(<string | null>'', Validators.required),
    });
    this.rangeDuration.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: range => {
        const currentDate = new Date();
        if (
          new Date(range.start).getTime() > new Date(range.end).getTime() ||
          !this.datePattern.test(new Date(this.rangeDuration.controls.start.value).toLocaleString().split(',')[0]) ||
          !this.datePattern.test(new Date(this.rangeDuration.controls.end.value).toLocaleString().split(',')[0]) ||
          range.start.valueOf() > currentDate.valueOf() ||
          range.end.valueOf() > currentDate.valueOf()
        ) {
          this.error = 'Invalid Date!';
          range.start = null;
          range.end = null;
        } else {
          this.error = null;
          this.handleRangeSelection(range);
        }
      },
      error: (err: unknown) => {
        console.error(err);
      },
    });
    this.maxDate = new Date();
    this.durationOptions = [Duration.oneDay, Duration.threeDay, Duration.week, Duration.month, Duration.year];
  }

  handleSelectDuration(data: unknown): void {
    this.resetRangeDuration();
    this.duration = data as DisplayDuration;
    this.emitEvent(this.duration);
  }

  emitEvent(duration: DisplayDuration): void {
    this.durationChange.emit(duration);
  }

  getActiveClass(displayDuration: DisplayDuration): boolean {
    return displayDuration === this.duration ? true : false;
  }

  resetRangeDuration(): void {
    if (this.rangeDuration.controls.start.value || this.rangeDuration.controls.end.value) this.rangeDuration.reset();
  }

  private handleRangeSelection(range: { start: Date; end: Date }): void {
    const date = this.extractDate(range.start, range.end);
    const durationRange = date as DurationRange;
    if (durationRange.start !== null && durationRange.end !== null) this.emitEvent(date);
    this.duration = null;
  }

  private extractDate(start: Date | null, end: Date | null): DisplayDuration {
    const endDate = !end === true ? null : toDate(new Date(end!.getFullYear(), end!.getMonth(), end!.getDate() + 1));
    return { start, end: endDate };
  }

  toggleTrendSelectionOverlay() {
    this.toggleCategorySelection.emit();
  }
}

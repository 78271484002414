export enum Page {
  Alarms = 'Alarms',
  Dashboard = 'Dashboard',
  PanelDashboard = 'Panel HMI Dashboard',
  Devices = 'Devices',
  Configuration = 'Configuration',
  Confirm = 'Confirm',
  Settings = 'Settings',
  Roles = 'Roles',
}

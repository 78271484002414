import { Injectable } from '@angular/core';
import { IdToken, IdTokenRelationships } from '@services/jsonapi-services/id_token.service';
import { Preference, PreferenceRels } from '@services/jsonapi-services/preference.service';
import { User, UserAttrs } from '@services/jsonapi-services/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserMapperService {
  mapToBackEnd(userData: UserAttrs): User {
    const userAttributes: UserAttrs = userData;
    const user: User = {
      type: 'users',
      attributes: userAttributes,
    };
    return user;
  }

  mapUserPreferenceToBackEnd(unit: string | undefined, userId?: string): Preference {
    let userPreference: Preference = {
      type: 'preferences',
      attributes: { unit },
    };
    if (userId) {
      const userPreferenceRel: PreferenceRels = {
        user: {
          data: {
            type: 'users',
            id: userId,
          },
        },
      };
      userPreference = { ...userPreference, relationships: userPreferenceRel };
    }
    return userPreference;
  }
  mapIdTokenToBackEnd(subject: string, userId: string, providerId: string): IdToken {
    let idTokenData: IdToken = {
      type: 'id_tokens',
      attributes: { subject },
    };

    const userPreferenceRel: IdTokenRelationships = {
      user: {
        data: {
          type: 'users',
          id: userId,
        },
      },
      id_provider: {
        data: {
          type: 'id_providers',
          id: providerId,
        },
      },
    };
    idTokenData = { ...idTokenData, relationships: userPreferenceRel };
    return idTokenData;
  }
}

// Code generated by "jsonapi"; DO NOT EDIT.

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Host, HostData, MultiHostData } from '@models/jsonapi-models/host.model';
import { AppConfigService } from '@services/app-config.service';
import { Observable } from 'rxjs';

const MEDIA_TYPE = 'application/vnd.api+json';
const typeNameByRelName: Record<string, string> = {
  actual_configs: 'host_actual_configs',
  children: 'hosts',
  desired_config: 'host_desired_configs',
  device: 'devices',
  latest_actual_config: 'host_actual_configs',
  parent: 'hosts',
};

@Injectable({
  providedIn: 'root',
})
export class HostService {
  uiBaseUrl = '';
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
  ) {
    this.uiBaseUrl = appConfigService.getJsonApiUrl();
  }

  getHosts(params: HttpParams): Observable<MultiHostData> {
    const url = `${this.uiBaseUrl}/hosts`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<MultiHostData>(url, options);
  }

  getHost(id: string, params: HttpParams): Observable<HostData> {
    const url = `${this.uiBaseUrl}/hosts/` + id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
      params: params,
    };
    return this.http.get<HostData>(url, options);
  }

  addHost(host: Host): Observable<Host> {
    const url = `${this.uiBaseUrl}/hosts`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
    };
    return this.http.post<Host>(url, { data: host }, options);
  }

  updateHost(host: Host): Observable<HostData> {
    const url = `${this.uiBaseUrl}/hosts/` + host.id;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': MEDIA_TYPE,
      }),
    };
    return this.http.patch<HostData>(url, { data: host }, options);
  }

  removeHost(id: string): Observable<never> {
    const url = `${this.uiBaseUrl}/hosts/` + id;
    const options = {
      headers: new HttpHeaders({}),
    };
    return this.http.delete<never>(url, options);
  }
}

import { NavMenuItem } from '@models/menu-item';

export const navMenuItems: NavMenuItem[] = [
  {
    route: '/for_me',
    icon: 'star',
    title: 'For me',
    disabled: true,
  },
  {
    route: '/dashboard',
    icon: 'dashboard',
    title: 'Dashboard',
    disabled: false,
  },
  {
    route: '/devices',
    icon: 'deployed_code',
    title: 'Devices',
    disabled: false,
  },
  {
    route: '/alarms',
    icon: 'notifications',
    title: 'Alarms',
    disabled: false,
  },
  {
    route: '/trends',
    icon: 'monitoring',
    title: 'Trends',
    disabled: true,
  },
  {
    route: '/reports',
    icon: 'analytics',
    title: 'Reports',
    disabled: true,
  },
  {
    route: '/activity',
    icon: 'history',
    title: 'Activity',
    disabled: true,
  },
  {
    route: '/templates',
    icon: 'stacks',
    title: 'Templates',
    disabled: true,
  },
  {
    route: '/settings',
    icon: 'settings',
    title: 'Settings',
    disabled: false,
  },
];

<div [formGroup]="formGroup">
  <div class="mat-form-field-container">
    <p class="input-label">{{ label }}</p>
    <mat-form-field appearance="outline" class="field-input">
      <input matInput [placeholder]="placeholder" [formControlName]="name" id="{{ name }}-ctrl" [type]="type" />
      @if (unit) {
        <span matTextSuffix>{{ unit }}</span>
      }
      @if (formGroup.controls[name].touched && formGroup.controls[name].errors?.required) {
        <mat-error> {{ label }} is required </mat-error>
      }
      @if (formGroup.controls[name].errors?.api) {
        <mat-error>
          {{ formGroup.controls[name].errors?.api?.value }}
        </mat-error>
      }
    </mat-form-field>
  </div>
</div>

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '@shared/services/permissions.service';

@Directive({
  selector: '[check-permissions]',
})
export class CheckPermissionsDirective implements OnInit {
  @Input('check-permissions') permissions!: string[];

  constructor(
    private permissionsService: PermissionsService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit() {
    let shouldRemoveElement = true;

    for (const permission of this.permissions) {
      if (this.permissionsService.checkPermission(permission)) {
        shouldRemoveElement = false;
        break;
      }
    }
    !shouldRemoveElement ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }
}

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base, IncludedRelationship } from '@models/device';
import { AppConfigService } from '@services/app-config.service';

interface Htc920Attributes {
  name?: string;
  serial?: number;
}

export const typeNameByRelName: Record<string, string> = {
  actual_configs: 'htc920_actual_configs',
  desired_config: 'htc920_desired_configs',
  device: 'devices',
  info: 'htc920_infos',
  latest_actual_config: 'htc920_actual_configs',
  latest_state: 'htc920_latest_states',
  states: 'htc920_states',
};

export interface Htc920_actual_configAttributes {
  device_tag: string;
  htc_0_control_temperature_setpoint?: string;
  htc_0_ground_fault_current_high_alarm_setpoint?: string;
  htc_0_ground_fault_current_trip_setpoint?: string;
  htc_0_load_current_high_alarm_setpoint?: string;
  htc_0_load_current_low_alarm_setpoint?: string;
  htc_0_voltage_high_alarm_setpoint?: string;
  htc_0_voltage_low_alarm_setpoint?: string;
  temperature_0_high_alarm_setpoint?: string;
  temperature_0_low_alarm_setpoint?: string;
  temperature_1_high_alarm_setpoint?: string;
  temperature_1_low_alarm_setpoint?: string;
  htc_0_maximum_power_setpoint?: string;
}

export interface Htc920_latest_stateAttributes {
  alarm_0_value?: boolean | null;
  created_at?: string | null;
  temperature_0_value?: string | null;
  temperature_0_value_error?: string | null;
  temperature_1_value?: string | null;
  temperature_1_value_error?: string | null;
  htc_0_control_temperature?: string | null;
  htc_0_control_temperature_error?: string | null;
  htc_0_ground_fault_current?: string | null;
  htc_0_line_voltage?: string | null;
  htc_0_power_consumption_0?: string | null;
  htc_0_load_current?: string | null;
  htc_0_raw_switch_output?: boolean | null;
}

export interface Htc920_latest_stateRelationships {
  Htc920?: { data: Htc920 };
}

export interface Htc920_latest_actual_config extends Base {
  id: string;
  attributes?: Htc920_actual_configAttributes;
}

export interface Htc920_latest_state extends Base {
  id: string;
  attributes?: Htc920_latest_stateAttributes;
  relationships?: Htc920_latest_stateRelationships;
}

export interface Htc920Relationships {
  actual_configs?: { data: Htc920_latest_actual_config[] };
  desired_config?: { data: ResourceIdentifierObject[] };
  device?: { data: unknown };
  info?: { data: unknown };
  latest_actual_config?: { data: Htc920_latest_actual_config };
  latest_state?: { data: Htc920_latest_state };
  states?: { data: unknown[] };
}

export interface Htc920 extends Base {
  id: string;
  type: string;
  attributes?: Htc920Attributes;
  relationships?: Htc920Relationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Htc920Data {
  data: Htc920;
  included?: IncludedRelationship[];
}

export interface Htc920sData {
  data: Htc920[];
  included?: IncludedRelationship[];
}

@Injectable({
  providedIn: 'root',
})
export class Htc920Service {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getHtc920s(params: HttpParams): Observable<Htc920sData> {
    const url = `${this.uiBaseURL}/htc920s`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Htc920sData>(url, options);
  }

  getHtc920(id: string, params: HttpParams): Observable<Htc920Data> {
    const url = `${this.uiBaseURL}/htc920s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Htc920Data>(url, options);
  }

  addHtc920(htc920: Htc920): Observable<Htc920> {
    const url = `${this.uiBaseURL}/htc920s`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.post<Htc920>(url, { data: htc920 }, options);
  }

  updateHtc920(htc920: Htc920): Observable<Htc920Data> {
    const url = `${this.uiBaseURL}/htc920s/` + htc920.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<Htc920Data>(url, { data: htc920 }, options);
  }

  removeHtc920(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/htc920s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.delete<never>(url, options);
  }

  getHtc920TrendData(params: HttpParams, id: string): Observable<Htc920sData> {
    const url = `${this.uiBaseURL}/htc920s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Htc920sData>(url, options);
  }
}

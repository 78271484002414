import { Injectable } from '@angular/core';
import { Cm2000p } from './cm2000p.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cm2000p_desired_config } from './cm2000p_desired_config.service';
import { AppConfigService } from '../app-config.service';
import { Base } from '@app/models/device';

export type Cm2000p_actual_configAttributes = {
  alarm_0_mode?: boolean;
  alarm_0_normal_state?: boolean;
  console_0_temperature_units?: boolean;
  created_at?: string;
  device_reset_mask?: boolean;
  device_reset_priority?: boolean;
  htc_0_atc_master?: boolean;
  htc_0_auto_cycle_enabled?: boolean;
  htc_0_auto_cycle_interval?: string;
  htc_0_auto_cycle_unit?: boolean;
  htc_0_circuit_breaker_0_current_rating?: string;
  htc_0_circuit_breaker_0_limiting_mask?: boolean;
  htc_0_circuit_breaker_0_limiting_priority?: boolean;
  htc_0_communication_failure_mask?: boolean;
  htc_0_communication_failure_priority?: boolean;
  htc_0_contactor_cycle_count_limit_exceeded_mask?: boolean;
  htc_0_contactor_cycle_count_limit_exceeded_priority?: boolean;
  htc_0_contactor_cycle_count_setpoint?: string;
  htc_0_control_temperature_failure_mask?: boolean;
  htc_0_control_temperature_failure_priority?: boolean;
  htc_0_control_temperature_setpoint?: string;
  htc_0_deadband?: string;
  htc_0_external_input_configuration?: string;
  htc_0_external_output_configuration?: string;
  htc_0_ground_fault_current_high_alarm_setpoint?: string;
  htc_0_ground_fault_current_high_mask?: boolean;
  htc_0_ground_fault_current_high_priority?: boolean;
  htc_0_ground_fault_current_trip_setpoint?: string;
  htc_0_ground_fault_trip_mask?: boolean;
  htc_0_ground_fault_trip_priority?: boolean;
  htc_0_line_current_0_over_mask?: boolean;
  htc_0_line_current_0_over_priority?: boolean;
  htc_0_line_voltage_high_mask?: boolean;
  htc_0_line_voltage_high_priority?: boolean;
  htc_0_line_voltage_low_mask?: boolean;
  htc_0_line_voltage_low_priority?: boolean;
  htc_0_load_current_high_alarm_setpoint?: string;
  htc_0_load_current_high_mask?: boolean;
  htc_0_load_current_high_priority?: boolean;
  htc_0_load_current_low_alarm_setpoint?: string;
  htc_0_load_current_low_mask?: boolean;
  htc_0_load_current_low_priority?: boolean;
  htc_0_load_shed_enabled?: boolean;
  htc_0_load_shed_fail_safe_enabled?: boolean;
  htc_0_load_shed_zone_0_enabled?: boolean;
  htc_0_load_shed_zone_1_enabled?: boolean;
  htc_0_load_shed_zone_2_enabled?: boolean;
  htc_0_load_shed_zone_3_enabled?: boolean;
  htc_0_local_alarm_priority_enabled?: boolean;
  htc_0_maximum_power_setpoint?: string;
  htc_0_non_latching_temperature_alarms?: boolean;
  htc_0_power_limiting_mask?: boolean;
  htc_0_power_limiting_priority?: boolean;
  htc_0_proportional_ambient_cycle_time?: string;
  htc_0_proportional_band?: string;
  htc_0_ssr_rating?: string;
  htc_0_switch_0_failure_mask?: boolean;
  htc_0_switch_0_failure_priority?: boolean;
  htc_0_switch_0_limiting_mask?: boolean;
  htc_0_switch_0_limiting_priority?: boolean;
  htc_0_switch_control_mode?: string;
  htc_0_temperature_control_mode?: string;
  htc_0_temperature_fail_mode?: boolean;
  htc_0_voltage_high_alarm_setpoint?: string;
  htc_0_voltage_low_alarm_setpoint?: string;
  nvram_0_total_failure_mask?: boolean;
  nvram_0_total_failure_priority?: boolean;
  temperature_0_configuration?: string;
  temperature_0_failure_mask?: boolean;
  temperature_0_failure_priority?: boolean;
  temperature_0_high_alarm_filter?: string;
  temperature_0_high_alarm_setpoint?: string;
  temperature_0_high_mask?: boolean;
  temperature_0_high_priority?: boolean;
  temperature_0_low_alarm_filter?: string;
  temperature_0_low_alarm_setpoint?: string;
  temperature_0_low_mask?: boolean;
  temperature_0_low_priority?: boolean;
  temperature_1_configuration?: string;
  temperature_1_failure_mask?: boolean;
  temperature_1_failure_priority?: boolean;
  temperature_1_high_alarm_setpoint?: string;
  temperature_1_high_mask?: boolean;
  temperature_1_high_priority?: boolean;
  temperature_1_low_alarm_setpoint?: string;
  temperature_1_low_mask?: boolean;
  temperature_1_low_priority?: boolean;
  config_id?: string;
  desired_config_id?: string;
};

interface Cm2000p_actual_configRelationships {
  bases?: { data: Cm2000p_desired_config[] };
  complete?: { data: Cm2000p_desired_config[] };
  cm2000p?: { data: Cm2000p };
  latest?: { data: Cm2000p };
}

export interface Cm2000p_actual_config extends Base {
  id: string;
  attributes?: Cm2000p_actual_configAttributes;
  relationships?: Cm2000p_actual_configRelationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Cm2000p_actual_configData {
  data: Cm2000p_actual_config;
  included?: ResourceIdentifierObject[];
}

// TODO {.Plural | pascal}
export interface Cm2000p_actual_configsData {
  data: Cm2000p_actual_config[];
  included?: ResourceIdentifierObject[];
}

@Injectable({
  providedIn: 'root',
})
export class Cm2000p_actual_configService {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }
  getCm2000p_actual_configs(params: HttpParams): Observable<Cm2000p_actual_configsData> {
    const url = `${this.uiBaseURL}/cm2000p_actual_configs`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Cm2000p_actual_configsData>(url, options);
  }

  getCm2000p_actual_config(id: string, params: HttpParams): Observable<Cm2000p_actual_configData> {
    const url = `${this.uiBaseURL}/cm2000p_actual_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Cm2000p_actual_configData>(url, options);
  }

  addCm2000p_actual_config(cm2000p_actual_config: Cm2000p_actual_config): Observable<Cm2000p_actual_config> {
    const url = `${this.uiBaseURL}/cm2000p_actual_configs`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.post<Cm2000p_actual_config>(url, { data: cm2000p_actual_config }, options);
  }

  updateCm2000p_actual_config(cm2000p_actual_config: Cm2000p_actual_config): Observable<Cm2000p_actual_configData> {
    const url = `${this.uiBaseURL}/cm2000p_actual_configs/` + cm2000p_actual_config.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<Cm2000p_actual_configData>(url, { data: cm2000p_actual_config }, options);
  }

  removeCm2000p_actual_config(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/cm2000p_actual_configs/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.delete<never>(url, options);
  }
}

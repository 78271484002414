import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatListOptionCheckboxPosition } from '@angular/material/list';
import { Router } from '@angular/router';
import { TrendSelectionCategories } from '@app/models/trend-selection';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'app-trend-selection',
  templateUrl: './trend-selection.component.html',
  styleUrl: './trend-selection.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class TrendSelectionComponent implements OnInit {
  checkboxPosition: MatListOptionCheckboxPosition = 'before';
  @Input() data!: TrendSelectionCategories;
  @Output() closeTrendSelection = new EventEmitter();
  @Output() saveSelectedCategory = new EventEmitter<TrendSelectionCategories>();
  constructor(@Inject(Router) private router: Router) {}
  keyOfTrendSelection!: string[];
  ngOnInit() {
    this.data = cloneDeep(this.data);
    this.keyOfTrendSelection = Object.keys(this.data);
  }

  getTrendDialogSubtitles(): string[] {
    if (this.router.url.includes('trends')) {
      return ['Temperature Attributes', 'Current Attributes', 'Power Attributes'];
    } else {
      return ['Plot 1', 'Plot 2', 'Plot 3'];
    }
  }

  getButtonStatus(): boolean {
    for (const chart in this.data) {
      if (this.data[chart as keyof TrendSelectionCategories]) {
        for (const plotData of this.data[chart as keyof TrendSelectionCategories]!) {
          if (plotData.plotted) return false;
        }
      }
    }
    return true;
  }

  onSave() {
    this.saveSelectedCategory.emit({ ...this.data });
  }

  closeTrendSelectionDialog() {
    this.closeTrendSelection.emit();
  }

  getPlotOptions(plotCategories: string) {
    return this.data[plotCategories as keyof TrendSelectionCategories]?.map(plotOptions => plotOptions);
  }
}

@if (modal.show) {
  <div class="dialog absolute-center" id="container">
    <div class="dialog-body">
      <div class="filter-group">
        <label id="msg">
          {{ modal.message }}
          @if (modal.additionalMessage) {
            <br />
            {{ modal.additionalMessage }}
          }
        </label>
      </div>
    </div>
    <div class="dialog-footer">
      <button (click)="modal.show = false" id="cancel">Cancel</button>
      <button color="accent" (click)="onConfirmation()" id="ok-btn">
        {{ modal.confirmationButtonText }}
      </button>
    </div>
  </div>
  @if (modal.show) {
    <div class="model-overlay" (click)="modal.show = false"></div>
  }
}

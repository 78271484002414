export const alarmDescriptionMapping: any = {
  htc_0_communication_failure: 'Communications Failure',
  temperature_0_high: 'High TS 1 Temperature',
  temperature_0_low: 'Low TS 1 Temperature',
  temperature_0_failure: 'TS 1 Failure',
  temperature_1_high: 'High TS 2 Temperature',
  temperature_1_low: 'Low TS 2 Temperature',
  temperature_1_failure: 'TS 2 Failure',
  htc_0_control_temperature_failure: 'Control TS Failure',
  htc_0_load_current_high: 'High Load Current',
  htc_0_load_current_low: 'Low Load Current',
  htc_0_ground_fault_current_high: 'High GF Current',
  htc_0_ground_fault_trip: 'GF Current Trip',
  htc_0_line_voltage_high: 'High Voltage',
  htc_0_line_voltage_low: 'Low Voltage',
  htc_0_line_current_0_over: 'Overcurrent Trip',
  htc_0_switch_0_failure: 'Switch Failure',
  device_reset: 'HTC/Device/Controller Reset',
  htc_0_power_limiting: 'Power Limiting',
  htc_0_circuit_breaker_0_limiting: 'Circuit Breaker Limiting',
  htc_0_switch_0_limiting: 'Switch Limiting',
  htc_0_tracing_resistance_0_high: 'High Trace Resistance',
  htc_0_tracing_resistance_0_low: 'Low Trace Resistance',
  nvram_0_total_failure: 'EEROM Data Failure',
  htc_0_contactor_cycle_count_limit_exceeded: 'Contactor Cycle Count',
  nvram_0_not_responding: 'Serial EEROM Not Responding',
  nvram_0_user_configuration_data_lost: 'User Configuration Data Lost or reset to defaults',
  htc_0_control_temperature_high: 'Control Temperature High',
  htc_0_control_temperature_low: 'Control Temperature Low',
  htc_0_temperature_source_0_failure: 'Temperature Source 1 Failure',
  htc_0_temperature_source_1_failure: 'Temperature Source 2 Failure',
  htc_0_temperature_source_2_failure: 'Temperature Source 3 Failure',
  htc_0_temperature_source_3_failure: 'Temperature Source 4 Failure',
  htc_0_temperature_source_4_failure: 'Temperature Source 5 Failure',
  htc_0_temperature_source_5_failure: 'Temperature Source 6 Failure',
  htc_0_temperature_source_6_failure: 'Temperature Source 7 Failure',
  htc_0_temperature_source_7_failure: 'Temperature Source 8 Failure',
  htc_0_line_current_0_high: 'High Line 1 Current',
  htc_0_line_current_0_low: 'Low Line 1 Current',
  htc_0_ground_fault_transformer_failure: 'GFI Current Transformer Failure',
  htc_0_output_0_limiting: 'Output 1 Limiting',
  digital_input_source_0_alarm: 'Digital Input',
  digital_input_source_0_failure: 'Digital input Local Source Failure',
  nvram_0_factory_configuration_data_lost: 'Factory Configuration Data Lost',
  htc_0_load_shed_source_failure: 'Load Shed Source Failure',
  htc_0_high_limit_cutout: 'High Limit Cut-Out',
  htc_0_heater_time_limit_exceeded: 'Heater On Time',
  limiter_0_trip: 'Safety Temperature Limiter Trip',
  limiter_0_communication_failure: 'Limiter Communication Failure',
  htc_0_line_current_1_high: 'High Line 2 Current',
  htc_0_line_current_2_high: 'High Line 3 Current',
  htc_0_line_current_1_low: 'Low Line 2 Current',
  htc_0_line_current_2_low: 'Low Line 3 Current',
  htc_0_line_current_1_over: 'Over Current Trip 2',
  htc_0_line_current_2_over: 'Over Current Trip 3',
  htc_0_switch_1_failure: 'Switch 2 Failure',
  htc_0_switch_2_failure: 'Switch 3 Failure',
  htc_0_output_1_limiting: 'Output 2 Limiting',
  htc_0_output_2_limiting: 'Output 3 Limiting',
  htc_0_circuit_breaker_1_limiting: 'Circuit Breaker 2 Limiting',
  htc_0_circuit_breaker_2_limiting: 'Circuit Breaker 3 Limiting',
  temperature_2_high: 'Temperature Source 3 High',
  temperature_2_low: 'Temperature Source 3 Low',
  temperature_2_failure: 'Temperature Source 3 Failure',
  temperature_3_high: 'Temperature Source 4 High',
  temperature_3_low: 'Temperature Source 4 Low',
  temperature_3_failure: 'Temperature Source 4 Failure',
  alarm_source_0_failure: 'Alarm Source 1 Failure',
  alarm_source_1_failure: 'Alarm Source 2 Failure',
  alarm_source_2_failure: 'Alarm Source 3 Failure',
  alarm_source_3_failure: 'Alarm Source 4 Failure',
  alarm_source_4_failure: 'Alarm Source 5 Failure',
  alarm_source_5_failure: 'Alarm Source 6 Failure',
  alarm_source_6_failure: 'Alarm Source 7 Failure',
  alarm_source_7_failure: 'Alarm Source 8 Failure',
  alarm_source_8_failure: 'Alarm Source 9 Failure',
  alarm_source_9_failure: 'Alarm Source 10 Failure',
  alarm_source_10_failure: 'Alarm Source 11 Failure',
  alarm_source_11_failure: 'Alarm Source 12 Failure',
  alarm_source_12_failure: 'Alarm Source 13 Failure',
  alarm_source_13_failure: 'Alarm Source 14 Failure',
  alarm_source_14_failure: 'Alarm Source 15 Failure',
  alarm_source_15_failure: 'Alarm Source 16 Failure',
  alarm_source_16_failure: 'Alarm Source 17 Failure',
  alarm_source_17_failure: 'Alarm Source 18 Failure',
  alarm_source_18_failure: 'Alarm Source 19 Failure',
  alarm_source_19_failure: 'Alarm Source 20 Failure',
  alarm_source_20_failure: 'Alarm Source 21 Failure',
  alarm_source_21_failure: 'Alarm Source 22 Failure',
  alarm_source_22_failure: 'Alarm Source 23 Failure',
  alarm_source_23_failure: 'Alarm Source 24 Failure',
  alarm_source_24_failure: 'Alarm Source 25 Failure',
  alarm_source_25_failure: 'Alarm Source 26 Failure',
  alarm_source_26_failure: 'Alarm Source 27 Failure',
  alarm_source_27_failure: 'Alarm Source 28 Failure',
  alarm_source_28_failure: 'Alarm Source 29 Failure',
  alarm_source_29_failure: 'Alarm Source 30 Failure',
  alarm_source_30_failure: 'Alarm Source 31 Failure',
  alarm_source_31_failure: 'Alarm Source 32 Failure',
  alarm_source_32_failure: 'Alarm Source 33 Failure',
  alarm_source_33_failure: 'Alarm Source 34 Failure',
  alarm_source_34_failure: 'Alarm Source 35 Failure',
  alarm_source_35_failure: 'Alarm Source 36 Failure',
  alarm_source_36_failure: 'Alarm Source 37 Failure',
  alarm_source_37_failure: 'Alarm Source 38 Failure',
  alarm_source_38_failure: 'Alarm Source 39 Failure',
  alarm_source_39_failure: 'Alarm Source 40 Failure',
  alarm_source_40_failure: 'Alarm Source 41 Failure',
  alarm_source_41_failure: 'Alarm Source 42 Failure',
  alarm_source_42_failure: 'Alarm Source 43 Failure',
  alarm_source_43_failure: 'Alarm Source 44 Failure',
  alarm_source_44_failure: 'Alarm Source 45 Failure',
  alarm_source_45_failure: 'Alarm Source 46 Failure',
  alarm_source_46_failure: 'Alarm Source 47 Failure',
  alarm_source_47_failure: 'Alarm Source 48 Failure',
  alarm_source_48_failure: 'Alarm Source 49 Failure',
  alarm_source_49_failure: 'Alarm Source 50 Failure',
  alarm_source_50_failure: 'Alarm Source 51 Failure',
  alarm_source_51_failure: 'Alarm Source 52 Failure',
  alarm_source_52_failure: 'Alarm Source 53 Failure',
  alarm_source_53_failure: 'Alarm Source 54 Failure',
  alarm_source_54_failure: 'Alarm Source 55 Failure',
  alarm_source_55_failure: 'Alarm Source 56 Failure',
  alarm_source_56_failure: 'Alarm Source 57 Failure',
  alarm_source_57_failure: 'Alarm Source 58 Failure',
  alarm_source_58_failure: 'Alarm Source 59 Failure',
  alarm_source_59_failure: 'Alarm Source 60 Failure',
  alarm_source_60_failure: 'Alarm Source 61 Failure',
  alarm_source_61_failure: 'Alarm Source 62 Failure',
  alarm_source_62_failure: 'Alarm Source 63 Failure',
  alarm_source_63_failure: 'Alarm Source 64 Failure',
  alarm_source_64_failure: 'Alarm Source 65 Failure',
  alarm_source_65_failure: 'Alarm Source 66 Failure',
  alarm_source_66_failure: 'Alarm Source 67 Failure',
  alarm_source_67_failure: 'Alarm Source 68 Failure',
  alarm_source_68_failure: 'Alarm Source 69 Failure',
  alarm_source_69_failure: 'Alarm Source 70 Failure',
  alarm_source_70_failure: 'Alarm Source 71 Failure',
  alarm_source_71_failure: 'Alarm Source 72 Failure',
  alarm_source_72_failure: 'Alarm Source 73 Failure',
  alarm_source_73_failure: 'Alarm Source 74 Failure',
  alarm_source_74_failure: 'Alarm Source 75 Failure',
  alarm_source_75_failure: 'Alarm Source 76 Failure',
  alarm_source_76_failure: 'Alarm Source 77 Failure',
  alarm_source_77_failure: 'Alarm Source 78 Failure',
  alarm_source_78_failure: 'Alarm Source 79 Failure',
  alarm_source_79_failure: 'Alarm Source 80 Failure',
  digital_input_0_alarm: 'Digital Input',
  limiter_0_contactor_cycle_count_limit_exceeded: 'Contactor Cycle Count',
  serial_0_hardware_failure: 'Alarm Queue 80% Full',
  serial_0_carrier_failed: 'Alarm Queue Full',
  serial_0_offline: 'Alarm Source 1 Failure',
  back_serial_0_hardware_failure: 'Alarm Source 10 Failure',
  back_serial_0_carrier_failed: 'Alarm Source 11 Failure',
  back_serial_0_offline: 'Alarm Source 12 Failure',
  serial_0_client: 'Alarm Source 13 Failure',
  back_serial_0_client: 'Alarm Source 14 Failure',
  serial_0_modbus_address: 'Alarm Source 15 Failure',
  serial_0_modbus_sub_address: 'Alarm Source 16 Failure',
  serial_0_address: 'Alarm Source 17 Failure',
  serial_0_baud_rate: 'Alarm Source 18 Failure',
  serial_0_data_bits: 'Alarm Source 19 Failure',
  serial_0_stop_bits: 'Alarm Source 2 Failure',
  back_serial_0_modbus_address: 'Alarm Source 20 Failure',
  back_serial_0_modbus_sub_address: 'Alarm Source 21 Failure',
  back_serial_0_address: 'Alarm Source 22 Failure',
  back_serial_0_baud_rate: 'Alarm Source 23 Failure',
  back_serial_0_data_bits: 'Alarm Source 24 Failure',
  back_serial_0_stop_bits: 'Alarm Source 25 Failure',
  serial_0_port: 'Alarm Source 26 Failure',
  serial_0_line: 'Alarm Source 27 Failure',
  serial_0_path: 'Alarm Source 28 Failure',
  serial_0_bypass: 'Alarm Source 29 Failure',
  back_serial_0_port: 'Alarm Source 3 Failure',
  back_serial_0_line: 'Alarm Source 30 Failure',
  back_serial_0_path: 'Alarm Source 31 Failure',
  back_serial_0_bypass: 'Alarm Source 32 Failure',
  serial_0_parity: 'Alarm Source 33 Failure',
  serial_0_protocol: 'Alarm Source 34 Failure',
  serial_0_frame_type: 'Alarm Source 35 Failure',
  serial_0_communications_driver: 'Alarm Source 36 Failure',
  serial_0_communications_profile: 'Alarm Source 37 Failure',
  back_serial_0_parity: 'Alarm Source 38 Failure',
  back_serial_0_protocol: 'Alarm Source 39 Failure',
  back_serial_0_frame_type: 'Alarm Source 4 Failure',
  back_serial_0_communications_driver: 'Alarm Source 40 Failure',
  back_serial_0_communications_profile: 'Alarm Source 41 Failure',
  serial_0_tx_delay: 'Alarm Source 42 Failure',
  back_serial_0_tx_delay: 'Alarm Source 43 Failure',
  serial_0_hardware_failure_mask: 'Alarm Source 44 Failure',
  serial_0_carrier_failed_mask: 'Alarm Source 45 Failure',
  serial_0_offline_mask: 'Alarm Source 46 Failure',
  back_serial_0_hardware_failure_mask: 'Alarm Source 47 Failure',
  back_serial_0_carrier_failed_mask: 'Alarm Source 48 Failure',
  back_serial_0_offline_mask: 'Alarm Source 49 Failure',
  serial_0_hardware_failure_latch: 'Alarm Source 5 Failure',
  serial_0_carrier_failed_latch: 'Alarm Source 50 Failure',
  serial_0_offline_latch: 'Alarm Source 51 Failure',
  back_serial_0_hardware_failure_latch: 'Alarm Source 52 Failure',
  back_serial_0_carrier_failed_latch: 'Alarm Source 53 Failure',
  back_serial_0_offline_latch: 'Alarm Source 54 Failure',
  serial_0_hardware_failure_priority: 'Alarm Source 55 Failure',
  serial_0_carrier_failed_priority: 'Alarm Source 56 Failure',
  serial_0_offline_priority: 'Alarm Source 57 Failure',
  back_serial_0_hardware_failure_priority: 'Alarm Source 58 Failure',
  back_serial_0_carrier_failed_priority: 'Alarm Source 59 Failure',
  back_serial_0_offline_priority: 'Alarm Source 6 Failure',
  serial_1_hardware_failure: 'Alarm Source 60 Failure',
  serial_1_carrier_failed: 'Alarm Source 61 Failure',
  serial_1_offline: 'Alarm Source 62 Failure',
  back_serial_1_hardware_failure: 'Alarm Source 63 Failure',
  back_serial_1_carrier_failed: 'Alarm Source 64 Failure',
  back_serial_1_offline: 'Alarm Source 65 Failure',
  serial_1_client: 'Alarm Source 66 Failure',
  back_serial_1_client: 'Alarm Source 67 Failure',
  serial_1_modbus_address: 'Alarm Source 68 Failure',
  serial_1_modbus_sub_address: 'Alarm Source 69 Failure',
  serial_1_address: 'Alarm Source 7 Failure',
  serial_1_baud_rate: 'Alarm Source 70 Failure',
  serial_1_data_bits: 'Alarm Source 71 Failure',
  serial_1_stop_bits: 'Alarm Source 72 Failure',
  back_serial_1_modbus_address: 'Alarm Source 73 Failure',
  back_serial_1_modbus_sub_address: 'Alarm Source 74 Failure',
  back_serial_1_address: 'Alarm Source 75 Failure',
  back_serial_1_baud_rate: 'Alarm Source 76 Failure',
  back_serial_1_data_bits: 'Alarm Source 77 Failure',
  back_serial_1_stop_bits: 'Alarm Source 78 Failure',
  serial_1_port: 'Alarm Source 79 Failure',
  serial_1_line: 'Alarm Source 8 Failure',
  serial_1_path: 'Alarm Source 80 Failure',
  serial_1_bypass: 'Alarm Source 9 Failure',
  back_serial_1_port: 'Circuit Breaker Limiting (L1)',
  back_serial_1_line: 'Circuit Breaker Limiting (L1)',
  back_serial_1_path: 'Circuit Breaker Limiting (L2)',
  back_serial_1_bypass: 'Circuit Breaker Limiting (L2)',
  serial_1_parity: 'Circuit Breaker Limiting (L3)',
  serial_1_protocol: 'Circuit Breaker Limiting (L3)',
  serial_1_frame_type: 'Communications Failure',
  serial_1_communications_driver: 'Communications Failure',
  serial_1_communications_profile: 'Contact Input #1 Alarm',
  back_serial_1_parity: 'Contact Input #2 Alarm',
  back_serial_1_protocol: 'Contact Input #3 Alarm',
  back_serial_1_frame_type: 'Contact Input #4 Alarm',
  back_serial_1_communications_driver: 'Contactor Cycle Count',
  back_serial_1_communications_profile: 'Contactor Cycle Count',
  serial_1_tx_delay: 'Control Temperature Failure',
  back_serial_1_tx_delay: 'Control Temperature Failure',
  serial_1_hardware_failure_mask: 'Control Temperature Failure',
  serial_1_carrier_failed_mask: 'Control Temperature High',
  serial_1_offline_mask: 'Control Temperature High',
  back_serial_1_hardware_failure_mask: 'Control Temperature Low',
  back_serial_1_carrier_failed_mask: 'Control Temperature Low',
  back_serial_1_offline_mask: 'Device Reset',
  serial_1_hardware_failure_latch: 'Factory Configuration Data Lost',
  serial_1_carrier_failed_latch: 'GFI Current Transformer Failure',
  serial_1_offline_latch: 'GFI Current Transformer Failure',
  back_serial_1_hardware_failure_latch: 'Ground Fault Trip',
  back_serial_1_carrier_failed_latch: 'Ground Fault Trip',
  back_serial_1_offline_latch: 'Heater On Time',
  serial_1_hardware_failure_priority: 'Heater On Time',
  serial_1_carrier_failed_priority: 'High Ground Fault Current',
  serial_1_offline_priority: 'High Ground Fault Current',
  back_serial_1_hardware_failure_priority: 'High Limit Cut-Out',
  back_serial_1_carrier_failed_priority: 'High Limit Cut-Out',
  back_serial_1_offline_priority: 'High Line Current (L1)',
  serial_2_hardware_failure: 'High Line Current (L1)',
  serial_2_carrier_failed: 'High Line Current (L2)',
  serial_2_offline: 'High Line Current (L2)',
  back_serial_2_hardware_failure: 'High Line Current (L3)',
  back_serial_2_carrier_failed: 'High Line Current (L3)',
  back_serial_2_offline: 'High Line Voltage',
  serial_2_client: 'High Line Voltage',
  back_serial_2_client: 'High Load Current',
  serial_2_modbus_address: 'High Load Current',
  serial_2_modbus_sub_address: 'High Tracing Resistance (L1)',
  serial_2_address: 'High Tracing Resistance (L1)',
  serial_2_baud_rate: 'High Tracing Resistance (L2)',
  serial_2_data_bits: 'High Tracing Resistance (L2)',
  serial_2_stop_bits: 'High Tracing Resistance (L3)',
  back_serial_2_modbus_address: 'High Tracing Resistance (L3)',
  back_serial_2_modbus_sub_address: 'HTC Communication Failure',
  back_serial_2_address: 'HTC Communication Failure',
  back_serial_2_baud_rate: 'Load Shed Source Failure',
  back_serial_2_data_bits: 'Load Shed Source Failure',
  back_serial_2_stop_bits: 'Load Shed Source Failure',
  serial_2_port: 'Load Shed Zone #1 Alarm',
  serial_2_line: 'Load Shed Zone #2 Alarm',
  serial_2_path: 'Load Shed Zone #3 Alarm',
  serial_2_bypass: 'Load Shed Zone #4 Alarm',
  back_serial_2_port: 'Low Limit Cut-Out',
  back_serial_2_line: 'Low Limit Cut-Out',
  back_serial_2_path: 'Low Line Current (L1)',
  back_serial_2_bypass: 'Low Line Current (L1)',
  serial_2_parity: 'Low Line Current (L2)',
  serial_2_protocol: 'Low Line Current (L2)',
  serial_2_frame_type: 'Low Line Current (L3)',
  serial_2_communications_driver: 'Low Line Current (L3)',
  serial_2_communications_profile: 'Low Line Voltage',
  back_serial_2_parity: 'Low Line Voltage',
  back_serial_2_protocol: 'Low Load Current',
  back_serial_2_frame_type: 'Low Load Current',
  back_serial_2_communications_driver: 'Low Tracing Resistance (L1)',
  back_serial_2_communications_profile: 'Low Tracing Resistance (L1)',
  serial_2_tx_delay: 'Low Tracing Resistance (L2)',
  back_serial_2_tx_delay: 'Low Tracing Resistance (L2)',
  serial_2_hardware_failure_mask: 'Low Tracing Resistance (L3)',
  serial_2_carrier_failed_mask: 'Low Tracing Resistance (L3)',
  serial_2_offline_mask: 'Output Limiting (L1)',
  back_serial_2_hardware_failure_mask: 'Output Limiting (L1)',
  back_serial_2_carrier_failed_mask: 'Output Limiting (L2)',
  back_serial_2_offline_mask: 'Output Limiting (L2)',
  serial_2_hardware_failure_latch: 'Output Limiting (L3)',
  serial_2_carrier_failed_latch: 'Output Limiting (L3)',
  serial_2_offline_latch: 'Port A HTC Carrier Failed On',
  back_serial_2_hardware_failure_latch: 'Port A Off-line',
  back_serial_2_carrier_failed_latch: 'Port B HTC Carrier Failed On',
  back_serial_2_offline_latch: 'Port B Off-line',
  serial_2_hardware_failure_priority: 'Port C HTC Carrier Failed On',
  serial_2_carrier_failed_priority: 'Port C Off-line',
  serial_2_offline_priority: 'Port D HTC Carrier Failed On',
  back_serial_2_hardware_failure_priority: 'Port D Off-line',
  back_serial_2_carrier_failed_priority: 'Printer Error',
  back_serial_2_offline_priority: 'Printer Off-line',
  serial_3_hardware_failure: 'Printer Paper Out',
  serial_3_carrier_failed: 'Protection Trip',
  serial_3_offline: 'Protection Trip',
  back_serial_3_hardware_failure: 'Safety Temperature Limiter Trip',
  back_serial_3_carrier_failed: 'SSR Over Current',
  back_serial_3_offline: 'SSR Over Current',
  serial_3_client: 'SSR Over Current (L1)',
  back_serial_3_client: 'SSR Over Current (L1)',
  serial_3_modbus_address: 'SSR Over Current (L2)',
  serial_3_modbus_sub_address: 'SSR Over Current (L2)',
  serial_3_address: 'SSR Over Current (L3)',
  serial_3_baud_rate: 'SSR Over Current (L3)',
  serial_3_data_bits: 'Switch Failure (L1)',
  serial_3_stop_bits: 'Switch Failure (L1)',
  back_serial_3_modbus_address: 'Switch Failure (L2)',
  back_serial_3_modbus_sub_address: 'Switch Failure (L2)',
  back_serial_3_address: 'Switch Failure (L3)',
  back_serial_3_baud_rate: 'Switch Failure (L3)',
  back_serial_3_data_bits: 'Switch Limiting (L1)',
  back_serial_3_stop_bits: 'Switch Limiting (L1)',
  serial_3_port: 'Switch Limiting (L2)',
  serial_3_line: 'Switch Limiting (L2)',
  serial_3_path: 'Switch Limiting (L3)',
  serial_3_bypass: 'Switch Limiting (L3)',
  back_serial_3_port: 'Temperature Sensor 1 Failure',
  back_serial_3_line: 'Temperature Sensor 1 High',
  back_serial_3_path: 'Temperature Sensor 1 Low',
  back_serial_3_bypass: 'Temperature Sensor 2 Failure',
  serial_3_parity: 'Temperature Sensor 2 High',
  serial_3_protocol: 'Temperature Sensor 2 Low',
  serial_3_frame_type: 'Temperature Sensor 3 Failure',
  serial_3_communications_driver: 'Temperature Sensor 3 High',
  serial_3_communications_profile: 'Temperature Sensor 3 Low',
  back_serial_3_parity: 'Temperature Sensor 4 Failure',
  back_serial_3_protocol: 'Temperature Sensor 4 High',
  back_serial_3_frame_type: 'Temperature Sensor 4 Low',
  back_serial_3_communications_driver: 'Temperature Sensor Failure',
  back_serial_3_communications_profile: 'Temperature Sensor Failure',
  serial_3_tx_delay: 'Temperature Source 1 Failure',
  back_serial_3_tx_delay: 'Temperature Source 1 Failure',
  serial_3_hardware_failure_mask: 'Temperature Source 2 Failure',
  serial_3_carrier_failed_mask: 'Temperature Source 2 Failure',
  serial_3_offline_mask: 'Temperature Source 3 Failure',
  back_serial_3_hardware_failure_mask: 'Temperature Source 3 Failure',
  back_serial_3_carrier_failed_mask: 'Temperature Source 4 Failure',
  back_serial_3_offline_mask: 'Temperature Source 4 Failure',
  serial_3_hardware_failure_latch: 'Temperature Source 5 Failure',
  serial_3_carrier_failed_latch: 'Temperature Source 5 Failure',
  serial_3_offline_latch: 'Temperature Source 6 Failure',
  back_serial_3_hardware_failure_latch: 'Temperature Source 6 Failure',
  back_serial_3_carrier_failed_latch: 'Temperature Source 7 Failure',
  back_serial_3_offline_latch: 'Temperature Source 7 Failure',
  serial_3_hardware_failure_priority: 'Temperature Source 8 Failure',
  serial_3_carrier_failed_priority: 'Temperature Source 8 Failure',
  serial_3_offline_priority: 'TOD Chip Battery Dead',
  back_serial_3_hardware_failure_priority: 'User Configuration Data Lost',
};

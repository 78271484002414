<div class="flex align-items-center trend-duration-container">
  <div class="trend-options-container">
    <button class="round-edge-button btn-black" (click)="toggleTrendSelectionOverlay()">
      <div class="cl-light flex-center font-med">
        <span>Trend Data Point Selection</span>
      </div>
    </button>
  </div>
  <div class="button-row">
    @for (duration of durationOptions; track durationOptions) {
      <button
        mat-stroked-button
        [ngClass]="getActiveClass(duration) ? 'active' : ''"
        [disabled]="getActiveClass(duration)"
        data-testid="toggleButton"
        value="{{ duration }}"
        (click)="handleSelectDuration(duration)">
        {{ duration }}
      </button>
    }
  </div>

  <div class="select-range-container" role="matRangePicker">
    <mat-form-field>
      <mat-label id="dateLabel">Enter a date range</mat-label>
      @if (error) {
        <mat-error class="date-error" id="dateError">{{ error }}</mat-error>
      }
      <mat-date-range-input [formGroup]="rangeDuration" [max]="maxDate" [rangePicker]="rangePicker">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle data-testid="calender" matSuffix [for]="rangePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #rangePicker> </mat-date-range-picker>
    </mat-form-field>
  </div>
</div>

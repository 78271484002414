<ng-template #headerTemplate>
  <div class="title-container">
    <div class="flex">
      @if (iconName) {
        <div class="material-symbols-outlined title-icon">{{ iconName }}</div>
      }
      <div class="overlay-title" data-cy="title">{{ title }}</div>
    </div>
    @if (showCloseBtn) {
      <button class="close-btn" (click)="onClose()">
        <span class="material-symbols-outlined close-icon">close</span>
      </button>
    }
  </div>
</ng-template>
<ng-template #bodyTemplate>
  <div class="body">
    <ng-content select="[body]"></ng-content>
  </div>
</ng-template>
<ng-template #footerTemplate>
  <div class="footer-options flex-between">
    <ng-content select="[footer]"></ng-content>
  </div>
</ng-template>
<div *blockUI="'overlay-block'" class="overlay-body absolute-center flex-between col-flex">
  @if (title) {
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  }
  <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
</div>
<div class="overlay"></div>

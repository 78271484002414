import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base, IncludedRelationship } from '@models/device';
import { AppConfigService } from '@services/app-config.service';

interface Cm2000Attributes {
  name?: string;
  serial?: number;
}

export const typeNameByRelName: Record<string, string> = {
  actual_configs: 'cm2000_actual_configs',
  desired_config: 'cm2000_desired_configs',
  device: 'devices',
  info: 'cm2000_infos',
  latest_actual_config: 'cm2000_actual_configs',
  latest_state: 'cm2000_latest_states',
  states: 'cm2000_states',
};

export interface Cm2000_actual_configAttributes {
  htc_0_control_temperature_setpoint?: string;
  htc_0_ground_fault_current_high_alarm_setpoint?: string;
  htc_0_ground_fault_current_trip_setpoint?: string;
  htc_0_load_current_high_alarm_setpoint?: string;
  htc_0_load_current_low_alarm_setpoint?: string;
  htc_0_voltage_high_alarm_setpoint?: string;
  htc_0_voltage_low_alarm_setpoint?: string;
  temperature_0_high_alarm_setpoint?: string;
  temperature_0_low_alarm_setpoint?: string;
  temperature_1_high_alarm_setpoint?: string;
  temperature_1_low_alarm_setpoint?: string;
  htc_0_maximum_power_setpoint?: string;
}

export interface Cm2000_latest_stateAttributes {
  created_at?: string | null;
  temperature_0_value?: string | null;
  temperature_0_value_error?: string | null;
  temperature_1_value?: string | null;
  temperature_1_value_error?: string | null;
  htc_0_control_temperature?: string | null;
  htc_0_control_temperature_error?: string | null;
  htc_0_ground_fault_current?: string | null;
  htc_0_line_voltage?: string | null;
  htc_0_power_consumption_0?: string | null;
  htc_0_load_current?: string | null;
}

export interface Cm2000_latest_stateRelationships {
  Cm2000?: { data: Cm2000 };
}

export interface Cm2000_latest_actual_config extends Base {
  id: string;
  attributes?: Cm2000_actual_configAttributes;
}

export interface Cm2000_latest_state extends Base {
  id: string;
  attributes?: Cm2000_latest_stateAttributes;
  relationships?: Cm2000_latest_stateRelationships;
}

export interface Cm2000Relationships {
  actual_configs?: { data: Cm2000_latest_actual_config[] };
  desired_config?: { data: ResourceIdentifierObject[] };
  device?: { data: unknown };
  info?: { data: unknown };
  latest_actual_config?: { data: Cm2000_latest_actual_config };
  latest_state?: { data: Cm2000_latest_state };
  states?: { data: unknown[] };
}

export interface Cm2000 extends Base {
  id: string;
  type: string;
  attributes?: Cm2000Attributes;
  relationships?: Cm2000Relationships;
}

export interface ResourceIdentifierObject {
  type: string;
  id: string;
}

export interface Cm2000Data {
  data: Cm2000;
  included?: IncludedRelationship[];
}

export interface Cm2000sData {
  data: Cm2000[];
  included?: IncludedRelationship[];
}

@Injectable({
  providedIn: 'root',
})
export class Cm2000Service {
  uiBaseURL = '';
  constructor(
    private http: HttpClient,
    appConfigService: AppConfigService,
  ) {
    this.uiBaseURL = appConfigService.getJsonApiUrl();
  }

  getCm2000s(params: HttpParams): Observable<Cm2000sData> {
    const url = `${this.uiBaseURL}/cm2000s`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Cm2000sData>(url, options);
  }

  getCm2000(id: string, params: HttpParams): Observable<Cm2000Data> {
    const url = `${this.uiBaseURL}/cm2000s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Cm2000Data>(url, options);
  }

  addCm2000(cm2000: Cm2000): Observable<Cm2000> {
    const url = `${this.uiBaseURL}/cm2000s`;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        'Content-Type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.post<Cm2000>(url, { data: cm2000 }, options);
  }

  updateCm2000(cm2000: Cm2000): Observable<Cm2000Data> {
    const url = `${this.uiBaseURL}/cm2000s/` + cm2000.id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.patch<Cm2000Data>(url, { data: cm2000 }, options);
  }

  removeCm2000(id: string): Observable<never> {
    const url = `${this.uiBaseURL}/cm2000s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
    };
    return this.http.delete<never>(url, options);
  }

  getCm2000TrendData(params: HttpParams, id: string): Observable<Cm2000sData> {
    const url = `${this.uiBaseURL}/cm2000s/` + id;
    const options = {
      headers: new HttpHeaders({
        // Authorization: 'Bearer ' + this.authService.idToken,
        Accept: 'application/vnd.api+json',
      }),
      params,
    };
    return this.http.get<Cm2000sData>(url, options);
  }
}
